import {FormFieldPrefillOption} from '../enums/form-field-prefill-option.enum';
import {Client} from '../models/Client';
import {Patient} from '../models/Patient';
import { Practice } from '../models/Practice';

export function getFieldPrefillValue(prefillWith: FormFieldPrefillOption, client: Client, patient: Patient | null, practice: Practice | null): string {
  switch (prefillWith) {
    case FormFieldPrefillOption.CLIENT_PMS_ID:
      return client.pmsDisplayId ?? client.pmsId;

    case FormFieldPrefillOption.CLIENT_NAME:
      return client.fullName;

    case FormFieldPrefillOption.CLIENT_PHONE:
      return client.phone || '';

    case FormFieldPrefillOption.CLIENT_EMAIL:
      return client.email || '';

    case FormFieldPrefillOption.CLIENT_POSTCODE:
      return client.postcode || '';

    case FormFieldPrefillOption.PATIENT_NAME:
      return patient?.name || '';

    case FormFieldPrefillOption.PRACTICE_NAME:
      return practice?.name || '';      

    case FormFieldPrefillOption.PATIENT_PMS_ID:
      return patient?.pmsId || '';
    case FormFieldPrefillOption.PATIENT_AGE:
      return patient?.age || 'age';
    case FormFieldPrefillOption.PATIENT_BREED:
      return patient?.breed || 'breed';
    case FormFieldPrefillOption.PATIENT_SEX:
      return patient?.sex || '';
    case FormFieldPrefillOption.PATIENT_SPECIES:
      return patient?.species || '';
    case FormFieldPrefillOption.CLIENT_ADDRESS:
      return client?.address1 || '';

    default:
      return '';
  }
}
