import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Practice } from '../../../models/Practice';
import { Observable } from 'rxjs';
import { SearchForms } from '../../state/actions';
import { getFormFilterSelection, getForms } from '../../state/selectors';
import { Form } from '../../interfaces/form';
import { FormFilterSelection } from '../../../interfaces/form-filter-selection';
import { FormFilterType } from '../../../enums/form-filter-type';
import { FormFilterArchiveStatus } from '../../../enums/form-filter-archive-status';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsPage {
  alive = true;
  practice$?: Observable<Practice | null>;
  practice?: Practice;
  forms$?: Observable<Form[]>;
  forms: Form[] = [];
  loading = false;
  search = '';
  filterSelection?: FormFilterSelection;
  isLoading = true;

  constructor(
    private store: Store,
    private router: Router,
    private titleService: Title
  ) {
    this.subscribeToCurrentPractice();
    this.subscribeToForms();

    const title = 'Digital Practice | Forms';
    this.titleService.setTitle(title);
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));

    this.practice$.subscribe((practice) => {
      if (practice) {
        this.practice = practice;
        this.store.dispatch(SearchForms({ search: this.search }));
      }
    });
  }

  subscribeToFilters(): void {
    this.store
      .select(getFormFilterSelection)
      .pipe(takeWhile(() => this.alive))
      .subscribe((selection: FormFilterSelection) => {
        this.filterSelection = selection;
        this.filterForms(this.filterSelection);
        setTimeout(() => {
          this.isLoading = false;
        }, 800);
      });
  }

  filterForms(selection: FormFilterSelection): void {
    let filteredForms = this.forms;

    if (this.forms) {
      filteredForms = filteredForms.filter((form: Form) => {
        if (
          selection[FormFilterType.DRAFT_PUBLISHED_STATUS].length > 0 &&
          !selection[FormFilterType.DRAFT_PUBLISHED_STATUS].includes(
            form.isDraftAt
          )
        ) {
          return false;
        }

        if (
          selection[FormFilterType.TAKEPAYMENT].length > 0 &&
          !selection[FormFilterType.TAKEPAYMENT].includes(form.formTakePayment)
        ) {
          return false;
        }

        if (
          selection[FormFilterType.ARCHIVE_STATUS]?.length > 0
        ) {
          const isArchivedSelected = selection[FormFilterType.ARCHIVE_STATUS].includes(FormFilterArchiveStatus.ARCHIVED);
          const isNotArchivedSelected = selection[FormFilterType.ARCHIVE_STATUS].includes(FormFilterArchiveStatus.NOT_ARCHIVED);
        
          // Check the archive status based on the selection
          return (isArchivedSelected && !!form.archivedAt) || 
                 (isNotArchivedSelected && !form.archivedAt);
        }
        

        return true;
      });
    }
    this.forms = filteredForms;
  }

  subscribeToForms(): void {
    this.forms$ = this.store
      .pipe(select(getForms))
      .pipe(takeWhile(() => this.alive));
    this.forms$?.subscribe((forms) => {
      this.forms = forms;

      if (this.filterSelection) {
        this.subscribeToFilters();
      }
    });
  }

  ngOnInit(): void {
    this.subscribeToForms();
    this.subscribeToFilters();
  }

  newForm(): void {
    this.router.navigateByUrl('forms/build');
  }
}
