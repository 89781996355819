import { Pipe, PipeTransform } from '@angular/core';
import {PageField} from '../forms/interfaces/page-field';
import {FormSubmissionData} from '../forms/interfaces/form-submission-data';
import {getSubmittedFieldLabel} from '../helpers/get-submitted-field-label';
import { FormCombineItem } from '../interfaces/form-combine-item.interface';

@Pipe({name: 'getSubmittedFieldLabel'})
export class GetSubmittedFieldLabelPipe implements PipeTransform {
  transform(value: PageField | FormCombineItem, submissionData: FormSubmissionData[]): string | undefined{
    return getSubmittedFieldLabel(value, submissionData);
  }
}
