<div class="form-submission-page">
  <div class="constrainer">
    <ng-container *ngIf="!formSubmission">
      <div class="loading">
        <p-progressSpinner [style]="{width: '40px', height: '40px'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
      </div>
    </ng-container>
    <ng-container *ngIf="formSubmission">
      <div class="form-submission">
        <div class="header">
          <div class="form-name">
            <div class="name">{{ formSubmission.form.name }}</div>
            <div class="completed">
              <ng-container *ngIf="!formSubmission.completedAt">
                <capsule label="Pending" backgroundColor="var(--orange-400)" [small]="true"></capsule>
              </ng-container>
              <ng-container *ngIf="formSubmission.completedAt">
                <capsule label="Complete" backgroundColor="var(--success-color)" [small]="true"></capsule>
              </ng-container>
            </div>
            <ng-container *ngIf="formSubmission.completedAt">
              <div class="complete-text">
                Completed: {{ formSubmission.completedAt ? (formSubmission.completedAt | date:"dd/MM/yy 'at' h:mma") : '-' }}
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="formSubmission.clientName">
            <div class="client-name">
              {{ formSubmission.clientName }}
            </div>
          </ng-container>
        </div>

        <ng-container *ngFor="let page of formSubmission.form.formPages">
          <div class="page">
            <div class="page-heading">{{ page.heading }}</div>
            <ng-container *ngFor="let field of page.combineItems">
              <ng-container *ngIf="field.fieldType === 'content'">
                <quill-view [content]="field.content" format="html" theme="snow"></quill-view>
              </ng-container>
              <ng-container *ngIf="field && field.fieldType === 'field'">
                <div class="field">
                  <div class="label">
                    {{ field | getSubmittedFieldLabel : formSubmission.formSubmissionData }}
                    <span *ngIf="field.deletedAt">(Archived)</span>
                  </div>
                  <div class="value">
                    {{ field | getSubmittedFieldValue : formSubmission.formSubmissionData }}
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="formSubmission.paymentId && formSubmission.paymentAmount">
          <div class="page">
            <div class="page-heading">Payment</div>
            <div class="fields">
              <div class="field">
                <div class="label">
                  Status
                </div>
                <div class="value">
                  <ng-container *ngIf="formSubmission.completedAt">
                    Paid
                  </ng-container>
                  <ng-container *ngIf="!formSubmission.completedAt">
                    Unpaid
                  </ng-container>
                </div>
              </div>
              <div class="field">
                <div class="label">
                  Payment amount
                </div>
                <div class="value">
                  {{ formSubmission.paymentAmount | penceToPounds : practiceCurrency?.currencySymbol }}
                </div>
              </div>
              <div class="field">
                <div class="label">
                  Pre-authorisation?
                </div>
                <div class="value">
                  {{ formSubmission.paymentAuthOnly }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
