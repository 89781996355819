import { ProductRequestStatus } from "../enums/product-request-status";

export function getProductRequestStatusColor(status: ProductRequestStatus): string {
    switch (status) {
        case ProductRequestStatus.AWAITING_APPROVAL:
            return 'var(--primary-color)';
        case ProductRequestStatus.REJECTED:
          return 'var(--alert-color-2)';
        case ProductRequestStatus.READY_TO_PAY:
            return 'var(--secondary-color)';
        case ProductRequestStatus.AWAITING_PAYMENT:
            return 'var(--orange-400)';
        case ProductRequestStatus.PAID:
            return 'var(--cyan-400)';
        case ProductRequestStatus.FULFILLED:
            return 'var(--third-color)';
        case ProductRequestStatus.COMPLETE:
            return 'var(--aquamarine-400)';
        case ProductRequestStatus.CANCELLED:
            return 'var(--surface-400)';
    }

    return 'var(--third-color)';
}
