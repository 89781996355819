<ng-container *ngIf="status">
  <ng-container *ngIf="status === 'queued'">
    <div class="status queued" pTooltip="Message queued">
      <img src="/assets/images/icons/clock.svg" alt="">
    </div>
  </ng-container>
  <ng-container *ngIf="status === 'sending' || status === 'accepted'">
    <div class="status sending" pTooltip="Message sending">
      <img src="/assets/images/icons/clock.svg" alt="">
    </div>
  </ng-container>
  <ng-container *ngIf="status === 'sent'">
    <div class="status sent" pTooltip="Message sent">
      <img src="/assets/images/icons/check.svg" alt="">
    </div>
  </ng-container>
  <ng-container *ngIf="status === 'delivered'">
    <div class="status delivered" pTooltip="Message delivered">
      <img src="/assets/images/icons/check.svg" alt="">
      <img src="/assets/images/icons/check.svg" alt="">
    </div>
  </ng-container>
  <ng-container *ngIf="status === 'read'">
    <div class="status read" pTooltip="Message read">
      <img [src]="'checkMessageIcon' | themeIconUrl: theme" alt="" >
      <img [src]="'checkMessageIcon' | themeIconUrl: theme" alt="" >
    </div>
  </ng-container>
  <ng-container *ngIf="status === 'failed' || status == 'undelivered'">
    <div class="status failed" pTooltip="Message failed to send"><img src="/assets/images/icons/alert.svg" alt=""></div>
  </ng-container>
  <ng-container *ngIf="status == 'unsubscribed'">
    <div class="status failed" pTooltip="Recipient has opted-out"><img src="/assets/images/icons/alert-alt.svg" alt=""></div>
  </ng-container>
</ng-container>
