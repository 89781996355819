export enum FormFieldPrefillOption {
  CLIENT_PMS_ID = 'CLIENT_PMS_ID',
  CLIENT_NAME = 'CLIENT_NAME',
  CLIENT_POSTCODE = 'CLIENT_POSTCODE',
  CLIENT_PHONE = 'CLIENT_PHONE',
  CLIENT_EMAIL = 'CLIENT_EMAIL',
  PATIENT_NAME = 'PATIENT_NAME',
  PRACTICE_NAME = 'PRACTICE_NAME',
  PATIENT_PMS_ID = 'PATIENT_PMS_ID',
  PATIENT_SPECIES = 'PATIENT_SPECIES',
  PATIENT_BREED = 'PATIENT_BREED',
  PATIENT_AGE = 'PATIENT_AGE',
  PATIENT_SEX = 'PATIENT_SEX',
  CLIENT_ADDRESS = 'CLIENT_ADDRESS',
}
