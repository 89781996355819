<div class="payment-screen-preview">
  <div class="lock-icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="var(--vhd-color)" d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>
  </div>
  <div class="payment-header">
    <ng-container *ngIf="practice && practice.banner">
      <img class="logo" src="{{ practice.banner }}" alt="">
    </ng-container>
  </div>
  <div class="payment-info">
    <div class="row message">
      <ng-container *ngIf="paymentMessage && paymentMessage.length > 0">
        <p class="payment-message">"{{ paymentMessage }}"</p>
      </ng-container>
      <p class="practice-name">{{ practice?.name ?? '' }}</p>
    </div>
    <div class="row details">
      <div class="payment-description">{{ description }}</div>
      <div class="payment-total">{{ practiceCurrency ? practiceCurrency.currencySymbol : '£' }}{{ amount }}</div>
    </div>
    <ng-container *ngIf="authOnly">
      <div class="row">By proceeding, you are pre-authorising a payment to be taken within the next 7 days</div>
    </ng-container>
  </div>
</div>
