import { Client } from '../../../models/Client';
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import { Store } from '@ngrx/store';
import {FormSubmission} from '../../interfaces/form-submission';
import { ExportFormsSubmission, RetryFormsSubmissionSyncToPms } from '../../state/actions';

@Component({
  selector: 'form-submission-list-item',
  templateUrl: './form-submission-list-item.component.html',
  styleUrls: ['./form-submission-list-item.component.scss'],
})
export class FormSubmissionListItemComponent {
  @Output() selectedToggled = new EventEmitter();
  @Input() selected = false;
  @Input() formSubmission?: FormSubmission;
  @Input() client?: Client;
  @Input() isAutomaticFormCompletionEnabled: boolean = false;
  @Input() isSyncing = false;
  actionsOpen = false;

  constructor(private router: Router,  private store: Store) {}

  toggleActions(): void {
    this.actionsOpen = !this.actionsOpen;
  }

  selectedChanged(): void {
    if (this.formSubmission) {
      this.selectedToggled.emit();
    }
  }

  viewSub(): void {
    if (this.formSubmission) {
      this.router.navigateByUrl(
        `/forms/submissions/${this.formSubmission.uuid}`
      );
    }
  }
  downloadPdf(formSubmission:FormSubmission):void {
    if (this.formSubmission) {
      const formsUUids = [this.formSubmission.uuid]
      this.store.dispatch(ExportFormsSubmission({formSubmissionUuids: formsUUids, downloadPdfs: true }));
    }
  }

  retrySaveToPms(): void {
    if (this.formSubmission) {
      this.store.dispatch(RetryFormsSubmissionSyncToPms({ formSubmissionUuid: this.formSubmission.uuid }));
    }
  }

}
