import { Injectable } from '@angular/core';
import { EnvironmentService } from '../services/environment.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Form} from './interfaces/form';
import {Practice} from '../models/Practice';
import {FormCreateDto} from './interfaces/dto/form-create.dto';
import {FormUpdateDto} from './interfaces/dto/form-update.dto';
import {FormSubmissionCreateDto} from './interfaces/dto/form-submission-create.dto';
import { FormSubmission } from './interfaces/form-submission';
import { Client } from '../models/Client';
import { Contact } from '../models/Contact';
import { Channel } from '../enums/channel';
import { FormDraftPublishedStatus } from '../enums/form-draft-published-status';
import { FormTakePayment } from '../enums/form-take-payment';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient
  ) {}

  searchForms(practice: Practice | null, search?: string, isDraft: boolean | null = null): Observable<Form[]> {
    if (!practice) {
      throw new Error('No practice set');
    }

    let url = this.environmentService.get('formsApiUrl') + `/search?practiceId=${practice.coreId}`;

    if (isDraft !== null) {
      url = `${url}&isDraft=${isDraft}`;
    }

    if (search) {
      url += `&search=${search}`;
    }

    return this.http.get<Form[]>(url).pipe(
      map((response: Form[]) => {
        response.map(form=>{
          form.isDraftAt = form.isDraft ? FormDraftPublishedStatus.DRAFT : FormDraftPublishedStatus.PUBLISHED
          form.formTakePayment = form.takePayment ? FormTakePayment.YES : FormTakePayment.NO
        })
        return response;
      })
    );
  }

  getALlForms(practice: Practice | null, search?: string): Observable<Form[]> {
    if (!practice) {
      throw new Error('No practice set');
    }

    let url = this.environmentService.get('formsApiUrl') + `/search?practiceId=${practice.coreId}`;

    if (search) {
      url += `&search=${search}`;
    }

    return this.http.get<Form[]>(url).pipe(
      map((response: Form[]) => {
        return response;
      })
    );
  }

  createForm(practice: Practice | null, dto: FormCreateDto): Observable<Form> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url =
      this.environmentService.get('formsApiUrl') +
      `/create?practiceId=${practice.coreId}`;

    return this.http.post<Form>(url, dto).pipe(
      map((response: Form) => {
        return response;
      })
    );
  }

  updateForm(practice: Practice | null, dto: FormUpdateDto): Observable<Form> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url =
      this.environmentService.get('formsApiUrl') +
      `/${dto.id}?practiceId=${practice.coreId}`;

    return this.http.patch<Form>(url, dto).pipe(
      map((response: Form) => {
        return response;
      })
    );
  }

  archiveForm(
    practice: Practice | null,
    formId: number,
    archived: boolean
  ): Observable<Form> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url =
      this.environmentService.get('formsApiUrl') +
      `/${formId}/archive?practiceId=${practice.coreId}`;

    return this.http
      .post<Form>(url, {
        archived,
      })
      .pipe(
        map((response: Form) => {
          return response;
        })
      );
  }

  getForm(practice: Practice | null, formId: number): Observable<Form> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url =
      this.environmentService.get('formsApiUrl') +
      `/${formId}?practiceId=${practice.coreId}`;

    return this.http.get<Form>(url).pipe(
      map((response: Form) => {
        return response;
      })
    );
  }

  duplicateForm(practice: Practice | null, formId: number): Observable<Form> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url =
      this.environmentService.get('formsApiUrl') +
      `/${formId}/duplicate?practiceId=${practice.coreId}`;

    return this.http.get<Form>(url).pipe(
      map((response: Form) => {
        return response;
      })
    );
  }

  createFormSubmission(
    practice: Practice | null,
    dto: FormSubmissionCreateDto
  ): Observable<FormSubmission> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url =
      this.environmentService.get('formsApiUrl') +
      `/submissions/create?practiceId=${practice.coreId}`;

    return this.http.post<FormSubmission>(url, dto).pipe(
      map((response: FormSubmission) => {
        return response;
      })
    );
  }

  sendFormSubmission(
    practice: Practice | null,
    formSubmission: FormSubmission,
    client: Client | null,
    contact: Contact | null,
    channel: Channel
  ): Observable<{ conversationId: number }> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url =
      this.environmentService.get('backendUrl') +
      `/forms/send?practiceId=${practice.id}`;

    return this.http
      .post<{ conversationId: number }>(url, {
        uuid: formSubmission.uuid,
        client,
        contact,
        channel,
        practiceId: practice.id,
        formName: formSubmission.form.name,
      })
      .pipe(
        map((response: { conversationId: number }) => {
          return response;
        })
      );
  }

  getFormSubmissions(
    practice: Practice | null,
    page: number,
    perPage: number,
    searchString: string | null,
    status?: string,
    saveToPms?: string,
    date?: string | object,
    clientPmsId?: string,
    orderBy?: string
  ): Observable<{ formSubs: FormSubmission[], totalCount: number }> {
    if (!practice) {
      throw new Error('No practice set');
    }
    let url = `${this.environmentService.get('formsApiUrl')}/submissions/search?practiceId=${practice.coreId}&page=${page}&perPage=${perPage}`;
    if (status) {
      url += `&status=${status}`;
    }
    if (saveToPms) {
      url += `&saveToPms=${saveToPms}`;
    }
    if (date) {
      let dateFilter: string;
      if (typeof date === 'string') {
        try {
          const parsed = JSON.parse(date);
          if (typeof parsed === 'object' && parsed !== null) {
            dateFilter = JSON.stringify(parsed);
          } else {
            dateFilter = `"${date}"`;
          }
        } catch (error) {
          dateFilter = `"${date}"`;
        }
      } else if (typeof date === 'object' && date !== null) {
        dateFilter = JSON.stringify(date);
      } else {
        dateFilter = 'null';
      }

      url += `&date=${encodeURIComponent(dateFilter)}`;
    }
  
    if (searchString && searchString !== '') {
      url += `&searchString=${encodeURIComponent(searchString)}`;
    }
  
    if (clientPmsId) {
      url += `&clientPmsId=${clientPmsId}`;
    }

    if (orderBy) {
      url += `&orderBy=${orderBy}`;
    }
  
    return this.http.get<{ formSubs: FormSubmission[], totalCount: number }>(url).pipe(
      map((response: { formSubs: FormSubmission[], totalCount: number }) => {
        return response;
      })
    );
  }
  

  getFormSubmission(
    practice: Practice | null,
    uuid: string
  ): Observable<FormSubmission> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url =
      this.environmentService.get('formsApiUrl') +
      `/submissions/${uuid}?practiceId=${practice.coreId}`;

    return this.http.get<FormSubmission>(url).pipe(
      map((response: FormSubmission) => {
        return response;
      })
    );
  }

  downloadPdfs(selectedFormsUuids: string[], practiceId: string): Observable<Blob> {
    const url = `${this.environmentService.get('backendUrl')}/forms/download-pdfs`;
    const requestData = { formSubmissionsUuids: selectedFormsUuids, practiceId }; 
    const headers = new HttpHeaders({ 'Content-Type': 'application/json',responseType : 'blob' });
    return this.http.post<Blob>(url, requestData, { headers, responseType: 'blob' as 'json' });
  }

  retrySaveFormSubmission(selectedFormsUuid: string, practiceId: string | undefined): Observable<{synced: boolean, formSubmission: FormSubmission}>  {
    if (!practiceId) {
      return of();
    }
    const url = this.environmentService.get('backendUrl') + `/forms/resync-form-submission`;

    return this.http.post<{synced: boolean, formSubmission: FormSubmission}>(url, {
      formSubmissionsUuid: selectedFormsUuid,
      practiceId: practiceId,
    }).pipe(
      map((response: { synced: boolean, formSubmission: FormSubmission }) => {
        return response;
      }),
      catchError((e) => {
        throw new Error(e);
      })
    );
  }

}
