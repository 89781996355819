import { FormSubmissionStatus } from "../enums/form-submission-status";

export function getFormSubmissionStatusColor(status: FormSubmissionStatus): string {
    switch (status) {
        case FormSubmissionStatus.COMPLETED:
            return 'var(--aquamarine-400)';
        case FormSubmissionStatus.PENDING:
            return 'var(--orange-400)';
    }

    return 'var(--third-color)';
}
