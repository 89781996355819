import {FormFieldType} from "../enums/form-field-type.enum";

export function prettifyFormInputType(value: FormFieldType): string {
  switch (value) {
    case FormFieldType.CHECKBOX:
      return 'Single checkbox';
    case FormFieldType.AGREE_TO_TERMS:
      return 'Agree to terms';
    case FormFieldType.MULTI_CHECKBOX:
      return 'Multiple selection checkboxes';
    case FormFieldType.TEXT:
      return 'Short text';
    case FormFieldType.EMAIL:
      return 'Email';
    case FormFieldType.TEXTAREA:
      return 'Paragraph';
    case FormFieldType.SELECT:
      return 'Dropdown';
    case FormFieldType.RADIO:
      return 'Multiple choice';
    case FormFieldType.DATE:
      return 'Date';
    case FormFieldType.HIDDEN:
      return 'Hidden (Clinic view only)';
  }
}
