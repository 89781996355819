<div
    #navContainer
    class="navigation"
    [class.open]="navOpen"
    (click)="toggleNav($event)"
    (mouseover)="openNav($event)"
    (mouseleave)="closeNav()"
    (blur)="closeNav()"
    (clickOutside)="handleClickOutside($event)"
>
    <div class="inner">
        <div class="top">
            <div class="left">
                <a (click)="navigate('/day-list', $event)" class="logo">
                    <ng-container *ngIf="this.practice && this.practice.logo">
                        <img [src]="this.practice.logo" alt="" />
                    </ng-container>
                    <ng-container *ngIf="!this.practice || !this.practice.logo">
                        <img [src]="logo" alt="" />
                    </ng-container>
                </a>
                <div class="welcome">
                    <ng-container *ngIf="practice">
                        <div class="practice-name">{{ practice.name }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="pms-status">
                <ng-container *ngIf="practice">
                    <ng-container *ngIf="practice.pmsHealthy === false">
                        <i
                            class="pi pi-exclamation-triangle"
                            pTooltip="PMS Connection Error"
                            [tooltipEvent]="'focus'"
                            [tabindex]="0"
                            style="outline: 0"
                            #hint1
                            (click)="$event.stopPropagation(); hint1.focus()"
                            tooltipStyleClass="warning-tooltip"
                            tooltipPosition="left"
                        ></i>
                    </ng-container>
                    <ng-container *ngIf="practice.pmsHealthy === true">
                        <i
                            class="pi pi-check-circle"
                            pTooltip="PMS Connected"
                            [tooltipEvent]="'focus'"
                            [tabindex]="0"
                            style="outline: 0"
                            #hint2
                            (click)="$event.stopPropagation(); hint2.focus()"
                            tooltipStyleClass="success-tooltip"
                            tooltipPosition="left"
                        ></i>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <div class="nav-items-list">
            <ng-container *ngIf="!userMenuOpen && !practiceContactDetailsOpen">
                <ng-container *ngIf="device === 'mobile'">
                    <div
                        class="nav-back-icon"
                        (click)="closeNav();$event.stopPropagation();"
                    >
                        <i class="pi pi-chevron-left"></i>
                    </div>
                </ng-container>
                <ng-container *ngIf="practice">
                    <div class="nav-section">
                        <ng-container *ngIf="messagingEnabled">
                            <ng-container
                                *ngIf="practice | canViewDaylist : practice.userRole"
                            >
                                <a
                                    (click)="navigate('/day-list', $event);"
                                    [class.active]="activeRoute === '/day-list'"
                                    class="nav-link"
                                >
                                    <img
                                        class="icon"
                                        [src]="'conversation' | themeIconUrl: theme"
                                        alt=""
                                    />
                                    <div class="label">Day list</div>
                                </a>
                            </ng-container>
                            <ng-container
                                *ngIf="practice | canViewAllConversations : practice.userRole"
                            >
                                <a
                                    (click)="navigateToConversations($event);"
                                    [class.active]="activeRoute === '/conversations'"
                                    class="nav-link"
                                >
                                    <img
                                        class="icon"
                                        [src]="'searchList' | themeIconUrl: theme"
                                        alt=""
                                    />
                                    <div class="label">All Conversations</div>
                                </a>
                            </ng-container>
                            <ng-container
                                *ngIf="practice | canViewBroadcasts : practice.userRole"
                            >
                                <a
                                    (click)="navigate('/broadcasts', $event);"
                                    [class.active]="activeRoute === '/broadcasts'"
                                    class="nav-link"
                                >
                                    <img
                                        class="icon"
                                        [src]="'bullhorn' | themeIconUrl: theme"
                                        alt=""
                                    />
                                    <div class="label">Broadcasts</div>
                                </a>
                            </ng-container>
                        </ng-container>

                        <ng-container
                            *ngIf="(practice | canViewPayments : practice.userRole) && paymentListToggle"
                        >
                            <a
                                (click)="navigateToPayments($event);"
                                [class.active]="activeRoute === '/payments'"
                                class="nav-link"
                            >
                                <img
                                    class="icon"
                                    [src]="'payment' | themeIconUrl: theme"
                                    alt=""
                                />
                                <div class="label">Payments</div>
                            </a>
                        </ng-container>

                        <ng-container *ngIf="messagingEnabled">
                            <ng-container *ngIf="showFormSubmissions">
                                <a
                                    (click)="navigate('/forms/submissions', $event)"
                                    [class.active]="activeRoute === '/forms/submissions'"
                                    class="nav-link"
                                >
                                    <img
                                        class="icon"
                                        [src]="'formSubmissions' | themeIconUrl: theme"
                                        alt=""
                                    />
                                    <div class="label">Form Submissions</div>
                                    <span class="notification-counter" *ngIf="formSubmissionCount > 0">
                                        {{ formSubmissionCount }}
                                    </span>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="showProductRequests">
                                <a
                                    (click)="navigate('/product-requests/board', $event)"
                                    [class.active]="activeRoute === '/product-requests'"
                                    class="nav-link"
                                >
                                    <img
                                        class="icon"
                                        [src]="'productRequest' | themeIconUrl: theme"
                                        alt=""
                                    />
                                    <div class="label">Product Requests</div>
                                </a>
                            </ng-container>
                            <ng-container
                                *ngIf="practice | canViewMedia : practice.userRole"
                            >
                                <a
                                    (click)="navigateToMedia($event)"
                                    [class.active]="activeRoute === '/media'"
                                    class="nav-link"
                                >
                                    <img
                                        class="icon"
                                        [src]="'image' | themeIconUrl: theme"
                                        alt=""
                                    />
                                    <div class="label">Media Library</div>
                                </a>
                            </ng-container>
                            <ng-container
                                *ngIf="practice | canViewDashboard : practice.userRole"
                            >
                                <a
                                    (click)="navigate('/insights', $event);"
                                    [class.active]="activeRoute === '/insights'"
                                    class="nav-link"
                                >
                                    <img
                                        class="icon"
                                        [src]="'chart' | themeIconUrl: theme"
                                        alt=""
                                    />
                                    <div class="label">Insights</div>
                                </a>
                            </ng-container>
                            <ng-container
                                *ngIf="practice | canViewTemplates : practice.userRole"
                            >
                                <a
                                    (click)="navigate('/templates', $event)"
                                    [class.active]="activeRoute === '/templates'"
                                    class="nav-link"
                                >
                                    <img
                                        class="icon"
                                        [src]="'messagePenRegular' | themeIconUrl: theme"
                                        alt=""
                                    />
                                    <div class="label">Templates</div>
                                </a>
                            </ng-container>
                            <ng-container
                                *ngIf="showForms && (practice | canViewForms : practice.userRole)"
                            >
                                <a
                                    (click)="navigate('/forms', $event)"
                                    [class.active]="activeRoute === '/forms'"
                                    class="nav-link"
                                >
                                    <img
                                        class="icon"
                                        [src]="'iconForm' | themeIconUrl: theme"
                                        alt=""
                                    />
                                    <div class="label">Forms</div>
                                </a>
                            </ng-container>
                        </ng-container>

                        <ng-container
                            *ngIf="practice | canViewClinicSettings : practice.userRole"
                        >
                            <a
                                [href]="settingsLink"
                                target="_blank"
                                class="nav-link"
                            >
                                <img
                                    class="icon"
                                    [src]="'clinicSetting' | themeIconUrl: theme"
                                    alt=""
                                />
                                <div class="label">Clinic Settings</div>
                            </a>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="device === 'mobile'">
                        <div class="nav-section">
                            <ng-container *ngIf="practice">
                                <div
                                    class="nav-link"
                                    (click)="openPracticeContactDetails($event)"
                                >
                                    <img
                                        class="icon"
                                        [src]="'contactDetails' | themeIconUrl: theme"
                                        alt=""
                                    />
                                    <div class="label">
                                        Clinic
                                        <span class="label-indicator"
                                            ><i class="pi pi-chevron-right"></i
                                        ></span>
                                    </div>
                                </div>
                            </ng-container>

                            <a
                                (click)="handleGoToHelpCenter()"
                                [href]="helpLink"
                                target="_blank"
                                class="nav-link"
                            >
                                <div class="icon-holder">
                                    <img
                                        class="icon"
                                        [src]="'helpIcon' | themeIconUrl: theme"
                                        alt=""
                                    />
                                </div>
                                <div class="label">Help Centre</div>
                            </a>

                            <ng-container *ngIf="user">
                                <div
                                    class="nav-link"
                                    (click)="openUserMenu($event)"
                                >
                                    <div class="icon-holder">
                                        <user-avatar
                                            [user]="user"
                                            size="x-small"
                                        ></user-avatar>
                                    </div>
                                    <div class="label">
                                        Account
                                        <span class="label-indicator"
                                            ><i class="pi pi-chevron-right"></i
                                        ></span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="userMenuOpen">
                <div class="nav-back-icon" (click)="handleNavBackClick($event)">
                    <i class="pi pi-chevron-left"></i>
                </div>
                <div class="nav-section">
                    <a [href]="profileLink" target="_blank" class="nav-link">
                        <img
                            class="icon"
                            src="/assets/images/icons/nav/user.svg"
                            alt=""
                        />
                        <div class="label">My profile</div>
                    </a>
                    <a
                        [href]="notificationLink"
                        target="_blank"
                        class="nav-link"
                    >
                        <img
                            class="icon"
                            src="/assets/images/icons/nav/bell.svg"
                            alt=""
                        />
                        <div class="label">Notifications</div>
                    </a>
                    <div (click)="logout()" class="nav-link">
                        <img
                            class="icon"
                            src="/assets/images/icons/nav/logout.svg"
                            alt=""
                        />
                        <div class="label">Log out</div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="practiceContactDetailsOpen && practice">
                <div class="nav-back-icon" (click)="handleNavBackClick($event)">
                    <i class="pi pi-chevron-left"></i>
                </div>
                <div class="nav-section practice-contact-details">
                    <ng-container *ngIf="practice.whatsapp_sender">
                        <div class="practice-contact">
                            <img src="assets/images/icons/WhatsApp.svg" />
                            {{ practice.whatsapp_sender }}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="practice.facebookPageId">
                        <div class="practice-contact">
                            <img
                                src="assets/images/icons/facebook-messenger.svg"
                            />
                            <a
                                href="https://facebook.com/{{ practice.facebookPageId }}"
                                target="_blank"
                                >Go to Facebook Page</a
                            >
                        </div>
                    </ng-container>
                    <div
                        class="practice-selector"
                        (click)="$event.stopPropagation()"
                    >
                        <practice-selector
                            (practiceChanged)="closePracticeContactDetails()"
                        ></practice-selector>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="vhd-logo">
            <div class="links">
                <div class="build">Build: {{ version }}</div>
                <a
                    href="https://www.digitalpractice.vet/privacy"
                    target="_blank"
                    >Privacy Policy</a
                >
                <ng-container
                    *ngIf="practice && (practice.pms === PMS.ROBOVET || practice.pms === PMS.RXWORKS)"
                >
                    <div class="about" (click)="handleAboutClick()">About</div>
                </ng-container>
            </div>

            <ng-container *ngIf="isPoweredByDP">
                <span class="powered-digital-practice">Powered by Digital Practice</span>
            </ng-container>
              
            <ng-container *ngIf="!isPoweredByDP">
                <a href="https://www.digitalpractice.vet/" target="_blank">
                    <img src="/assets/images/dp-logo.svg" alt="" />
                </a>
            </ng-container>
              
        </div>
    </div>
</div>

<p-dialog
    header="About"
    [(visible)]="aboutOpen"
    [modal]="true"
    [style]="{width: '450px'}"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
>
    <div>
        VetHelpDirect is not affiliated with or sponsored by Covetrus Software
        Services, LLC or its affiliates. This software application utilizes the
        Program Software Developer Kit; all rights reserved
    </div>
</p-dialog>
