import { Client } from '../../../models/Client';
import { Dropdown } from 'primeng/dropdown';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormSubmission} from '../../interfaces/form-submission';
import {select, Store} from '@ngrx/store';
import {getCurrentPractice} from '../../../practices/state/selectors';
import {take, takeWhile} from 'rxjs/operators';
import {getFormFilterSelection, getFormSubmissionFilterSelection, getFormSubmissions, getFormSubmissionsLoading, getFormSubmissionsTotalCount, getSelectedFormSubmissionUuids, getSelectedFormSubmissions, getSyncingFormSubmissions} from '../../state/selectors';
import {ExportFormsSubmission, GetFormSubmissions, SelectFormSubmissions} from '../../state/actions';
import {Observable, Subscription, combineLatest} from 'rxjs';
import {Practice} from '../../../models/Practice';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import { practiceHasFeature } from '../../../helpers/practice-has-feature';
import { PracticeFeature } from '../../../enums/practice-feature';
import { FormFilterType } from '../../../enums/form-filter-type';
import { FormSubmissionFilterType } from '../../../enums/form-submission-filter-type';
import { WebsocketService } from '../../../conversation/websocket.service';
import { practiceSupportsFormSync } from '../../../helpers/practice-support-form-sync';

interface BatchAction {
  slug: string;
  label: string;
  disabled: boolean;
}
@Component({
  selector: 'form-submission-list',
  templateUrl: './form-submission-list.component.html',
  styleUrls: ['./form-submission-list.component.scss'],
})
export class FormSubmissionListComponent implements OnInit, OnDestroy {
  @Input() client?: Client;
  @ViewChild('dropdown') dropdown?: Dropdown;
  searchString: string | null = '';
  alive = true;
  loading = true;
  practice$?: Observable<Practice | null>;
  practice?: Practice;
  formSubmissions: FormSubmission[] = [];
  totalItems = 0;
  page = 1;
  perPage = 10;
  isFormFeatureEnabled = false;
  clientPmsId?: string;
  selectedForms: FormSubmission[] = [];
  batchActions: BatchAction[] = [
    { slug: 'export', label: 'Export as PDF', disabled: false },
  ];
  selectedAction: BatchAction | null = null;
  selectedFormsUuids: string[] = [];
  isAutomaticFormCompletionEnabled: boolean = false;
  selectedFilter:any={}

  syncingFormSubmissions$?: Observable<string[]>;
  syncingFormSubmissionsSub$?: Subscription;
  syncingFormSubmissions: string[] = [];

  websocketConnected = false;
  joinedFormsSocket = false;
  formsSocket$?: Subscription;

  constructor(private store: Store, private route: ActivatedRoute, private websocketService: WebsocketService,) {}

  ngOnInit(): void {
    this.subscribeToRouteParams();
    this.subscribeToCurrentPractice();
    this.subscribeToFormSubmissions();
    this.subscribeToFormSubmissionsLoading();
    this.subscribeToSyncingFormSubmissions();
    this.subscribeToSelectedFormSubmissions();
    this.subscribeToFormUpdates();

    this.websocketService.onConnect$.subscribe((connected) => {
      if (connected) {
        this.websocketConnected = true;
        if (this.practice) {
          this.websocketService.joinForms(this.practice.id);
          this.joinedFormsSocket = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.practice) {
      this.websocketService.leaveForms(this.practice.id);
    }
    this.alive = false;

    this.formsSocket$?.unsubscribe();
  }

  subscribeToFormUpdates(): void {
    this.formsSocket$ = this.websocketService
      .getFormUpdates()
      .subscribe(() => {
        this.getSubs();
      });
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));

    this.practice$.subscribe((practice) => {
      if (practice) {
        if (this.websocketConnected && !this.joinedFormsSocket) {
          this.websocketService.joinForms(practice.id);
          this.joinedFormsSocket = true;
        }
        this.isAutomaticFormCompletionEnabled = practiceSupportsFormSync(practice);
        this.practice = practice;
        this.isFormFeatureEnabled = practiceHasFeature(
          this.practice,
          PracticeFeature.FORMS
        );
        this.getSubs();
      }
    });
  }

  subscribeToFormSubmissions(): void {
    this.store
      .pipe(select(getFormSubmissions))
      .pipe(takeWhile(() => this.alive))
      .subscribe((submissions) => {
        this.formSubmissions = submissions;
      });

    this.store
      .pipe(select(getFormSubmissionsTotalCount))
      .pipe(takeWhile(() => this.alive))
      .subscribe((count) => {
        this.totalItems = count;
      });
  }

  subscribeToFormSubmissionsLoading(): void {
    this.store
      .pipe(select(getFormSubmissionsLoading))
      .pipe(takeWhile(() => this.alive))
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  subscribeToSyncingFormSubmissions(): void {
    this.syncingFormSubmissions$ = this.store
      .select(getSyncingFormSubmissions)
      .pipe(takeWhile(() => this.alive));

    this.syncingFormSubmissionsSub$ = this.syncingFormSubmissions$.subscribe((formsSubmissions) => {
      this.syncingFormSubmissions = formsSubmissions;
    });
  }

  subscribeToSelectedFormSubmissions(): void {
    this.store
      .pipe(select(getSelectedFormSubmissions))
      .pipe(takeWhile(() => this.alive))
      .subscribe((formSubmissions) => {
        this.selectedForms = formSubmissions;
      });

    this.store
      .pipe(select(getSelectedFormSubmissionUuids))
      .pipe(takeWhile(() => this.alive))
      .subscribe((formSubmissions) => {
        this.selectedFormsUuids = formSubmissions;
      });
  }

  formSubIsSyncing(formSubmission: FormSubmission): boolean {
    return this.syncingFormSubmissions.find((uuid) => uuid === formSubmission?.uuid) !== undefined;
  }

  subscribeToRouteParams(): void {
    let startSubs = false;
    this.route.queryParams.subscribe((params) => {
      if (params.s) {
        if (this.searchString !== params.s) {
          this.searchString = params.s;
          startSubs = true;
        }
      }

      if (params.clientPmsId) {
        if ( this.clientPmsId !== params.clientPmsId) {
          this.clientPmsId = params.clientPmsId;
          startSubs = true;
        }
      }

      if (startSubs) {
        this.getSubs();
      }
      
    });
  }

  showPreviousPageLink(): boolean {
    return this.page > 1;
  }

  showNextPageLink(): boolean {
    if (this.totalItems) {
      return this.totalItems > this.page * this.perPage;
    }

    return false;
  }

  previousPage(): void {
    this.page--;
    this.getSubs();
  }

  nextPage(): void {
    this.page++;
    this.getSubs();
  }


  getSubs(): void {
    if (this.practice && this.isFormFeatureEnabled) {
      const finalSearchString =
        this.client && this.client.pmsId ? this.client.pmsId : this.searchString;

      this.selectedFilter = this.selectedFilter || {};

      // Combine filter selections
      combineLatest([
        this.store.select(getFormFilterSelection),
        this.store.select(getFormSubmissionFilterSelection),
      ])
        .pipe(take(1))
        .subscribe(([formFilters, formSubmissionFilters]) => {
          const searchString = formFilters[FormFilterType.SEARCH_TERM] || '';
          const payload = {
            status: formSubmissionFilters[FormSubmissionFilterType.STATUS] || [],
            saveToPms:
              formSubmissionFilters[FormSubmissionFilterType.SAVED_TO_PMS] || [],
            date: formSubmissionFilters[FormSubmissionFilterType.DATE] || '',
            page: this.page,
            perPage: this.perPage,
            searchString: finalSearchString || searchString,
            clientPmsId: this.clientPmsId,
          };

          // Merge new payload with `selectedFilter`
          this.selectedFilter = {
            ...this.selectedFilter,
            ...payload,
          };

          // Dispatch the action
          this.store.dispatch(GetFormSubmissions(this.selectedFilter));
        });
    }
  }

  goToAllForms(): void {
    if (this.client) {
      location.href = `search?s=${this.client.pmsId}&searchType=form-submissions`;
    }
  }
  handleToggleForm(submission: FormSubmission): void {
    this.store.dispatch(
      SelectFormSubmissions({
        formSubmission: submission, 
        formSubmissionUuid: submission.uuid,
        selected: !this.isFormSubmissionSelected(submission),
      })
    );
  }

  handleBulkActionFired(): void {
    // this.selectedForms = [];
    // this.selectedFormsUuids = [];
  }

  handleAction(action?: BatchAction): void {
    if (action) {
      if (action.slug) {
        this.store.dispatch(
          ExportFormsSubmission({
            formSubmissionUuids: this.selectedFormsUuids,
            downloadPdfs: true,
          })
        );
      }
      this.handleBulkActionFired();
      this.handleActionFired();
    }
  }

  handleActionFired(): void {
    setTimeout(() => {
      if (this.dropdown) {
        this.dropdown.clear();
      }
    }, 0);
  }

  isFormSubmissionSelected(formSubmission: FormSubmission): boolean {
    return this.selectedFormsUuids.find((uuid) => uuid === formSubmission.uuid) !== undefined;
  }

  updateCurrentPage(page: number): void {
    this.page = page;
  }
}
