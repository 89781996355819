import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Client } from 'src/app/models/Client';
import { getCurrentPractice } from 'src/app/practices/state/selectors';
import { AppState } from 'src/app/state/reducers';
import { ClientService } from '../client.service';
import * as ClientActions from './actions';
import {ProductRequest} from '../../models/ProductRequest';
import {Noop} from '../../state/actions';
import {MessageService} from 'primeng/api';
import {ClientInsights} from "../../models/ClientInsights";
import * as SearchActions from "../../search/state/actions";
import {SearchType} from "../../enums/search-type";
import {buildUrlParamsFromConversationFilters} from "../../helpers/build-url-params-from-conversation-filters";
import {Router} from "@angular/router";
import {ClientDto} from "../../interfaces/dto/client.dto";
import { Patient } from '../../models/Patient';

@Injectable()
export class ClientEffects {
  constructor(
    private actions$: Actions,
    private clientService: ClientService,
    private store: Store<AppState>,
    private messageService: MessageService,
    private router: Router
  ) {
  }

  getClientSearchResults$ = createEffect(() => this.actions$.pipe(
    ofType(ClientActions.GetClientSearchResults),
    withLatestFrom(this.store.select(getCurrentPractice)),
    filter(([action, practice]) => practice !== null),
    mergeMap(([action, practice]) => this.clientService.findClients(action.searchString, practice!.id)
      .pipe(
        tap(() => {
          this.store.dispatch(ClientActions.StopClientSearchLoading());
        }),
        map((result: Client[]) => {
          return ClientActions.GetClientSearchResultsSuccess({clients: result});
        }),
        catchError((error) => {
          return of(ClientActions.GetClientSearchResultsFail());
        })
      ))
    )
  );

  getFilterClients$ = createEffect(() => this.actions$.pipe(
    ofType(ClientActions.GetFilterClients),
    mergeMap((action) => this.clientService.allClients(action.practice, action.search, action.limit)
      .pipe(
        map((result: Client[]) => {
          return ClientActions.GetFilterClientsSuccess({clients: result});
        }),
        catchError((error) => {
          return of(ClientActions.GetFilterClientsFail());
        })
      ))
    )
  );

  getClientProductRequests$ = createEffect(() => this.actions$.pipe(
      ofType(ClientActions.GetClientProductRequests),
      mergeMap((action) => this.clientService.getProductRequestHistory(action.client)
        .pipe(
          map((result: ProductRequest[]) => {
            return ClientActions.GetClientProductRequestsSuccess({productRequests: result});
          }),
          catchError((error) => {
            return of(Noop());
          })
        ))
    )
  );

  getClientInsights$ = createEffect(() => this.actions$.pipe(
      ofType(ClientActions.GetClientInsights),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => this.clientService.getInsights(action.clientId, practice)
        .pipe(
          map((result: ClientInsights) => {
            return ClientActions.GetClientInsightsSuccess({client: result});
          }),
          catchError((error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to load client',
              life: 10000
            });
            return of(ClientActions.GetClientInsightsFailure({clientId: action.clientId}));
          })
        ))
    )
  );

  addManualDeposit$ = createEffect(() => this.actions$.pipe(
      ofType(ClientActions.AddManualDeposit),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => this.clientService.addManualDeposit(action.client, practice)
        .pipe(
          map((result: ClientInsights) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Deposit added successfully',
              life: 10000
            });
            return ClientActions.AddManualDepositSuccess({client: result});
          }),
          catchError((error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error adding this deposit',
              life: 10000
            });
            return of(ClientActions.AddManualDepositFailure({client: action.client}));
          })
        ))
    )
  );

  refundDeposit$ = createEffect(() => this.actions$.pipe(
      ofType(ClientActions.RefundDeposit),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => this.clientService.refundDeposit(action.client, practice)
        .pipe(
          map((result: ClientInsights) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Deposit refunded successfully',
              life: 10000
            });
            return ClientActions.RefundDepositSuccess({client: result});
          }),
          catchError((error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error refunding this deposit',
              life: 10000
            });
            return of(ClientActions.RefundDepositFailure({client: action.client}));
          })
        ))
    )
  );

  takeDeposit$ = createEffect(() => this.actions$.pipe(
      ofType(ClientActions.TakeDeposit),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => this.clientService.takeDeposit(action.client, practice)
        .pipe(
          map((result: ClientInsights) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Deposit taken successfully',
              life: 10000
            });
            return ClientActions.TakeDepositSuccess({client: result});
          }),
          catchError((error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error taking this deposit',
              life: 10000
            });
            return of(ClientActions.TakeDepositFailure({client: action.client}));
          })
        ))
    )
  );

  getClientListClients$ = createEffect(() => this.actions$.pipe(
    ofType(ClientActions.GetClientListClients),
    mergeMap((action) => this.clientService.getClientListClients(action.practiceId, action.searchString, action.filters, action.sortBy, action.page)
      .pipe(
        map((result: {items: Client[], total: number}) => {
          return ClientActions.GetClientListClientsSuccess({clients: result.items, total: result.total});
        })
      ))
    )
  );

  createNewClientDeposit$ = createEffect(() => this.actions$.pipe(
      ofType(ClientActions.CreateNewClientDeposit),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => this.clientService.createNewClientDeposit(action.client, practice)
        .pipe(
          map((result: { client: Client, depositAlreadyExists: boolean }) => {
            if (result.depositAlreadyExists) {
              return ClientActions.CreateNewClientDepositAlreadyExists({client: result.client});
            }

            this.router.navigate([`clients/${result.client.id}`]);
            return ClientActions.CreateNewClientDepositSuccess({client: result.client});
          }),
          catchError((error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error adding this deposit',
              life: 10000
            });
            return of(ClientActions.CreateNewClientDepositFailure({client: action.client}));
          })
        ))
    )
  );

  getClientPMSInsight$ = createEffect(() => this.actions$.pipe(
    ofType(ClientActions.GetClientPMSInsight),
    mergeMap((action) => {
      if (action.practiceId) {
        return this.clientService.getClientByPmsId(action.clientPmsId, action.practiceId, action.page).pipe(
          map((result: { client: Client, patients: Patient[] }) => {
            return ClientActions.GetClientPMSInsightSuccess({client: result.client});
          }),
          catchError((error) => {
            return of(ClientActions.GetClientPMSInsightFailure({client: null}));
          })
        );
      }

      return of(ClientActions.GetClientPMSInsightSuccess({client: null}));
    })
  ));

  getClientInvoices$ = createEffect(() => this.actions$.pipe(
    ofType(ClientActions.GetClientInvoices),
    mergeMap((action) => {
      if (action.practiceId) {
        return this.clientService.getClientInvoices(action.clientPmsId, action.practiceId).pipe(
          map((invoices) => {
            return ClientActions.GetClientInvoicesSuccess({ clientPmsId: action.clientPmsId, invoices });
          }),
          catchError((error) => {
            return of(ClientActions.GetClientInvoicesFailure({ error }));
          })
        );
      }
  
      return of(ClientActions.GetClientInvoicesFailure({ error: 'Practice ID is required' }));
    })
  ));
  
  
}
