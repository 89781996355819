<div class="panel" joyrideStep="step4" [stepContent]="step4Content" stepPosition="right" (next)="handleStepToClient()">
  <ng-container *ngIf="conversation">
    <ng-container *ngIf="loading">
      <div class="loading">
        <p-progressSpinner [style]="{width: '40px', height: '40px'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
      </div>
    </ng-container>

    <div class="header" [class.show-shim]="showTopShim">
      <div class="row">
        <div>
          <div class="chat-title">
            <ng-container *ngIf="conversation && conversation.channel === channels.SMS">
              <img class="icon sms-ipad" src="/assets/images/icons/SMS-IPAD.svg" alt="">
            </ng-container>
            <ng-container *ngIf="conversation && (conversation.channel === channels.WHATSAPP || conversation.channel === channels.WHATSAPP360 || conversation.channel === channels.WHATSAPP360CLOUD)">
              <img class="icon wa" src="/assets/images/icons/WhatsApp.svg" alt="">
            </ng-container>
            <ng-container *ngIf="conversation && conversation.channel === channels.FACEBOOK">
              <img class="icon fb" src="/assets/images/icons/facebook-messenger.svg" alt="">
            </ng-container>
            <ng-container *ngIf="conversation && conversation.channel === channels.INSTAGRAM">
              <i class="pi pi-instagram"></i>
            </ng-container>
            <h2 class="title">Client Chat</h2>
          </div>

          <ng-container *ngIf="user">
            <div class="current-user">
              <span *ngIf="user.phone" class="phone">{{ user.phone }}</span>
              <ng-container *ngIf="user.firstName">
                <span class="name">{{ user.firstName }} {{ user.lastName }}</span>
              </ng-container>
              <ng-container *ngIf="!user.firstName">
                <span class="name">(Unmatched)</span>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="chat-type-actions">
          <div class="chat-screen-action-container">
            <ng-container *ngIf="!(fullScreen$ | ngrxPush)">
              <p (click)="enterFullScreen()" class="chat-screen-action">View fullscreen</p>
            </ng-container>
            <ng-container *ngIf="(fullScreen$ | ngrxPush)">
              <p (click)="exitFullScreen()"  class="chat-screen-action">Exit fullscreen</p>
            </ng-container>
          </div>
          <ng-container *ngIf="showConversationActions">
            <div class="chat-actions" (clickOutside)="closeActionsOpen()">
              <div class="actions-opener" [class.disabled]="disabled" (click)="toggleActionsOpen()">
                <ng-container *ngIf="!disabled">
                  <img src="assets/images/icons/dots-verticle.svg" alt="">
                </ng-container>
                <ng-container *ngIf="disabled">
                  <img src="assets/images/icons/dots-verticle-light.svg" alt="">
                </ng-container>
              </div>
              <ng-container *ngIf="actionsOpen">
                <div class="actions-dropdown">
                  <ng-container *ngIf="(conversation.readStatus === readStatus.UNSET && conversation.newMessage > 0) || conversation.readStatus === readStatus.UNREAD">
                    <div class="action" (click)="markRead(); closeActionsOpen()">
                      Mark as read
                    </div>
                  </ng-container>
                  <ng-container *ngIf="(conversation.readStatus === readStatus.UNSET && conversation.newMessage === 0) || conversation.readStatus === readStatus.READ">
                    <div class="action" (click)="markUnread(); closeActionsOpen()">
                      Mark as unread
                    </div>
                  </ng-container>
                  <div class="action" (click)="openEditContact(); closeActionsOpen()">
                    Edit contact name
                  </div>
                  <div class="action" (click)="openMessageInfo(); closeActionsOpen()">
                    Conversation info
                  </div>
                  <div class="action" (click)="openBlockSender(); closeActionsOpen()">
                    Block sender
                  </div>
                  <ng-container *ngIf="!conversation.lastClientResponseAt && (conversation.channel === channels.WHATSAPP || conversation.channel === channels.WHATSAPP360 || conversation.channel === channels.WHATSAPP360CLOUD)">
                    <div class="action" (click)="openConvertToSms(); closeActionsOpen()">
                      Convert to SMS
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div #chatContainer class="chat" (scroll)="handleScroll()">
      <ng-container *ngIf="messages$">
        <ng-container *ngFor="let message of ((messages$ | ngrxPush) || []); let i = index; trackBy: trackMessage">
          <ng-container *ngIf="i === ((messages$ | ngrxPush) || []).length-1 && conversation?.failoverToSms">
            <ng-container *ngIf="!conversation.convertedBy">
              <div class="failover-message">
                We were unable to send this message via WhatsApp, the conversation has been automatically switched to SMS
              </div>
            </ng-container>
            <ng-container *ngIf="conversation.convertedBy">
              <div class="failover-message">
                The conversation was switched to SMS by {{ conversation.convertedBy.fullName }}
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="message.isVisible">
            <message
              [message]="message"
              [channel]="conversation.channel"
              [showDate]="message.createdAt | showMessageDate : (messages$ | ngrxPush) : i"
              [touchScreen]="hasTouch"
              (loadedAttachment)="this.scrollToBottom()"
              (delete)="this.deleteMessage($event)"
            >
            </message>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="previousConversations">
        <div *ngFor="let previousConversation of previousConversations; let i = index; trackBy: trackPreviousConversation">
          <div class="previous-conversation">
            <ng-container *ngIf="isConversationCheck(previousConversation)">
              <ng-container *ngFor="let message of previousConversation.messages; let i = index; trackBy: trackMessage">
                <ng-container *ngIf="i === (previousConversation.messages || []).length-1 && previousConversation?.failoverToSms">
                  <ng-container *ngIf="!previousConversation.convertedBy">
                    <div class="failover-message">
                      We were unable to send this message via WhatsApp, the conversation has been automatically switched to SMS
                    </div>
                  </ng-container>
                  <ng-container *ngIf="previousConversation.convertedBy">
                    <div class="failover-message">
                      The conversation was switched to SMS by {{ previousConversation.convertedBy.fullName }}
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="message.isVisible">
                  <message
                    [message]="message"
                    [channel]="previousConversation.channel"
                    [showDate]="message.createdAt | showMessageDate : previousConversation.messages || [] : i"
                    [touchScreen]="hasTouch"
                    (delete)="this.deleteMessage($event)"
                  >
                  </message>
                </ng-container>
              </ng-container>
              <a [routerLink]="['/conversations/' + previousConversation.id]" class="previous-conversation-title">
                <div class="previous-conversation-title-content">
                  <ng-container *ngIf="previousConversation && previousConversation.channel === channels.SMS">
                    <svg width="17" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="previous-conversation-title-icon sms">
                      <path d="M16.0645 6.56709V4.90894C16.0645 2.75009 14.3144 1 12.1555 1H4.92665C2.79884 1 1.06188 2.70192 1.01852 4.82929L1 5.73801L1.02871 7.14662C1.06641 8.99627 2.57662 10.476 4.42666 10.476C4.86856 10.476 5.22678 10.8343 5.22678 11.2761V12.5586C5.22678 12.9635 5.71206 13.171 6.00481 12.8912L8.39884 10.6035C8.48445 10.5217 8.59831 10.476 8.71673 10.476H11.8638H12.1555C14.3144 10.476 16.0645 8.72593 16.0645 6.56709Z" stroke="currentColor" stroke-width="1.19008"/>
                      <circle cx="5.35949" cy="5.7417" r="0.880061" fill="currentColor" stroke="currentColor" stroke-width="0.09534"/>
                      <path d="M9.43492 5.7417C9.43492 6.22535 9.02029 6.62176 8.50332 6.62176C7.98635 6.62176 7.57172 6.22535 7.57172 5.7417C7.57172 5.25805 7.98635 4.86163 8.50332 4.86163C9.02029 4.86163 9.43492 5.25805 9.43492 5.7417Z" fill="currentColor" stroke="currentColor" stroke-width="0.09534"/>
                      <circle cx="11.75" cy="5.7417" r="0.880061" fill="currentColor" stroke="currentColor" stroke-width="0.09534"/>
                    </svg>
                  </ng-container>
                  <ng-container *ngIf="previousConversation && (previousConversation.channel === channels.WHATSAPP || previousConversation.channel === channels.WHATSAPP360 || previousConversation.channel === channels.WHATSAPP360CLOUD)">
                    <svg width="25" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="previous-conversation-title-icon wa">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.62488 12.528L6.50785 9.31111C5.96243 8.36804 5.67582 7.29732 5.67707 6.20755C5.67844 2.78471 8.47046 9.55867e-06 11.901 9.55867e-06C12.7171 -0.00142285 13.5255 0.158149 14.28 0.469608C15.0345 0.781067 15.7203 1.23831 16.2983 1.81522C16.8763 2.39213 17.3351 3.0774 17.6485 3.83189C17.9619 4.58638 18.1238 5.3953 18.1249 6.21244C18.1235 9.63534 15.3313 12.4204 11.9011 12.4204H11.8985C10.8598 12.4204 9.83745 12.1606 8.92435 11.6648L5.62488 12.528ZM9.07713 10.5408L9.26602 10.6527C10.0637 11.1237 10.9728 11.3721 11.8989 11.3719H11.901C14.7522 11.3719 17.0729 9.05724 17.074 6.21208C17.0731 5.53306 16.9386 4.86087 16.6783 4.23388C16.418 3.6069 16.0368 3.0374 15.5566 2.5579C15.0765 2.0784 14.5067 1.6983 13.8798 1.4393C13.2529 1.18029 12.5812 1.04746 11.903 1.04838C9.04962 1.04838 6.72897 3.36285 6.72799 6.20776C6.72674 7.17961 7.001 8.13185 7.51891 8.95379L7.64192 9.14893L7.11939 11.0531L9.07713 10.5408Z" fill="currentColor"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3454 3.61241C10.229 3.35401 10.1064 3.34877 9.99555 3.3444C9.9049 3.34061 9.80116 3.34082 9.69751 3.34082C9.61876 3.34287 9.54128 3.36117 9.46991 3.39459C9.39855 3.428 9.33485 3.47581 9.2828 3.53501C9.14024 3.69022 8.73853 4.0655 8.73853 4.82897C8.73853 5.59245 9.29576 6.33008 9.37351 6.43356C9.45125 6.53704 10.4494 8.1539 12.0301 8.77581C13.3438 9.2926 13.6111 9.18974 13.8962 9.16398C14.1813 9.13822 14.8163 8.78876 14.9459 8.42641C15.0755 8.06406 15.0755 7.7534 15.0366 7.68875C14.9977 7.6241 14.894 7.58527 14.7385 7.50764C14.583 7.43 13.8184 7.05467 13.6759 7.00294C13.5333 6.95121 13.4297 6.92531 13.326 7.0806C13.2223 7.2359 12.9243 7.58527 12.8335 7.68875C12.7428 7.79224 12.6521 7.80531 12.4966 7.72764C12.3411 7.64998 11.84 7.48619 11.246 6.95773C10.7838 6.54645 10.4716 6.03842 10.3809 5.8831C10.2902 5.72777 10.3712 5.64388 10.4491 5.5666C10.5191 5.49707 10.6046 5.3854 10.6824 5.29481C10.7601 5.20422 10.786 5.1396 10.8379 5.03603C10.8897 4.93246 10.8638 4.84193 10.8249 4.76426C10.786 4.6866 10.4837 3.91922 10.3454 3.61241Z" fill="currentColor"/>
                    </svg>
                  </ng-container>
                  <ng-container *ngIf="previousConversation && previousConversation.channel === channels.FACEBOOK">
                    <svg width="25" viewBox="0 0 59 28" fill="none" xmlns="http://www.w3.org/2000/svg" class="previous-conversation-title-icon fb">
                      <g clip-path="url(#clip0_4031:506)">
                        <path d="M29 0C21.1135 0 15 5.7771 15 13.58C15 17.6617 16.6726 21.1883 19.3967 23.625C19.5103 23.7255 19.6022 23.8481 19.6669 23.9853C19.7316 24.1225 19.7677 24.2714 19.7729 24.423L19.8493 26.915C19.8549 27.0983 19.9055 27.2775 19.9966 27.4367C20.0877 27.5958 20.2165 27.7302 20.3717 27.828C20.5268 27.9258 20.7037 27.984 20.8866 27.9974C21.0695 28.0109 21.2529 27.9792 21.4208 27.9051L24.1997 26.6801C24.4352 26.5764 24.6993 26.5571 24.9473 26.6255C26.2682 26.9845 27.6313 27.1643 29 27.16C36.8866 27.16 43 21.385 43 13.58C43 5.775 36.8866 0 29 0ZM37.407 10.4485L33.2945 16.975C33.1398 17.2204 32.9359 17.4311 32.6957 17.5938C32.4555 17.7565 32.1842 17.8677 31.8989 17.9203C31.6136 17.9729 31.3205 17.9658 31.038 17.8995C30.7556 17.8332 30.49 17.7091 30.2579 17.535L26.9871 15.0815C26.8411 14.972 26.6633 14.9131 26.4807 14.9136C26.2981 14.9142 26.1207 14.9742 25.9753 15.0846L21.5576 18.4359C20.9682 18.8832 20.1986 18.1776 20.593 17.5514L24.7055 11.0267C24.8602 10.7813 25.0641 10.5706 25.3043 10.4079C25.5445 10.2452 25.8158 10.1341 26.1011 10.0815C26.3864 10.0289 26.6795 10.0359 26.962 10.1022C27.2444 10.1686 27.51 10.2927 27.7421 10.4667L31.0128 12.9199C31.1589 13.0294 31.3367 13.0883 31.5193 13.0878C31.7019 13.0872 31.8793 13.0272 32.0247 12.9167L36.4424 9.56445C37.0318 9.1168 37.8015 9.8224 37.407 10.4485Z" fill="currentColor"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_4031:506">
                          <rect width="28" height="28" fill="white" transform="translate(15)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </ng-container>
                  <ng-container *ngIf="previousConversation && previousConversation.channel === channels.INSTAGRAM">
                    <i class="pi pi-instagram previous-conversation-title-icon instagram"></i>
                  </ng-container>
                  <div class="previous-conversation-title-text">Conversation #{{ previousConversation.id }}<ng-container *ngIf="previousConversation.summary">: {{ previousConversation.summary }}</ng-container></div>
                </div>
              </a>
            </ng-container>
            <ng-container *ngIf="!isConversationCheck(previousConversation)">
              <message
                [message]="previousConversation"
                [channel]="previousConversation.channel"
                [showDate]="true"
                [touchScreen]="hasTouch"
              >
              </message>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!allPreviousConversationsLoaded && conversation && conversation.previousConversationCount && conversation.previousConversationCount > 0">
        <div class="load-previous" (click)="loadPreviousConversations()">Show previous messages</div>
      </ng-container>
    </div>

    <div class="footer" [class.show-shim]="showBottomShim">
      <ng-container *ngIf="showScrollDownPrompt">
        <div class="scroll-down-prompt" (click)="scrollToBottom()">
          <ng-container *ngIf="newMessages > 0">
            <div class="counter">{{ newMessages }}</div>
          </ng-container>
          <img src="/assets/images/icons/down-arrow.svg" alt="">
        </div>
      </ng-container>

      <ng-container *ngIf="(messages | showConsentMessage)">
        <div class="whatsapp-reinitialise">
          <div class="labels">
            <div>Queued messages will send when the client accepts the consent request</div>
            <div class="light">
              <div>Last sent {{ messages | getLastConsentDatetime }} &bull; <a class="link" (click)="sendConsentRequest()">Resend Consent Request</a></div>
              <a [href]="helpLink + '/category/faq/post/what-is-consent'" class="consent-info" target="_blank"><img [src]="'questionMarkIcon' | themeIconUrl: theme" height="20" class="help-icon" alt="" ></a>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="input-wrap">
        <ng-container *ngIf="user && user.optedOut">
          <div class="contact-not-present">
            <i class="pi pi-exclamation-triangle"></i>
            <div>
              <span class="identifier">
                <ng-container *ngIf="user.firstName">
                  <span>{{ user.firstName }} {{ user.lastName }}</span>
                  <span *ngIf="user.phone">({{ user.phone }})</span>
                </ng-container>
                <ng-container *ngIf="!user.firstName">
                  <span *ngIf="user.phone">{{ user.phone }}</span>
                  <span>(Unknown)</span>
                </ng-container>
              </span>
              <span>has opted out and will not receive any messages</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="user && userClientMismatch === true">
          <div class="contact-not-present">
            <i class="pi pi-exclamation-triangle"></i>
            <div>
              <span class="identifier">
                <ng-container *ngIf="user.firstName">
                  <span>{{ user.firstName }} {{ user.lastName }}</span>
                  <span *ngIf="user.phone">({{ user.phone }})</span>
                </ng-container>
                <ng-container *ngIf="!user.firstName">
                  <span *ngIf="user.phone">{{ user.phone }}</span>
                  <span>(Unknown)</span>
                </ng-container>
              </span>
              <span>is not connected to your selected client account</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="paymentsFailedToSync > 0">
          <div class="unsynced-payments">
            <i class="pi pi-exclamation-triangle"></i>
            <div>
              This conversation contains payments that have not synced to the PMS
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="user && !user.phone">
          <div class="not-verified">
            <i class="pi pi-exclamation-triangle"></i>
            <div>No verification details yet completed for this contact</div>
          </div>
        </ng-container>
        <div class="input-wrap">
          <div class="top-row">
            <ng-container *ngIf="videoUploading">
              <div class="video-upload-bar">
                <ng-container *ngIf="videoUploadPercentage > 50">
                  <div class="label filled">{{ videoUploadPercentage }}%</div>
                </ng-container>
                <ng-container *ngIf="videoUploadPercentage <= 50">
                  <div class="label">{{ videoUploadPercentage }}%</div>
                </ng-container>
                <div class="bar" [style]="{'width': videoUploadPercentage+'%'}"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="videoProcessing">
              <div class="video-upload-bar complete">
                <div class="label">
                  <img src="/assets/images/spinner-white.svg" class="loading-spinner" alt=""> Processing
                </div>
                <div class="bar"></div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="!disabled">
            <div class="bottom-row">
              <div class="attachments" (clickOutside)="attachmentsOpen = false">
                <ng-container *ngIf="conversation.resolvedAt">
                  <div class="attachments-toggler disabled">
                    <i class="pi pi-plus"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="!conversation.resolvedAt">
                  <ng-container *ngIf="!sendingFile">
                    <div class="attachments-toggler" [class.active]="attachmentsOpen" (click)="attachmentsOpen = !attachmentsOpen">
                      <i class="pi pi-plus"></i>
                    </div>
                    <div class="attachments-inputs" *ngIf="attachmentsOpen">
                      <ng-container *ngTemplateOutlet="fileUploadsTemplate"></ng-container>

                      <ng-container *ngIf="templates.length > 0 && !disabled">
                        <div class="templates-upload" (click)="selectTemplate()">
                          <div class="icon"><i class="pi pi-align-left"></i></div>
                          <div class="label">Template</div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="forms.length > 0 && !disabled && isFormFeatureEnabled">
                        <div class="templates-upload" (click)="startFormRequest()">
                          <div class="icon"><img src="assets/images/icons/icon-form.svg" alt=""></div>
                          <div class="label">Form</div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="sendingFile">
                    <div class="attachments-toggler loading-version">
                      <img src="/assets/images/spinner-white.svg" alt="">
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <div class="message-box-wrapper">
                <textarea
                  #textAreaElement
                  placeholder="Compose client message"
                  [class.invalid]="messageInvalid"
                  pInputTextarea
                  [rows]="1"
                  [autoResize]="true"
                  (onResize)="textareaResize($event)"
                  [ngModel]="messageInput"
                  (ngModelChange)="onMessageInputChange($event)"
                  [disabled]="disabled"
                >
                </textarea>

                <div class="character-count" [class.invalid]="messageTooLong">{{ remainingMessageLength }}</div>
              </div>

              <ng-container *ngIf="(currentlyRecording || sendingAudio)">
                <ng-container *ngIf="currentlyRecording && !sendingAudio">
                  <div class="recording-controls">
                    <ng-container *ngIf="withAudioTooltip">
                      <div class="action-icon-wrap" (click)="cancelRecording()" tooltipPosition="top" pTooltip="Cancel recording">
                        <img src="assets/images/icons/cancel-recording.svg" class="action-icon default-icon">
                        <img src="assets/images/icons/cancel-recording-filled.svg" class="action-icon hover-icon">
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!withAudioTooltip">
                      <div class="action-icon-wrap" (click)="cancelRecording()">
                        <img src="assets/images/icons/cancel-recording.svg" class="action-icon default-icon">
                        <img src="assets/images/icons/cancel-recording-filled.svg" class="action-icon hover-icon">
                      </div>
                    </ng-container>
                    <div class="recording-info">
                        <div class="label">To client</div>
                        <div class="status">
                          <div class="status-dot"></div>
                          <div class="timer">{{ recordingLength | minutesAndSeconds }}</div>
                        </div>
                    </div>
                    <ng-container *ngIf="withAudioTooltip">
                      <div class="action-icon-wrap" (click)="sendRecording()" tooltipPosition="top" pTooltip="Send recording">
                        <img src="assets/images/icons/finish-recording.svg" class="action-icon default-icon">
                        <img src="assets/images/icons/finish-recording-filled.svg" class="action-icon hover-icon">
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!withAudioTooltip">
                      <div class="action-icon-wrap" (click)="sendRecording()">
                        <img src="assets/images/icons/finish-recording.svg" class="action-icon default-icon">
                        <img src="assets/images/icons/finish-recording-filled.svg" class="action-icon hover-icon">
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="sendingAudio">
                  <div class="recording-spinner">
                    <p-progressSpinner [style]="{width: '18px', height: '18px'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="(!currentlyRecording && !sendingAudio)">
                <ng-container *ngIf="!disabled && !messageInvalid">
                  <ng-container *ngIf="conversation.queueMode">
                    <div class="send-icon queue" tooltipPosition="top" pTooltip="Add to queue">
                      <div class="mobile-tip" [class.highlight]="messageInput !== ''">Add to queue</div>
                      <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg" class="action-icon" (click)="send()">
                        <path d="M33.0117 24.8794C33.2105 24.803 33.0839 24.5141 32.8531 24.5176L9.19902 24.8838C8.83223 24.8895 8.53444 25.0702 8.4161 25.359L4.52663 34.8494C4.36923 35.2335 4.8941 35.6777 5.31452 35.5163L33.0117 24.8794Z" [attr.stroke]="theme | themeSvgColor" stroke-width="2"/>
                        <path d="M33.0254 24.6421C33.2252 24.7155 33.1047 25.0039 32.8739 25.0049L9.21679 25.1045C8.84027 25.1061 8.53219 24.9243 8.41125 24.6292L4.51652 15.1259C4.36027 14.7447 4.87725 14.3021 5.29823 14.4568L33.0254 24.6421Z" [attr.stroke]="theme | themeSvgColor" stroke-width="2"/>
                        <circle cx="20.5" cy="11.5" r="10.5" fill="white" [attr.stroke]="theme | themeSvgColor" stroke-width="2"/>
                        <path d="M20 4.5V11.9793C20 12.3057 20.1592 12.6114 20.4266 12.7986L25 16" [attr.stroke]="theme | themeSvgColor" stroke-width="2" stroke-linecap="round"/>
                      </svg>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!conversation.queueMode">
                    <div class="send-icon" tooltipPosition="top" pTooltip="Send to client">
                      <div class="mobile-tip" [class.highlight]="messageInput !== ''">To client</div>
                        <svg width="37" height="24" viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="action-icon"  (click)="send()">
                          <path d="M33.0116 11.8795C33.2105 11.8032 33.0838 11.5142 32.8531 11.5178L9.19899 11.8839C8.8322 11.8896 8.5344 12.0703 8.41607 12.3591L4.5266 21.8495C4.3692 22.2336 4.89407 22.6779 5.31449 22.5164L33.0116 11.8795Z" stroke="currentColor" stroke-width="2"/>
                          <path d="M33.0254 11.642C33.2251 11.7154 33.1046 12.0038 32.8738 12.0047L9.21673 12.1044C8.84021 12.106 8.53213 11.9242 8.41119 11.6291L4.51646 2.1258C4.36021 1.74453 4.87718 1.30199 5.29817 1.45663L33.0254 11.642Z" stroke="currentColor" stroke-width="2"/>
                        </svg>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="disabled || messageInvalid">
                  <div class="send-icon" [class.disabled]="!!conversation.resolvedAt">
                    <ng-container *ngIf="messageInvalid">
                      <div class="tip invalid">{{ invalidReason }}</div>
                    </ng-container>
                    <ng-container *ngIf="!messageInvalid">
                      <div class="mobile-tip" [class.disabled]="!!conversation.resolvedAt" [class.highlight]="messageInput !== ''">To client</div>
                    </ng-container>

                    <svg width="37" height="24" viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="action-icon disabled">
                      <path d="M33.0116 11.8795C33.2105 11.8032 33.0838 11.5142 32.8531 11.5178L9.19899 11.8839C8.8322 11.8896 8.5344 12.0703 8.41607 12.3591L4.5266 21.8495C4.3692 22.2336 4.89407 22.6779 5.31449 22.5164L33.0116 11.8795Z" stroke="currentColor" stroke-width="2"/>
                      <path d="M33.0254 11.642C33.2251 11.7154 33.1046 12.0038 32.8738 12.0047L9.21673 12.1044C8.84021 12.106 8.53213 11.9242 8.41119 11.6291L4.51646 2.1258C4.36021 1.74453 4.87718 1.30199 5.29817 1.45663L33.0254 11.642Z" stroke="currentColor" stroke-width="2"/>
                    </svg>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="disabled">
            <div class="new-conversation-btn">
              <p-button class="selector-button" styleClass="p-button-secondary p-button-fullwidth p-button-smalltext"(click)="openNewConversation()">
                <span class="p-ml-1">New Conversation</span>
              </p-button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #step4Content>
  <div class="tour-item">
    <div class="top lilac"><img src="/assets/images/icons/tour/chat.svg" height="92" alt="" class="icon"></div>
    <div class="content">
      <div class="title">Client chat</div>
      <div class="description">Use this section to send and receive text, photos, PDFs, voice and video messages</div>
    </div>
  </div>
</ng-template>

<ng-template #fileUploadsTemplate>
  <div class="input-upload" (click)="selectMedia()">
    <div class="p-fileupload p-fileupload-basic p-component">
      <div class="p-button p-component p-fileupload-choose p-ripple" [class.p-disabled]="disabled">
        <span class="p-button-icon p-button-icon-left pi pi-image"></span>
        <span class="p-button-label">From Media library</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="captureSupported">
    <div class="input-upload" (click)="handleCapturePhotoClick()">
      <div class="p-fileupload p-fileupload-basic p-component">
        <div class="p-button p-component p-fileupload-choose p-ripple" [class.p-disabled]="disabled">
          <span class="p-button-icon p-button-icon-left pi pi-camera"></span>
          <span class="p-button-label">Take Photo</span>
          <input #capturePhotoUpload type="file" accept="image/jpeg,image/jpg,image/png" capture (change)="uploadCapturePhoto()">
        </div>
      </div>
    </div>
    <div class="input-upload" (click)="handleCaptureVideoClick()">
      <div class="p-fileupload p-fileupload-basic p-component">
        <div class="p-button p-component p-fileupload-choose p-ripple" [class.p-disabled]="disabled">
          <span class="p-button-icon p-button-icon-left pi pi-video"></span>
          <span class="p-button-label">Take Video</span>
          <input #captureVideoUpload type="file" accept="video/*" capture (change)="uploadCaptureVideo()">
        </div>
      </div>
    </div>
  </ng-container>
  <p-fileUpload #imageUpload [disabled]="disabled" [chooseIcon]="uploadPhotoIcon" [chooseLabel]="uploadPhotoLabel" class="input-upload" mode="basic" name="image" accept="image/jpeg,image/jpg,image/png" invalidFileSizeMessageSummary="Invalid file size," invalidFileTypeMessageSummary="Invalid file type," invalidFileTypeMessageDetail="allowed file types: jpeg, jpg, png." [maxFileSize]="16777216" [auto]="true" [customUpload]="true" (uploadHandler)="uploadFile($event, imageUpload)"></p-fileUpload>
  <p-fileUpload #videoUpload [disabled]="disabled" [chooseIcon]="uploadVideoIcon" [chooseLabel]="uploadVideoLabel" class="input-upload" mode="basic" name="image" accept="video/*" invalidFileSizeMessageSummary="Invalid file size" invalidFileTypeMessageSummary="Invalid file type" invalidFileTypeMessageDetail="Allowed file types: mp4, mov, wmv, 3gp, flv" [maxFileSize]="524288000" [auto]="true" [customUpload]="true" (uploadHandler)="uploadVideo($event, videoUpload)"></p-fileUpload>
  <p-fileUpload #fileUpload [disabled]="disabled" chooseIcon="pi-file-pdf" chooseLabel="PDF" class="input-upload" mode="basic" name="image" accept="application/pdf" invalidFileSizeMessageSummary="Invalid file size," invalidFileTypeMessageSummary="Invalid file type," invalidFileTypeMessageDetail="allowed file types: pdf." [maxFileSize]="16777216" [auto]="true" [customUpload]="true" (uploadHandler)="uploadFile($event, fileUpload)"></p-fileUpload>

  <div class="input-upload" (click)="startRecording()">
    <div class="p-fileupload p-fileupload-basic p-component">
      <div class="p-button p-component p-fileupload-choose p-ripple" [class.p-disabled]="disabled">
        <span class="p-button-icon p-button-icon-left pi pi-volume-up"></span>
        <span class="p-button-label">Record audio</span>
      </div>
    </div>
  </div>
</ng-template>

<!-- <ng-container *ngIf="templates.length > 0">
  <dialog-template-selector
    [templates]="templates"
    [showDialog]="showTemplateDialog"
    [client]="client || undefined"
    [patient]="conversation?.patient"
    (templateSelected)="handleTemplateSelected($event)"
    (dialogClosed)="showTemplateDialog = false"
    buttonClass="p-button-success"
    [includeMediaFilter]="true"
  ></dialog-template-selector>
</ng-container> -->

<ng-container *ngIf="conversation">
  <p-dialog
    header="Conversation #{{ conversation.id }}"
    [(visible)]="messageInfoOpen"
    [modal]="true"
    [style]="{width: '400px'}"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="message-info">
      <div class="message-info-item"><span class="message-info-label">Last client message:</span> {{ conversation.lastClientResponseAt ? (conversation.lastClientResponseAt | date : "dd/MM/yy 'at' h:mma") : 'N/A' }}</div>
      <ng-container *ngIf="conversation.channel === channels.WHATSAPP || conversation.channel === channels.WHATSAPP360 || conversation.channel === channels.WHATSAPP360CLOUD || conversation.channel === channels.FACEBOOK">
        <div class="message-info-title">Consent</div>
        <div class="message-info-item">
          <span class="message-info-label">Currently granted:</span>
          <ng-container *ngIf="(conversation.timeSinceLastClientResponse && conversation.timeSinceLastClientResponse >= 86400) || !conversation.timeSinceLastClientResponse">
            No
          </ng-container>
          <ng-container *ngIf="conversation.timeSinceLastClientResponse && conversation.timeSinceLastClientResponse < 86400">
            Yes
          </ng-container>
        </div>
        <ng-container *ngIf="conversation.timeSinceLastClientResponse && conversation.timeSinceLastClientResponse < 86400">
          <div class="message-info-item">
            <span class="message-info-label">Expires:</span> {{ getWindowClosesAt() | diffForHumans }}
          </div>
        </ng-container>
        <div class="message-info-item">
          <span class="message-info-label">Requested:</span><span class="consent-time"> {{ messages | getLastConsentDatetime }}</span>
        </div>
        <div class="message-info-item consent">
          <a class="message-info-link" (click)="sendConsentRequest(); closeMessageInfo();">Resend Consent Request</a>
          <a [href]="helpLink + '/category/faq/post/what-is-consent'" class="consent-info" target="_blank"><img src="/assets/images/icons/question_mark.png" alt=""></a>
        </div>
      </ng-container>
    </div>
  </p-dialog>
</ng-container>

<ng-container *ngIf="conversation">
  <p-dialog
    header="Block Sender"
    [(visible)]="blockSenderOpen"
    [modal]="true"
    [style]="{width: '400px'}"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
    <block-sender (closed)="closeBlockSender()" [conversation]="conversation"></block-sender>
  </p-dialog>
</ng-container>

<ng-container *ngIf="conversation">
  <p-dialog
    header="Convert to SMS"
    [(visible)]="convertToSmsOpen"
    [modal]="true"
    [style]="{width: '400px'}"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="convert-to-sms">
      <p>Note: any messages already sent may still be delivered to this contact via WhatsApp.</p>
      <div class="convert-to-sms-checkbox-wrap">
        <div class="convert-to-sms-checkbox">
          <input type="checkbox" name="resend-all" id="resend-all" checked #resendAll>
        </div>
        <label for="resend-all" class="convert-to-sms-label">Resend all messages via SMS? (Note - if not selected, any queued messages will be removed)</label>
      </div>
      <div class="convert-to-sms-submit">
        <button pButton label="Convert to SMS" (click)="convertToSms()"></button>
      </div>
    </div>
  </p-dialog>
</ng-container>

<ng-container *ngIf="user">
  <p-dialog
    header="Edit contact"
    [(visible)]="editContactActive"
    [modal]="true"
    [style]="{width: '400px'}"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="edit-contact-form">
      <div class="input-row">
        <label>First Name</label>
        <input type="text" pInputText [(ngModel)]="contact.firstName" maxlength="16">
      </div>
      <div class="input-row">
        <label>Last Name</label>
        <input type="text" pInputText [(ngModel)]="contact.lastName" maxlength="16">
      </div>
      <div class="input-row">
        <label>Phone</label>
        <input type="text" pInputText [ngModel]="contact.phone" disabled>
      </div>
      <div class="btn-row">
        <button pButton label="Save" (click)="saveContact()"></button>
      </div>
    </div>
  </p-dialog>
</ng-container>
