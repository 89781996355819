<ng-container *ngIf="formSubmission">
  <div class="form-list-item">
    <div class="selector" (click)="$event.stopPropagation()">
      <p-checkbox name="selected" (onChange)="selectedChanged()" [(ngModel)]="selected" [binary]="true" [disabled]="!formSubmission.completedAt"></p-checkbox>
    </div>
    <div class="form-name">
      <span class="label">Form:</span>{{ formSubmission.form.name }}
    </div>
    <ng-container *ngIf="!client?.pmsId">
      <div class="client-name">
        <span class="label">Client:</span>
        {{ formSubmission.clientName && formSubmission.clientName.trim() !== "" ? formSubmission.clientName : "Unmatched" }}
      </div>
      <div class="created">
        <span class="label">Sent:</span
        >{{ formSubmission.createdAt | date : "dd/MM/yy 'at' h:mma" }}
      </div>
    </ng-container>
    <div class="completed">
      <div class="capsule">
        <span class="label">Status:</span>
        <ng-container *ngIf="!formSubmission.completedAt">
          <capsule
            label="Pending"
            backgroundColor="var(--orange-400)"
            [small]="true"
          ></capsule>
        </ng-container>
        <ng-container *ngIf="formSubmission.completedAt">
          <capsule
            label="Complete"
            backgroundColor="var(--success-color)"
            [small]="true"
          ></capsule>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="isAutomaticFormCompletionEnabled">
      <div class="synced-to-pms">
        <span class="label">Saved to PMS:</span>
        <ng-container *ngIf="formSubmission?.form?.saveToPms && formSubmission.clientName?.trim()">
          <div class="not-saved-to-pms">
            <ng-container *ngIf="formSubmission?.syncedToPms">
              <i
                class="pi pi-check-circle"
                pTooltip="Saved to PMS"
                tooltipStyleClass="success-tooltip"
                tooltipPosition="top"
              ></i>
            </ng-container>
            <ng-container *ngIf="!formSubmission?.syncedToPms && formSubmission?.syncAttemptedAt">
              <div class="retry-save-to-pms syncing"  tooltipStyleClass="tooltip-error" pTooltip="This form could not sync with your PMS. Please try syncing again or download it as a PDF to manually add to your PMS using the options in the form menu." tooltipPosition="top">
                <ng-container *ngIf="isSyncing">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M496 48V192c0 17.69-14.31 32-32 32H320c-17.69 0-32-14.31-32-32s14.31-32 32-32h63.39c-29.97-39.7-77.25-63.78-127.6-63.78C167.7 96.22 96 167.9 96 256s71.69 159.8 159.8 159.8c34.88 0 68.03-11.03 95.88-31.94c14.22-10.53 34.22-7.75 44.81 6.375c10.59 14.16 7.75 34.22-6.375 44.81c-39.03 29.28-85.36 44.86-134.2 44.86C132.5 479.9 32 379.4 32 256s100.5-223.9 223.9-223.9c69.15 0 134 32.47 176.1 86.12V48c0-17.69 14.31-32 32-32S496 30.31 496 48z"/></svg>
                </ng-container>
                <ng-container *ngIf="!isSyncing">
                  <img src="/assets/images/icons/pms-fail.svg" class="pms-sync-icon" alt="">
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="!formSubmission?.syncedToPms && !formSubmission?.syncAttemptedAt" >
              <img src="assets/images/icons/pending-submission.png" 
                height="22px"
                alt="Pending submission to PMS" 
                pTooltip="Pending form completion"
                tooltipStyleClass="tooltip-error"
                tooltipPosition="top">
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!formSubmission?.form?.saveToPms || !formSubmission.clientName?.trim()">
          N/A
        </ng-container>
      </div>
    </ng-container>
    <div class="actions">
      <div class="actions-opener" (clickOutside)="actionsOpen = false" (click)="toggleActions()"><img src="assets/images/icons/dots.svg" alt=""></div>
      <ng-container *ngIf="actionsOpen">
        <div class="actions-dropdown">
          <div class="action" (click)="viewSub()">
            View
          </div>
          <div *ngIf="formSubmission.completedAt" class="action" (click)="downloadPdf(formSubmission)">
            Download PDF
          </div>
          <div *ngIf="!formSubmission?.syncedToPms && formSubmission?.syncAttemptedAt && !isSyncing" class="action" (click)="retrySaveToPms()">
            Retry PMS Sync
          </div>
        </div>
      </ng-container>
    </div>
    <div class="view-status">
      <ng-container *ngTemplateOutlet="newFlag"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #newFlag>
  <ng-container *ngIf="formSubmission && !formSubmission.viewedAt && formSubmission.completedAt">
    <div class="new">
      <capsule [label]="'New'" [ngStyle]="{
        'position': 'absolute', 
        'top': '-10px', 
        'left': '-10px'
      }"
      backgroundColor="var(--alert-color)"
      [small]="true"></capsule>
    </div>
  </ng-container>
</ng-template>

<ng-template #statusBar>
  <ng-container *ngIf="formSubmission && !formSubmission.viewedAt && formSubmission.completedAt">
    <div class="status-tab" [ngStyle]="{'background-color': 'var(--alert-color)'}"></div>
  </ng-container>
</ng-template>

