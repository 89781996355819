import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { FormSubmission } from '../interfaces/form-submission';
import { FormFilterType } from '../../enums/form-filter-type';
import { FormDraftPublishedStatus } from '../../enums/form-draft-published-status';
import { FormFilterSelection } from '../../interfaces/form-filter-selection';
import { FormTakePayment } from '../../enums/form-take-payment';
import {Client} from '../../models/Client';
import {Contact} from '../../models/Contact';
import {Channel} from '../../enums/channel';
import {Form} from '../interfaces/form';
import {Patient} from '../../models/Patient';
import { FormSubmissionFilterType } from '../../enums/form-submission-filter-type';
import { FormSubmissionFilterSelection } from '../../interfaces/form-submissions-filter-selection';
import { FormFilterArchiveStatus } from '../../enums/form-filter-archive-status';

export interface FormsState {
  newFormRequestOpen: boolean;
  formRequestClient: Client | null;
  formRequestPatients: Patient[];
  formRequestContact: Contact | null;
  formRequestChannel: Channel | null;
  patientsLoading: boolean;
  forms: Form[];
  currentForm: Form | null;
  formToSend: Form | null;
  createFormSubmissionInProgress: boolean;
  formSubmissions: FormSubmission[];
  clientFormSubmissions: FormSubmission[];
  formSubmissionsTotalCount: number;
  formSubmissionsLoading: boolean;
  currentFormSubmission: FormSubmission | null;
  formPreviewResult: Form | null;
  filters: FormFilterSelection;
  formSubmissionFilters: FormSubmissionFilterSelection;
  syncingFormSubmissions: string[],
  selectedFormSubmissions: FormSubmission[],
  selectedFormSubmissionUuids: string[],
}

export const initialState: FormsState = {
  newFormRequestOpen: false,
  formRequestClient: null,
  formRequestPatients: [],
  formRequestContact: null,
  formRequestChannel: null,
  patientsLoading: false,
  forms: [],
  currentForm: null,
  formToSend: null,
  createFormSubmissionInProgress: false,
  formSubmissions: [],
  clientFormSubmissions: [],
  formSubmissionsTotalCount: 0,
  formSubmissionsLoading: true,
  currentFormSubmission: null,
  formPreviewResult: null,
  filters: {
    [FormFilterType.SEARCH_TERM]: '',
    [FormFilterType.TAKEPAYMENT]: [],
    [FormFilterType.DRAFT_PUBLISHED_STATUS]: [],
    [FormFilterType.ARCHIVE_STATUS]: [
      FormFilterArchiveStatus.NOT_ARCHIVED
    ]
  },
  formSubmissionFilters: {
    [FormSubmissionFilterType.DATE]: '',
    [FormSubmissionFilterType.SAVED_TO_PMS]: [],
    [FormSubmissionFilterType.STATUS]: [],
  },
  syncingFormSubmissions: [],
  selectedFormSubmissions: [],
  selectedFormSubmissionUuids: [],
};

const formsReducer = createReducer(
  initialState,
  on(actions.OpenFormRequest, (state, payload) => ({
    ...state,
    newFormRequestOpen: true,
  })),
  on(actions.CloseFormRequest, (state, payload) => ({
    ...state,
    newFormRequestOpen: false,
    createFormSubmissionInProgress: false,
  })),
  on(actions.SetFormRequestClient, (state, payload) => ({
    ...state,
    formRequestClient: payload.client ?? null,
    patientsLoading: true,
  })),
  on(actions.GetClientFormSubmissionsSuccess, (state, payload) => ({
    ...state,
    clientFormSubmissions: payload.formSubmissions,
  })),
  on(actions.GetMoreSendFormPatients, (state, payload) => ({
    ...state,
    patientsLoading: true,
  })),
  on(actions.GetSendFormPatientsSuccess, (state, payload) => {
    if (payload.replace) {
      return {
        ...state,
        formRequestPatients: payload.patients,
        patientsLoading: false,
      };
    }

    return {
      ...state,
      formRequestPatients: [...state.formRequestPatients, ...payload.patients],
      patientsLoading: false,
    };
  }),
  on(actions.SetFormRequestContact, (state, payload) => ({
    ...state,
    formRequestContact: payload.contact ?? null,
  })),
  on(actions.SetFormRequestChannel, (state, payload) => ({
    ...state,
    formRequestChannel: payload.channel ?? null,
  })),
  on(actions.SearchFormsSuccess, (state, payload) => ({
    ...state,
    forms: payload.forms,
  })),
  on(actions.GetAllFormsSuccess, (state, payload) => ({
    ...state,
    forms: payload.forms,
  })),
  on(actions.GetForm, (state, payload) => ({
    ...state,
    currentForm: null,
  })),
  on(actions.ClearCurrentForm, (state, payload) => ({
    ...state,
    currentForm: null,
  })),
  on(actions.GetFormSuccess, (state, payload) => ({
    ...state,
    currentForm: payload.form,
  })),
  on(actions.GetFormToSend, (state, payload) => ({
    ...state,
    formToSend: null,
  })),
  on(actions.GetFormToSendSuccess, (state, payload) => ({
    ...state,
    formToSend: payload.form,
  })),
  on(actions.ArchiveFormSuccess, (state, payload) => ({
    ...state,
    forms: [
      ...state.forms.map((form) => {
        if (form.id === payload.form.id) {
          return {
            ...form,
            archivedAt: payload.form.archivedAt,
          };
        }

        return form;
      }),
    ],
  })),
  on(actions.CreateFormSubmission, (state, payload) => ({
    ...state,
    createFormSubmissionInProgress: true,
  })),
  on(actions.CreateFormSubmissionSuccess, (state, payload) => ({
    ...state,
    newFormRequestOpen: false,
    createFormSubmissionInProgress: false,
  })),
  on(actions.CreateFormSubmissionFailed, (state, payload) => ({
    ...state,
    newFormRequestOpen: false,
    createFormSubmissionInProgress: false,
  })),
  on(actions.UpdateFormSubmissionStatusFilter, (state, payload) => ({
    ...state,
    formSubmissionFilters: {
      ...state.formSubmissionFilters,
      [FormSubmissionFilterType.STATUS]: state.formSubmissionFilters[
        FormSubmissionFilterType.STATUS
      ].includes(payload.option)
        ? [
            ...state.formSubmissionFilters[FormSubmissionFilterType.STATUS].filter(
              (val: string) => val !== payload.option
            ),
          ]
        : [...state.formSubmissionFilters[FormSubmissionFilterType.STATUS], payload.option],
    },
  })),
  on(actions.UpdateFormSubmissionSaveToPmsFilter, (state, payload) => ({
    ...state,
    formSubmissionFilters: {
      ...state.formSubmissionFilters,
      [FormSubmissionFilterType.SAVED_TO_PMS]: state.formSubmissionFilters[
        FormSubmissionFilterType.SAVED_TO_PMS
      ].includes(payload.option)
        ? [
            ...state.formSubmissionFilters[FormSubmissionFilterType.SAVED_TO_PMS].filter(
              (val: string) => val !== payload.option
            ),
          ]
        : [...state.formSubmissionFilters[FormSubmissionFilterType.SAVED_TO_PMS], payload.option],
    },
  })),
  on(actions.UpdateFormSubmissionDateFilter, (state, payload) => {
    return {
      ...state,
      formSubmissionFilters: {
        ...state.formSubmissionFilters,
        [FormSubmissionFilterType.DATE]:
            payload.date !== undefined
              ? typeof payload.date === 'object'
              ? JSON.stringify(payload.date)
              : `${payload.date}`
              : state.formSubmissionFilters[FormSubmissionFilterType.DATE] || '',
            
      },
    }
  }),
  on(actions.GetFormSubmissionsSuccess, (state, payload) => ({
    ...state,
    formSubmissionsLoading: false,
    formSubmissions: payload.formSubmissions,
    formSubmissionsTotalCount: payload.totalCount,
  })),
  on(actions.GetFormSubmissionsFailed, (state, payload) => ({
    ...state,
    formSubmissionsLoading: false,
    formSubmissionsTotalCount: 0,
  })),
  on(actions.GetFormSubmission, (state, payload) => ({
    ...state,
    currentFormSubmission: null,
  })),
  on(actions.GetFormSubmissionSuccess, (state, payload) => ({
    ...state,
    currentFormSubmission: payload.formSubmission,
  })),
  on(actions.GetFormSubmissionFailed, (state, payload) => ({
    ...state,
    currentFormSubmission: null,
  })),
  on(actions.OpenFormPreviewSuccess, (state, { form }) => ({
    ...state,
    formPreviewResult: form,
  })),
  on(actions.ClearFormPreviewResult, (state) => ({
    ...state,
    formPreviewResult: null,
  })),
  on(actions.UpdateFormFilterDraftStatus, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [FormFilterType.DRAFT_PUBLISHED_STATUS]: state.filters[
        FormFilterType.DRAFT_PUBLISHED_STATUS
      ].includes(payload.option)
        ? [
            ...state.filters[FormFilterType.DRAFT_PUBLISHED_STATUS].filter(
              (val: FormDraftPublishedStatus) => val !== payload.option
            ),
          ]
        : [
            ...state.filters[FormFilterType.DRAFT_PUBLISHED_STATUS],
            payload.option,
          ],
    },
  })),
  on(actions.UpdateFormFilterArchiveStatus, (state, payload) => {
    console.log("filters",state.filters);
    return {
    ...state,
    filters: {
      ...state.filters,
      [FormFilterType.ARCHIVE_STATUS]: state.filters[FormFilterType.ARCHIVE_STATUS].includes(payload.option) ? [
        ...state.filters[FormFilterType.ARCHIVE_STATUS].filter((val) => val !== payload.option)
      ] : [
        ...state.filters[FormFilterType.ARCHIVE_STATUS],
        payload.option
      ]
    }
  }
  }),
  on(actions.UpdateFormFilterTakePayment, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [FormFilterType.TAKEPAYMENT]: state.filters[
        FormFilterType.TAKEPAYMENT
      ].includes(payload.option)
        ? [
            ...state.filters[FormFilterType.TAKEPAYMENT].filter(
              (val: FormTakePayment) => val !== payload.option
            ),
          ]
        : [...state.filters[FormFilterType.TAKEPAYMENT], payload.option],
    },
  })),
  on(actions.SetTemplateSearchTerm, (state, { option }) => ({
    ...state,
    filters: {
      ...state.filters,
      [FormFilterType.SEARCH_TERM]: option,
    },
  })),
  on(actions.ClearTemplateFilters, (state) => {
    return {
      ...state,
      filters: { ...initialState.filters },
      formSubmissionFilters: { ...initialState.formSubmissionFilters },
      selectedFormSubmissions: [],
      selectedFormSubmissionUuids: [],
    };
  }),
  
  on(actions.ExportFormsSubmission, (state, payload) => {
    return {
      ...state,
      pdfDownloadInProgress: true
    };
  }),
  on(actions.ExportFormsSubmissionComplete, (state, payload) => {
    return {
      ...state,
      pdfDownloadInProgress: false
    };
  }),
  on(actions.RetryFormsSubmissionSyncToPms, (state, payload) => ({
    ...state,
    syncingFormSubmissions: [
      ...state.syncingFormSubmissions,
      payload.formSubmissionUuid
    ]
  })),
  on(actions.RetryFormsSubmissionSyncToPmsSuccess, (state, payload) => {
    let formSubmissions: FormSubmission[] = [];

    if (state.formSubmissions) {
      const updatedFormSubmission = {
        ...payload.formSubmission,
        syncedToPms: true,
      };
      
      formSubmissions = state.formSubmissions.map((formSubmission) =>
        formSubmission?.uuid === payload.formSubmission?.uuid
          ? updatedFormSubmission
          : formSubmission
      );
    }

    return {
      ...state,
      syncingFormSubmissions: state.syncingFormSubmissions.filter((uuid) => uuid !== payload.formSubmission?.uuid),
      formSubmissions
    };
  }),
  
  on(actions.RetryFormsSubmissionSyncToPmsFailed, (state, payload) => {
    let formSubmissions: FormSubmission[] | null = null;
    return {
      ...state,
      syncingFormSubmissions: state.syncingFormSubmissions.filter((uuid) => uuid !== payload.formSubmissionUuid),
    };
  }),

  on(actions.SelectFormSubmissions, (state, payload) => {
    const selected = payload.selected;
    let selectedFormSubmissions: FormSubmission[];
    let selectedFormSubmissionUuids: string[];

    if (selected) {
      selectedFormSubmissions = [...state.selectedFormSubmissions, payload.formSubmission];
      selectedFormSubmissionUuids = [...state.selectedFormSubmissionUuids, payload.formSubmissionUuid];
    } else {
      selectedFormSubmissions = state.selectedFormSubmissions.filter(
        (submission) => submission.id !== payload.formSubmission.id
      );
      selectedFormSubmissionUuids = state.selectedFormSubmissionUuids.filter(
        (uuid) => uuid !== payload.formSubmissionUuid
      );
    }
    return {
      ...state,
      selectedFormSubmissions,
      selectedFormSubmissionUuids,
    };
  }),
 
);

export function reducer(
  state: FormsState | undefined,
  action: Action
): FormsState {
  return formsReducer(state, action);
}
