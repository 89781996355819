<div class="form-builder" [formGroup]="form">
  <ng-container *ngIf="loading">
    <div class="loading">
      <p-progressSpinner
        [style]="{ width: '32px', height: '32px' }"
        styleClass="dark-spinner"
        strokeWidth="6"
      ></p-progressSpinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <div class="constrainer">
      <div class="input-block">
        <div class="input-row">
          <div class="label">Form name<span class="required">*</span>:</div>
          <input pInputText type="text" formControlName="name" maxlength="60" />
          <ng-container
            *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched) && form.controls.name.errors?.['required']"
          >
            <div class="error">You must enter a name for this form.</div>
          </ng-container>
        </div>
        <div class="input-row">
          <div class="label">Form intro<span class="required">*</span>:<div class="tooltip"><i class="pi pi-info-circle"
            tooltipPosition="right"
            [pTooltip]="'A brief overview or context for the form, helping clients understand its purpose and encouraging them to complete.'"></i></div>
          </div>
          <input
            pInputText
            type="text"
            formControlName="intro"
          />
          <ng-container
            *ngIf="form.controls.intro.invalid && (form.controls.intro.dirty || form.controls.intro.touched) && form.controls.intro.errors?.['required']"
          >
            <div class="error">You must enter an intro for this form.</div>
          </ng-container>
        </div>
        <div class="input-row">
          <div class="label">
            Estimated duration<span class="required">*</span>:
            <div class="tooltip">
              <i
                class="pi pi-info-circle"
                tooltipPosition="right"
                [pTooltip]="
                  'An estimate for how long this form takes to complete, e.g. 10 minutes'
                "
              ></i>
            </div>
          </div>
          <input
            pInputText
            type="text"
            maxlength="30"
            formControlName="estimatedCompletionDuration"
          />
          <ng-container
            *ngIf="form.controls.estimatedCompletionDuration.invalid && (form.controls.estimatedCompletionDuration.dirty || form.controls.estimatedCompletionDuration.touched) && form.controls.estimatedCompletionDuration.errors?.['required']"
          >
            <div class="error">You must enter a duration for this form.</div>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="isAutomaticFormCompletionEnabled">
        <div class="save-to-pms-section">
          <div class="input-row">
            <div class="input checkbox">
              <p-checkbox formControlName="saveToPms" [binary]="true" (onChange)="saveToPmsToggled()"></p-checkbox>
              <div class="label">Automatically save completed forms to PMS</div>
            </div>
          </div>
          <ng-container *ngIf="form.controls.saveToPms.value === true">
            <div class="input-row halfs">
              <div class="input">
                <div class="label">Save against:</div>
                <select class="primary" formControlName="saveAgainst" pInputText>
                  <ng-container *ngFor="let saveAgainstOption of saveAgainstOptions">
                    <option [value]="saveAgainstOption.value">{{ saveAgainstOption.label }}</option>
                  </ng-container>
                </select>
               
                <div *ngIf="form.controls.saveAgainst.invalid && (form.controls.saveAgainst.dirty || form.controls.saveAgainst.touched)" class="error">
                  <div *ngIf="form.controls.saveAgainst.errors?.['required']">Please select an option for saving the form to PMS.</div>
                </div>
              </div>
            </div>
          </ng-container>
          
        </div>
      </ng-container>
      <div class="pages" formArrayName="pages">
        <ng-container *ngFor="let page of pages().controls; let pageIndex=index" [formGroupName]="pageIndex">
          <div class="page" [id]="'page-' + pageIndex" >
            <div class="page-heading">
              Page #{{ pageIndex + 1 }}
              <div class="action-buttons">
                <div class="move-buttons">
                  <i class="pi pi-chevron-circle-up" (click)="movePageUp(pageIndex)" [class.disabled]="pageIndex === 0"></i>
                  <i class="pi pi-chevron-circle-down" (click)="movePageDown(pageIndex)" [class.disabled]="pageIndex === pages().length - 1"></i>
                </div>
                <ng-container *ngIf="pages().length > 1">
                  <i class="pi pi-minus-circle page-remover" (click)="removePage(pageIndex)"></i>
                </ng-container>
              </div>
              <input type="hidden" formControlName="id" />
            </div>
      
            <div class="input-row halfs">
              <div class="input">
                <div class="label">Page title<span class="required">*</span>:</div>
                <input pInputText type="text" formControlName="heading" maxlength="60" />
                <ng-container *ngIf="page.get('heading')?.invalid && (page.get('heading')?.dirty || page.get('heading')?.touched) && page.get('heading')?.errors?.['required']">
                  <div class="error">You must enter a title for this page.</div>
                </ng-container>
              </div>
              <div class="input">
                <div class="label">Page intro<span class="required">*</span>:
                  <div class="tooltip">
                    <i class="pi pi-info-circle" tooltipPosition="right" [pTooltip]="'A brief overview or context for page, helping clients understand its purpose.'"></i>
                  </div>
                </div>
                <input pInputText type="text" formControlName="intro" />
                <ng-container *ngIf="page.get('intro')?.invalid && (page.get('intro')?.dirty || page.get('intro')?.touched) && page.get('intro')?.errors?.['required']">
                  <div class="error">You must enter an intro for this page.</div>
                </ng-container>
              </div>
            </div>

            <div class="page-fields" formArrayName="items">
              <ng-container *ngFor="let field of getItems(pageIndex).controls; let fieldIndex=index" [formGroupName]="fieldIndex">
                <ng-container *ngIf="isField(field)">
                  <div class="field" [id]="'field-' + pageIndex + '-' + fieldIndex" >
                    <div class="field-heading" >
                      Field #{{ getFieldIndex(pageIndex, fieldIndex) + 1 }}
                      <div class="action-buttons">
                        <div class="move-buttons field-move-buttons">
                          <i class="pi pi-chevron-circle-up" (click)="moveItemUp(pageIndex, fieldIndex)" [class.disabled]="fieldIndex === 0"></i>
                          <i class="pi pi-chevron-circle-down" (click)="moveItemDown(pageIndex, fieldIndex)" [class.disabled]="fieldIndex === getItems(pageIndex).length - 1"></i>
                        </div>
                        <ng-container *ngIf="getFieldIndex(pageIndex, fieldIndex) >= 1">
                          <i class="pi pi-minus-circle field-remover" (click)="removeField(pageIndex, fieldIndex)"></i>
                        </ng-container>
                      </div>
                      <input type="hidden" formControlName="id" />
                    </div>

                    <div class="input-row halfs">
                      <div class="input">
                        <div class="label">Content type:
                          <div class="tooltip">
                            <i class="pi pi-info-circle" tooltipPosition="right" [pTooltip]="'Select the type of content you require for this question.'"></i>
                          </div>
                        </div>
                        <select class="primary" formControlName="type" (change)="typeChanged(pageIndex, fieldIndex)">
                          <ng-container *ngFor="let inputType of inputTypes">
                            <option [value]="inputType">{{ inputType | prettifyFormInputType }}</option>
                          </ng-container>
                        </select>
                      </div>
                      <div class="input">
                        <div class="label">Question<span class="required">*</span>:</div>
                        <input pInputText type="text" formControlName="label" />
                        <ng-container *ngIf="field.get('label')?.invalid && (field.get('label')?.dirty || field.get('label')?.touched) && field.get('label')?.errors?.['required']">
                          <div class="error">You must enter a question for this field.</div>
                        </ng-container>
                      </div>
                    </div>
        
                    <!-- Terms and Conditions Link -->
                    <ng-container *ngIf="field.get('type')?.value === formFieldType.AGREE_TO_TERMS">
                      <div class="input-row">
                        <div class="input">
                          <div class="label">Terms and Conditions Link:</div>
                          <input pInputText type="text" formControlName="url" maxlength="100" placeholder="https://www.example.com/terms" />
                        </div>
                      </div>
                    </ng-container>
        
                    <!-- Description -->
                    <ng-container *ngIf="field.get('type')?.value !== formFieldType.HIDDEN">
                      <div class="input-row">
                        <div class="input">
                          <div class="label">Description:
                            <div class="tooltip">
                              <i class="pi pi-info-circle" tooltipPosition="right" [pTooltip]="'An optional brief description to give context for the question.'"></i>
                            </div>
                          </div>
                          <ng-container *ngIf="field.get('type')?.value === formFieldType.AGREE_TO_TERMS; else description">
                            <quill-editor class="quill-editor-box-class" formControlName="description" [disabled]="true" [modules]="quillModules" placeholder="Description"></quill-editor>
                          </ng-container>
                          <ng-template #description>
                            <input pInputText type="text" formControlName="description" />
                          </ng-template>
                        </div>
                      </div>
                    </ng-container>
                    <div class="input-row halfs">
                      <!-- Placeholder Text -->
                      <ng-container *ngIf="field.get('type')?.value !== formFieldType.DATE && field.get('type')?.value !== formFieldType.HIDDEN">
                        <ng-container *ngIf="field.get('type')?.value | fieldTypeHasPlaceholder">
                          <div class="input">
                            <div class="label">Placeholder text:
                              <div class="tooltip">
                                <i class="pi pi-info-circle" tooltipPosition="right" [pTooltip]="'A brief hint or example text which displays inside the answer field that guides users on what to enter, which disappears when they start typing.'"></i>
                              </div>
                            </div>
                            <input pInputText type="text" formControlName="placeholder" maxlength="80" />
                          </div>
                        </ng-container>
        
                        <!-- Options -->
                        <ng-container *ngIf="field.get('type')?.value | fieldTypeHasOptions">
                          <div class="input" formArrayName="options">
                            <div class="label">Options:
                              <div class="tooltip">
                                <i class="pi pi-info-circle" tooltipPosition="right" [pTooltip]="'The choices available for clients to select.'"></i>
                              </div>
                            </div>
                            <ng-container *ngFor="let option of options(pageIndex, fieldIndex).controls; let optionIndex = index" [formGroupName]="optionIndex">
                              <input type="hidden" formControlName="id" />
                              <div class="option-row" [class]="(valueVisibility && valueVisibility[pageIndex] && valueVisibility[pageIndex][fieldIndex]) ? 'value-visible' : 'value-hidden'">
                                <div class="option-input">
                                  <ng-container *ngIf="valueVisibility && valueVisibility[pageIndex] && valueVisibility[pageIndex][fieldIndex]">
                                    <div class="label">Label<span class="required">*</span>:</div>
                                  </ng-container>
                                  <input pInputText type="text" formControlName="label"  />
                                  <ng-container *ngIf="option.get('label')?.invalid && (option.get('label')?.dirty || option.get('label')?.touched) && option.get('label')?.errors?.['required']">
                                    <div class="error">You must enter a label for this option.</div>
                                  </ng-container>
                                </div>
                                <ng-container *ngIf="valueVisibility && valueVisibility[pageIndex] && valueVisibility[pageIndex][fieldIndex]">
                                  <div class="option-input">
                                    <div class="label">Value<span class="required">*</span>:</div>
                                    <input pInputText type="text" formControlName="value" maxlength="100" />
                                    <ng-container *ngIf="option.get('value')?.invalid && (option.get('value')?.dirty || option.get('value')?.touched) && option.get('value')?.errors?.['required']">
                                      <div class="error">You must enter a value for this option.</div>
                                    </ng-container>
                                  </div>
                                </ng-container>
                                <div class="option-remover">
                                  <ng-container *ngIf="options(pageIndex, fieldIndex).length > 1">
                                    <i class="pi pi-minus-circle option-remover" (click)="removeOption(pageIndex, fieldIndex, optionIndex)"></i>
                                  </ng-container>
                                </div>
                              </div>
                            </ng-container>
                            <div class="btn-text-row" [class]="(valueVisibility && valueVisibility[pageIndex] && valueVisibility[pageIndex][fieldIndex]) ? 'value-visible' : 'value-hidden'">
                              <div class="input-row show-hide-text-contain">
                                <a href="" class="show-hide-text" (click)="showDisplayLabel($event, pageIndex, fieldIndex)">Show/Hide Value</a>
                              </div>
                              <p-button styleClass="p-button-secondary p-button-fullwidth p-button-smalltext" (click)="addOption(pageIndex, fieldIndex)" icon="pi pi-plus" label="Add Option"></p-button>
                            </div>
                          </div>
                        </ng-container>
                     
                        <div class="input">
                          <ng-container
                            *ngIf="
                              field.get('type')?.value ===
                                formFieldType.CHECKBOX ||
                              field.get('type')?.value ===
                                formFieldType.AGREE_TO_TERMS
                            "
                          >
                            <div class="checkbox">
                              <p-checkbox
                                formControlName="default"
                                [binary]="true"
                              ></p-checkbox>
                              <div class="label">Checked by default</div>
                            </div>
                          </ng-container>
                          <!-- Default Value -->
                          <ng-container *ngIf="field.get('type')?.value !== formFieldType.CHECKBOX && field.get('type')?.value !== formFieldType.AGREE_TO_TERMS">
                            <ng-container *ngIf="!(field.get('type')?.value | fieldTypeHasOptions)">
                              <div class="label">Default:</div>
                              <input
                                pInputText
                                type="text"
                                formControlName="default"
                                maxlength="100"
                              />
                            </ng-container>
                            <ng-container *ngIf="field.get('type')?.value | fieldTypeHasDefaultOption">
                              <div class="label">Default:</div>
                              <select class="primary" formControlName="default">
                                <option value="-1"></option>
                                <ng-container
                                  *ngFor="
                                    let option of options(pageIndex, fieldIndex)
                                      .controls;
                                    let optionIndex = index
                                  "
                                >
                                  <option value="{{ optionIndex }}">
                                    {{ option.get("label")?.value }}
                                  </option>
                                </ng-container>
                              </select>
                              <ng-container
                                *ngIf="field.get('default')?.invalid && (field.get('default')?.dirty || field.get('default')?.touched) && field.get('default')?.errors?.['required']"
                              >
                                <div class="error">
                                  You must select a default option.
                                </div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
        
                    <!-- Prefill and Readonly -->
                    <ng-container *ngIf="field.get('type')?.value | fieldCanBePrefilled">
                      <div class="input-row halfs">
                        <ng-container *ngIf="field.get('type')?.value !== formFieldType.HIDDEN">
                          <div class="input">
                            <div class="checkbox">
                              <p-checkbox 
                                formControlName="prefill" 
                                [binary]="field.get('customizeContent')?.value===false"  
                                [disabled]="field.get('customizeContent')?.value===true" 
                              ></p-checkbox>
                              <div class="label">Prefill this field</div>
                            </div>
                            <ng-container>
                            <div class="checkbox" >
                              <p-checkbox 
                                formControlName="customizeContent" 
                                [binary]="true" 
                                [disabled]="field.get('prefill')?.value===true"
                              ></p-checkbox>
                              <div class="label">Allow clinic to change the value before sending
                                <div class="tooltip">
                                  <i
                                    class="pi pi-info-circle"
                                    tooltipPosition="right"
                                    [pTooltip]="
                                      'When selected the clinic will be prompted to enter a value for this field before sending to each client, allowing this value to be customised specifically for each form request.'
                                    "
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                            <ng-container *ngIf="field.get('prefill')?.value === true || field.get('customizeContent')?.value === true">
                              <div class="checkbox space-above">
                                <p-checkbox formControlName="readonly" [binary]="true"></p-checkbox>
                                <div class="label">Make this field read-only</div>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="field.get('customizeContent')?.value != true && (field.get('prefill')?.value === true || field.get('type')?.value === formFieldType.HIDDEN)">
                          <div class="input">
                            <div class="label">Prefill with:</div>
                            <select class="primary" formControlName="prefillWith">
                              <ng-container *ngFor="let prefillOption of prefillOptions">
                                <option value="{{ prefillOption }}">{{ prefillOption | prettifyFormFieldPrefillOption }}</option>
                              </ng-container>
                            </select>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                    <!-- Required Field -->
                    <div class="input-row">
                      <div class="input">
                        <div class="checkbox">
                          <p-checkbox formControlName="required" [binary]="true"></p-checkbox>
                          <div class="label">This field is required
                            <div class="tooltip">
                              <i class="pi pi-info-circle" tooltipPosition="right" [pTooltip]="'When selected, clients must complete this question before submitting the form.'"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </ng-container>
                <ng-container *ngIf="isContent(field)">
                  <div class="field">
                    <div class="field-heading">
                      Content #{{ getContentIndex(pageIndex, fieldIndex) + 1 }}
                      <div class="action-buttons">
                        <div class="move-buttons field-move-buttons">
                          <i class="pi pi-chevron-circle-up" (click)="moveItemUp(pageIndex, fieldIndex)" [class.disabled]="fieldIndex === 0"></i>
                          <i class="pi pi-chevron-circle-down" (click)="moveItemDown(pageIndex, fieldIndex)" [class.disabled]="fieldIndex === getItems(pageIndex).length - 1"></i>
                        </div>
                        <ng-container *ngIf="getItems(pageIndex).length > 1">
                          <i class="pi pi-minus-circle field-remover" (click)="removeContent(pageIndex, fieldIndex)"></i>
                        </ng-container>
                      </div>
                      <input type="hidden" formControlName="id" />
                    </div>

                    <div class="input-row">
                      <div class="input">
                        <div class="label">Content:</div>
                        <quill-editor class="quill-editor-box-class" formControlName="content" [disabled]="true" [modules]="quillModules" [placeholder]="''"></quill-editor>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <!-- Add Content and Add Field Buttons -->
            <div class="btn-row">
              <span class="btn-content">
                <span>
                  <p-button styleClass="p-button-secondary p-button-fullwidth p-button-smalltext" icon="pi pi-plus" label="Add Content" (click)="addContent(pageIndex, true)"></p-button>
                </span>
              </span>
              <span class="btn-field">
                <span>
                  <p-button styleClass="p-button-secondary p-button-fullwidth p-button-smalltext" icon="pi pi-plus" label="Add Field" (click)="addField(pageIndex, true)"></p-button>
                </span>
              </span>
            </div>
          </div>
        </ng-container>
      </div>
      
      <div class="btn-row add-page">
        <p-button
          styleClass="p-button-secondary p-button-fullwidth p-button-smalltext"
          (click)="addPage(true)"
          icon="pi pi-plus"
          label="Add Page"
        ></p-button>
      </div>
      <div class="payment-section">
        <div class="input-row">
          <div class="input checkbox">
            <p-checkbox
              formControlName="takePayment"
              [binary]="true"
              (onChange)="takePaymentToggled()"
            ></p-checkbox>
            <div class="label">Take a payment when this form is completed</div>
          </div>
        </div>
        <ng-container *ngIf="form.controls.takePayment.value === true">
          <div class="input-row halfs">
            <div class="input">
              <div class="label">Payment amount<span class="required">*</span>:</div>
              <input pInputText type="text" formControlName="paymentAmount" />
              <ng-container
                *ngIf="
                  form.controls.paymentAmount.invalid &&
                  (form.controls.paymentAmount.dirty ||
                    form.controls.paymentAmount.touched)
                "
              >
                <ng-container
                  *ngFor="
                    let err of form.controls.paymentAmount.errors || {}
                      | keyvalue
                  "
                >
                  <div class="error">{{ err.value.message }}</div>
                </ng-container>
              </ng-container>
            </div>
            <div class="input">
              <div class="label">Payment description<span class="required">*</span>:</div>
              <input
                pInputText
                type="text"
                formControlName="paymentDescription"
                maxlength="100"
              />
              <ng-container
                *ngIf="form.controls.paymentDescription.invalid && (form.controls.paymentDescription.dirty || form.controls.paymentDescription.touched) && form.controls.paymentDescription.errors?.['required']"
              >
                <div class="error">You must enter a payment description.</div>
              </ng-container>
            </div>
          </div>
          <div class="input-row halfs">
            <div class="input">
              <div class="label">Expires after:</div>
              <select class="primary" formControlName="paymentExpiresAfter">
                <ng-container *ngFor="let expiryOption of expiryOptions">
                  <option value="{{ expiryOption.amount }}">
                    {{ expiryOption.label }}
                  </option>
                </ng-container>
              </select>
            </div>
            <div class="input checkbox">
              <p-checkbox
                formControlName="paymentPreAuth"
                [binary]="true"
              ></p-checkbox>
              <div class="label">Pre-authorise client's card</div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="btn-row form-action-buttons">
        <p-button 
          styleClass="p-button-secondary" 
          (click)="openPreview(true)" 
          label="Save & Preview">
        </p-button>
      
        <p-button 
          *ngIf="!formId || (formId && isDraft)" 
          styleClass="p-button-secondary" 
          (click)="submit(true)" 
          label="Save Draft">
        </p-button>
      
        <p-button 
          *ngIf="!formId" 
          styleClass="p-button-secondary" 
          (click)="publish()" 
          label="Publish">
        </p-button>
      
        <p-button 
          *ngIf="formId && isDraft" 
          styleClass="p-button-secondary" 
          (click)="publish()" 
          label="Publish">
        </p-button>
      
        <p-button 
          *ngIf="formId && !isDraft" 
          styleClass="p-button-secondary" 
          (click)="unpublish()" 
          label="Unpublish">
        </p-button>

        <p-button 
          *ngIf="formId && !isDraft" 
          styleClass="p-button-secondary" 
          (click)="submit()" 
          label="Save Form">
        </p-button>
        
      </div>     
    </div>
  </ng-container>
  <p-dialog 
    header="Form Preview"
    [(visible)]="previewOpen"
    [modal]="true"
    [style]="{width: '50%'}"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
    [closable]="true" >
    <iframe *ngIf="iframeUrl" [src]="iframeUrl" width="100%" height="800px"></iframe>
  </p-dialog>
</div>
