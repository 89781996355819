<div class="forms-page">
  <div class="constrainer">
    <ng-container *ngIf="loading">
      <div class="loading">
        <p-progressSpinner [style]="{width: '40px', height: '40px'}" styleClass="custom-spinner"
          strokeWidth="4"></p-progressSpinner>
      </div>
    </ng-container>

    <div class="filters">
      <form-filter></form-filter>
    </div>

    <div class="header">
      <button pButton label="New Form" class="p-button-secondary" (click)="newForm()"></button>
    </div>

    <div class="forms-list">
      <div class="headings">
        <div class="name">Name</div>
        <div class="take-payment">Take Payment?</div>
        <div class="status">Status</div>
        <div class="actions"></div>
      </div>
      <ng-container *ngFor="let form of forms">
        <div class="form-list-item">
          <form-list-item [form]="form"></form-list-item>
        </div>
      </ng-container>
      <ng-container *ngIf="(forms && forms.length === 0) && !isLoading">
        <div class="no-results">
          <h1> Sorry, we couldn't find any forms matching your filter. </h1>
          <h2>Try and alter the following:</h2>
          <ul>
            <li>Search term</li>
            <li>Status filter</li>
            <li>Payment filter</li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>