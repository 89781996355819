import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/reducers';
import * as FormActions from './actions';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of, takeUntil} from 'rxjs';
import {CloseFormRequest, GetMoreSendFormPatients, GetSendFormPatientsSuccess, SearchForms} from "./actions";
import * as PatientActions from "../../patients/state/actions";
import {getPatientPage} from "../../patients/state/selectors";
import {getConversationClient} from "../../conversation/state/selectors";
import {Client} from "../../models/Client";
import {Patient} from "../../models/Patient";
import * as ConversationActions from "../../conversation/state/actions";
import {getCurrentPractice} from "../../practices/state/selectors";
import {FormsService} from "../forms.service";
import {Form} from "../interfaces/form";
import {Router} from "@angular/router";
import {Noop} from "../../state/actions";
import {MessageService} from "primeng/api";
import * as ProductRequestActions from "../../product-requests/state/actions";
import {ClientService} from "../../clients/client.service";
import {getProductRequestClient} from "../../product-requests/state/selectors";
import {getFormRequestClient} from "./selectors";
import {FormSubmission} from "../interfaces/form-submission";
import {Contact} from "../../models/Contact";
import {Channel} from "../../enums/channel";
import {FormUpdateDto} from "../interfaces/dto/form-update.dto";

@Injectable()
export class FormsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private formsService: FormsService,
    private router: Router,
    private messageService: MessageService,
    private clientService: ClientService
  ) {}

  openFormRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.OpenFormRequest),
      switchMap((action) => {
        return of(
          FormActions.SetFormRequestClient({ client: action.client }),
          FormActions.SetFormRequestContact({ contact: action.contact }),
          FormActions.SetFormRequestChannel({ channel: action.channel })
        );
      })
    )
  );

  closeFormRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.CloseFormRequest),
      switchMap((action) => {
        return of(
          FormActions.SetFormRequestClient({}),
          FormActions.SetFormRequestContact({}),
          FormActions.SetFormRequestChannel({})
        );
      })
    )
  );

  getPatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.SetFormRequestClient),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        if (action.client && practice) {
          return this.clientService
            .getClientByPmsId(action.client.pmsId, practice.id, 0)
            .pipe(
              map((result: { client: Client; patients: Patient[] }) => {
                return FormActions.GetSendFormPatientsSuccess({
                  patients: result.patients,
                  replace: true,
                });
              })
            );
        }

        return of(
          FormActions.GetSendFormPatientsSuccess({
            patients: [],
            replace: true,
          })
        );
      })
    )
  );

  getMorePatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.GetMoreSendFormPatients),
      withLatestFrom(
        this.store.select(getCurrentPractice),
        this.store.select(getFormRequestClient)
      ),
      mergeMap(([action, practice, client]) => {
        if (client && practice) {
          return this.clientService
            .getClientByPmsId(client.pmsId, practice.id, action.page)
            .pipe(
              map((result: { client: Client; patients: Patient[] }) => {
                return FormActions.GetSendFormPatientsSuccess({
                  patients: result.patients,
                  replace: false,
                });
              })
            );
        }

        return of(
          FormActions.GetSendFormPatientsSuccess({
            patients: [],
            replace: true,
          })
        );
      })
    )
  );

  searchForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.SearchForms),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        return this.formsService.searchForms(practice, action.search , action.isDraft).pipe(
          map((result: Form[]) => {
            return FormActions.SearchFormsSuccess({ forms: result });
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when getting forms',
              life: 5000,
            });
            return of(FormActions.SearchFormsFailed());
          })
        );
      })
    )
  );

  getForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.GetForm),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        return this.formsService.getForm(practice, action.formId).pipe(
          map((result: Form) => {
            return FormActions.GetFormSuccess({ form: result });
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when getting this form',
              life: 5000,
            });
            return of(FormActions.GetFormFailed());
          })
        );
      })
    )
  );

  getAllForms$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.GetAllForms),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.getALlForms(practice, action.search).pipe(
        map((result: Form[]) => {
          return FormActions.GetAllFormsSuccess({forms: result});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when getting forms',
            life: 5000
          });
          return of(FormActions.GetAllFormsFailed());
        }),
      );
    })
  ));

  getFormToSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.GetFormToSend),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        return this.formsService.getForm(practice, action.formId).pipe(
          map((result: Form) => {
            return FormActions.GetFormToSendSuccess({ form: result });
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when getting this form',
              life: 5000,
            });
            return of(Noop());
          })
        );
      })
    )
  );

  duplicateForm$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.DuplicateForm),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.duplicateForm(practice, action.formId).pipe(
        map((result: Form) => {
          return FormActions.DuplicateFormSuccess({form: result});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when getting this form',
            life: 5000
          });
          return of(FormActions.DuplicateFormFailed());
        }),
      );
    })
  ));

  duplicateFormSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.DuplicateFormSuccess),
    tap((action) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Form created successfully',
        life: 5000
      });
      this.router.navigateByUrl(`/forms/edit/${action.form.id}`);
    }),
    map((action) => {
      return Noop();
    })
  ));

  createForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.CreateForm),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        return this.formsService.createForm(practice, action.dto).pipe(
          map((result: Form) => {
            return FormActions.CreateFormSuccess({ form: result, dto: action.dto });
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when creating this form',
              life: 5000,
            });
            return of(FormActions.CreateFormFailed());
          })
        );
      })
    )
  );

  createFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.CreateFormSuccess),
      tap((action) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Form created successfully',
          life: 5000,
        });
        this.router.navigateByUrl('/forms');
      }),
      map((action) => {
        return Noop();
      })
    )
  );

  updateForm$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.UpdateForm),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.updateForm(practice, action.dto).pipe(
        map((result: Form) => {
          return FormActions.UpdateFormSuccess({form: result,dto: action.dto});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when updating this form',
            life: 5000
          });
          return of(FormActions.UpdateFormFailed());
        }),
      );
    })
  ));
  updateFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.UpdateFormSuccess),
      tap((action) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Form updated successfully',
          life: 5000,
        });
        this.router.navigateByUrl('/forms');
      }),
      map((action) => {
        return Noop();
      })
    )
  );

  archiveForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.ArchiveForm),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        return this.formsService
          .archiveForm(practice, action.formId, action.archived)
          .pipe(
            map((result: Form) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: action.archived
                  ? 'Form archived successfully'
                  : 'Form restored successfully',
                life: 5000,
              });
              return FormActions.ArchiveFormSuccess({ form: result });
            }),
            catchError(() => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred when archiving this form',
                life: 5000,
              });
              return of(FormActions.ArchiveFormFailed());
            })
          );
      })
    )
  );

  openFormPreview$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.OpenFormPreview),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      // Check if it's an update or create action based on the existence of an ID
      if (action.dto && 'id' in action.dto) {
        return this.formsService.updateForm(practice, action.dto).pipe(
          map((result: Form) => FormActions.OpenFormPreviewSuccess({ form: result })),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when updating this form',
              life: 5000
            });
            return of(FormActions.OpenFormPreviewFailed());
          })
        );
      } else {
        return this.formsService.createForm(practice, action.dto).pipe(
          map((result: Form) => FormActions.OpenFormPreviewSuccess({ form: result })),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when creating this form',
              life: 5000
            });
            return of(FormActions.OpenFormPreviewFailed());
          })
        );
      }
    })
  ));
  
  publishForm$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.PublishForm),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      if (action.dto && 'id' in action.dto) {
        return this.formsService.updateForm(practice, action.dto).pipe(
          map((result: Form) => FormActions.PublishFormSuccess({ form: result })),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when publishing this form',
              life: 5000
            });
            return of(FormActions.PublishFormFailed());
          })
        );
      } else {
        return this.formsService.createForm(practice, action.dto).pipe(
          map((result: Form) => FormActions.PublishFormSuccess({ form: result })),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when publishing this form',
              life: 5000
            });
            return of(FormActions.PublishFormFailed());
          })
        );
      }
    })
  ));

  publishFormSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.PublishFormSuccess),
    tap((action) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Form published successfully',
        life: 5000
      });
      this.router.navigateByUrl('/forms');
    }),
    map((action) => {
      return Noop();
    })
  ));

  unpublishForm$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.UnpublishForm),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      if (action.dto && 'id' in action.dto) {
        return this.formsService.updateForm(practice, action.dto).pipe(
          map((result: Form) => FormActions.UnpublishFormSuccess({ form: result })),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when unpublishing this form',
              life: 5000
            });
            return of(FormActions.UnpublishFormFailed());
          })
        );
      } else {
        return this.formsService.createForm(practice, action.dto).pipe(
          map((result: Form) => FormActions.UnpublishFormSuccess({ form: result })),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when unpublishing this form',
              life: 5000
            });
            return of(FormActions.UnpublishFormFailed());
          })
        );
      }
    })
  ));

  unpublishFormSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.UnpublishFormSuccess),
    tap((action) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Form unpublished successfully',
        life: 5000
      });
      this.router.navigateByUrl('/forms');
    }),
    map((action) => {
      return Noop();
    })
  ));

  createFormSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.CreateFormSubmission),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        return this.formsService
          .createFormSubmission(practice, action.dto)
          .pipe(
            map((result: FormSubmission) => {
              return FormActions.CreateFormSubmissionSuccess({
                formSubmission: result,
                client: action.client,
                contact: action.contact,
                channel: action.channel,
              });
            }),
            catchError(() => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred when sending this form',
                life: 5000,
              });
              return of(FormActions.CreateFormSubmissionFailed());
            })
          );
      })
    )
  );

  createFormSubmissionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.CreateFormSubmissionSuccess),
      map((action) => {
        return FormActions.SendFormSubmission({
          formSubmission: action.formSubmission,
          client: action.client,
          contact: action.contact,
          channel: action.channel,
        });
      })
    )
  );

  sendFormSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.SendFormSubmission),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        return this.formsService
          .sendFormSubmission(
            practice,
            action.formSubmission,
            action?.client || null,
            action.contact || null,
            action?.channel
          )
          .pipe(
            map((result: { conversationId: number }) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Form sent successfully',
                life: 5000,
              });
              return FormActions.SendFormSubmissionSuccess();
            }),
            catchError(() => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred when sending this form',
                life: 5000,
              });
              return of(FormActions.SendFormSubmissionFailed());
            })
          );
      })
    )
  );

  sendFormSubmissionFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.SendFormSubmissionFailed),
      map((action) => {
        return FormActions.CloseFormRequest();
      })
    )
  );

  getFormSubmissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.GetFormSubmissions),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        return this.formsService
          .getFormSubmissions(
            practice,
            action.page,
            action.perPage,
            action.searchString,
            action?.status,
            action?.saveToPms,
            action?.date,
            action?.clientPmsId
          )
          .pipe(
            map(
              (result: { formSubs: FormSubmission[]; totalCount: number }) => {
                return FormActions.GetFormSubmissionsSuccess({
                  formSubmissions: result.formSubs,
                  totalCount: result.totalCount,
                });
              }
            ),
            catchError(() => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred when getting form submissions',
                life: 5000,
              });
              return of(FormActions.GetFormSubmissionsFailed());
            })
          );
      })
    )
  );

  getClientFormSubmissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.GetClientFormSubmissions),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        return this.formsService
          .getFormSubmissions(
            practice,
            1,
            5,
            '',
            '',
            '',
            '',
            action.client.pmsId,
            'createdAt'
          )
          .pipe(
            map(
              (result: { formSubs: FormSubmission[]; totalCount: number }) => {
                return FormActions.GetClientFormSubmissionsSuccess({
                  formSubmissions: result.formSubs
                });
              }
            ),
            catchError(() => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred when getting form submissions',
                life: 5000,
              });
              return of(FormActions.GetFormSubmissionsFailed());
            })
          );
      })
    )
  );

  getFormSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.GetFormSubmission),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        return this.formsService.getFormSubmission(practice, action.uuid).pipe(
          map((result: FormSubmission) => {
            return FormActions.GetFormSubmissionSuccess({
              formSubmission: result,
            });
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when getting this form submission',
              life: 5000,
            });
            return of(FormActions.GetFormSubmissionFailed());
          })
        );
      })
    )
  );

  exportFormsSubmission$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FormActions.ExportFormsSubmission),
        withLatestFrom(this.store.select(getCurrentPractice)),
        mergeMap(([action, practice]) => {
          if (
            practice &&
            Array.isArray(action.formSubmissionUuids) &&
            action.formSubmissionUuids.length > 0
          ) {
            return this.formsService
              .downloadPdfs(action.formSubmissionUuids, practice.id)
              .pipe(
                tap((data: Blob) => {
                  const fileType = data.type;
                  let fileName;

                  if (fileType === 'application/pdf') {
                    fileName = "form"
                    fileName += '.pdf';
                  } else {
                    fileName = "forms"
                    fileName += '.zip';
                  }

                  const file = new Blob([data]);
                  const fileURL = URL.createObjectURL(file);
                  const a = document.createElement('a');
                  a.href = fileURL;
                  a.target = '_blank';
                  a.download = fileName;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  this.store.dispatch(
                    FormActions.ExportFormsSubmissionComplete()
                  );
                }),
                catchError((error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail:
                      'There was an error downloading PDFs of these forms',
                    life: 5000,
                  });
                  this.store.dispatch(
                    FormActions.ExportFormsSubmissionComplete()
                  );
                  return of(null);
                })
              );
          } else {
            console.error(
              'Invalid practice or form submission UUIDs:',
              practice,
              action.formSubmissionUuids
            );
            return of(null);
          }
        })
      ),
    { dispatch: false }
  );

  retryFormsSubmissionSyncToPms$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.RetryFormsSubmissionSyncToPms),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.retrySaveFormSubmission(action.formSubmissionUuid, practice?.id)
        .pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Form Submission saved to PMS successfully',
              life: 5000
            });
          }),
          map((result: any) => {
            return FormActions.RetryFormsSubmissionSyncToPmsSuccess({formSubmission: result.formSubmission});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Saving to PMS failed',
              life: 5000
            });
            return of(FormActions.RetryFormsSubmissionSyncToPmsFailed({formSubmissionUuid: action.formSubmissionUuid}));
          })
        );
    }),
  ));
  
}

