import { Component, Input, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, take, takeWhile } from 'rxjs';
import { FormFilterType } from '../../../enums/form-filter-type';
import { FormsState } from '../../state/reducer';
import {
  ClearTemplateFilters,
  GetFormSubmissions,
  SetTemplateSearchTerm,
  UpdateFormFilterArchiveStatus,
  UpdateFormFilterDraftStatus,
  UpdateFormFilterTakePayment,
  UpdateFormSubmissionDateFilter,
  UpdateFormSubmissionSaveToPmsFilter,
  UpdateFormSubmissionStatusFilter,
} from '../../state/actions';
import { Channel } from '../../../enums/channel';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { Practice } from '../../../models/Practice';
import {
  getFilterFormSearchTerm,
  getFilterSelectedArchiveStatuses,
  getFilterSelectedDate,
  getFilterSelectedDraftStatuses,
  getFilterSelectedSaveToPMS,
  getFilterSelectedStatus,
  getFilterSelectedTakePayment,
  getFormFilterSelection,
  getFormSubmissionFilterSelection,
} from '../../state/selectors';
import { SearchForms } from '../../state/actions';
import { FormDraftPublishedStatus } from '../../../enums/form-draft-published-status';
import { FormTakePayment } from '../../../enums/form-take-payment';
import { FormSubmissionStatus } from '../../../enums/form-submission-status';
import { FormSubmissionFilterType } from '../../../enums/form-submission-filter-type';
import { FormSubmisstionSavedToPms } from '../../../enums/form-submission-saved-to-pms';
import { DateFilterOption } from '../../../interfaces/date-filter-option.interface';
import { dateFilterOptions } from '../../../constants/date-filter-options.constants';
import { DateFilterShorthand } from '../../../enums/date-filter-shorthand';
import { defaultConversationFilters } from '../../../constants/default-conversation-filters.constants';
import { ChangeDetectorRef } from '@angular/core';
import { FormFilterArchiveStatus } from '../../../enums/form-filter-archive-status';

@Component({
  selector: 'form-filter',
  templateUrl: './form-filter.component.html',
  styleUrl: './form-filter.component.scss',
})
export class FormFilterComponent {
  alive = true;
  @Input() source ?:string ='';
  @Input() whatsappChannel?: Channel;
  @Input() perPage?: number;
  @Input() page?: number;
  @Output() updateCurrentPage = new EventEmitter<number>();
  selectedOptionsFormSubmissionStatus: string[] = []; 
  selectedOptionsSavedToPms: string[] = []; 
  dateFilterOpen = false;
  customRangeOpen = false;
  defaultSelectedDate = null;
  selectedDate: DateFilterOption | null = this.defaultSelectedDate;
  practice$?: Observable<Practice | null>;
  dateOptions: DateFilterOption[] = dateFilterOptions;
  practice?: Practice | null;
  device = "desktop";
  FormFilterType = FormFilterType;
  FormSubmissionFilterType = FormSubmissionFilterType;
  mobileFiltersOpen = false;
  activeFilter: FormFilterType | FormSubmissionFilterType | null = null;
  selectedFilter:any={}
  selectedDraftStatus$: Observable<FormDraftPublishedStatus[]>;
  selectedArchiveStatus$: Observable<FormFilterArchiveStatus[]>;
  dateFilter: {start: string, end: string} | null | string = defaultConversationFilters.date;
  selectedTakePayment$: Observable<FormTakePayment[]>;
  searchTerm$: Observable<string>;
  filterLabels = {
    [FormFilterType.DRAFT_PUBLISHED_STATUS]: '',
    [FormFilterType.TAKEPAYMENT]: '',
    [FormFilterType.ARCHIVE_STATUS]: '',
    [FormSubmissionFilterType.DATE]: '',
    [FormSubmissionFilterType.SAVED_TO_PMS]: '',
    [FormSubmissionFilterType.STATUS]: '',
  };
  rangeDates: Date[] = [new Date(), new Date()];
  draftStatusOptions: FormDraftPublishedStatus[] = [
    FormDraftPublishedStatus.DRAFT,
    FormDraftPublishedStatus.PUBLISHED,
  ];
  formSubmissionStatus:FormSubmissionStatus[] = [
    FormSubmissionStatus.COMPLETED,
    FormSubmissionStatus.PENDING
  ]

  takePaymentOptions: FormTakePayment[] = [
    FormTakePayment.YES,
    FormTakePayment.NO,
  ];
  takeSavedToPmsOptions: FormSubmisstionSavedToPms[] =[
    FormSubmisstionSavedToPms.YES,
    FormSubmisstionSavedToPms.NO
  ]

  searchTerm: string = '';
  theme = '0';

  selectedSaveToPms$: Observable<string[]>;
  selectedDate$: Observable<string>;
  selectedStatuses$: Observable<string[]>;
  archiveStatusOptions: FormFilterArchiveStatus[] = [
    FormFilterArchiveStatus.NOT_ARCHIVED,
    FormFilterArchiveStatus.ARCHIVED,
  ];

  constructor(private store: Store<FormsState>,private cdr:ChangeDetectorRef) {
    this.searchTerm$ = this.store
      .select(getFilterFormSearchTerm)
      .pipe(takeWhile(() => this.alive));
    this.selectedDraftStatus$ = this.store
      .select(getFilterSelectedDraftStatuses)
      .pipe(takeWhile(() => this.alive));
    this.selectedArchiveStatus$ = this.store
      .select(getFilterSelectedArchiveStatuses)
      .pipe(takeWhile(() => this.alive));
    this.selectedTakePayment$ = this.store
      .select(getFilterSelectedTakePayment)
      .pipe(takeWhile(() => this.alive));
    this.selectedSaveToPms$ = this.store
      .select(getFilterSelectedSaveToPMS)
      .pipe(takeWhile(() => this.alive)); 
    this.selectedDate$ = this.store
      .select(getFilterSelectedDate)
      .pipe(takeWhile(() => this.alive));
    this.selectedStatuses$ = this.store
      .select(getFilterSelectedStatus)
      .pipe(takeWhile(() => this.alive));
  }

  ngOnInit(): void {
    this.subscribeToFilters();
    this.subscribeToCurrentPractice();
    this.device = this.getDevice();
    this.selectedFilter = {page: this.page, perPage: this.perPage};
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
  getDevice(): string {
    if (window.innerWidth <= 640) {
      return "mobile";
    }

    return "desktop";
  }
  subscribeToFilters(): void {
    this.selectedDraftStatus$.subscribe(
      (statuses: FormDraftPublishedStatus[]) => {
        this.filterLabels[FormFilterType.DRAFT_PUBLISHED_STATUS] =
          this.generateFilterLabel(
            FormFilterType.DRAFT_PUBLISHED_STATUS,
            statuses
          );
      }
    );

    this.selectedTakePayment$.subscribe((statuses: FormTakePayment[]) => {
      this.filterLabels[FormFilterType.TAKEPAYMENT] = this.generateFilterLabel(
        FormFilterType.TAKEPAYMENT,
        statuses
      );
    });

    this.selectedSaveToPms$.subscribe((savedToPms: string[]) => {
      this.filterLabels[FormSubmissionFilterType.SAVED_TO_PMS] = this.generateFilterLabel(
        FormSubmissionFilterType.SAVED_TO_PMS,
        savedToPms
      );
    });

    this.selectedDate$.subscribe((date: string) => {
      this.filterLabels[FormSubmissionFilterType.DATE] = date;
    });

    this.selectedStatuses$.subscribe((statuses: string[]) => {
      this.filterLabels[FormSubmissionFilterType.STATUS] = this.generateFilterLabel(
        FormSubmissionFilterType.STATUS,
        statuses
      );;
    });

    this.selectedArchiveStatus$.subscribe((statuses: FormFilterArchiveStatus[]) => {
      this.filterLabels[FormFilterType.ARCHIVE_STATUS] = this.generateFilterLabel(
        FormFilterType.ARCHIVE_STATUS,
        statuses
      );
    });

  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));

    this.practice$.subscribe((practice) => {
      if (practice) {
        this.practice = practice;
        if (practice) {
          this.theme = practice.theme ? practice.theme : '0';
        }
      }
    });
  }

  generateFilterLabel(
    type: FormFilterType | FormSubmissionFilterType,
    values: FormDraftPublishedStatus[] | FormTakePayment[] | FormSubmissionStatus[] | FormSubmisstionSavedToPms[] | string[]
  ): string {
    let label = '';

    if (values.length > 0) {
      label = values[0];

      if (values.length > 1) {
        label = `${label} + ${values.length - 1}`;
      }
    }

    return label;
  }

  toggleMobileFilters() {
    this.mobileFiltersOpen = !this.mobileFiltersOpen;
  }

  toggleActiveFilter(type: FormFilterType | FormSubmissionFilterType): void {
    if (this.activeFilter === type) {
      this.closeFilters();
      return;
    }

    this.setActiveFilter(type);
  }

  setActiveFilter(type: FormFilterType | FormSubmissionFilterType): void {
    this.activeFilter = type;
  }

  setFilteredTemplates(): void {
    if (this.source ==='form_submission' && this.page && this.perPage) {
      this.selectedFilter = {
        ...this.selectedFilter,
        searchString: this.searchTerm,
      };
      this.store.dispatch(SetTemplateSearchTerm({ option: this.searchTerm }));
      this.FormSubmitted(this.selectedFilter)
    } else {
      this.store.dispatch(SearchForms({ search: this.searchTerm }));
    }

  }

  FormSubmitted(data: any){
    this.store.dispatch(GetFormSubmissions(data));
  }

  clearFilters(): void {

    this.searchTerm = '';
    this.selectedOptionsFormSubmissionStatus = [];
    this.selectedOptionsSavedToPms = [];

    if (this.source === "form_submission") {
        this.handleFormSubmissionFilter();
        this.store.dispatch(ClearTemplateFilters());
    } else {
        this.setFilteredTemplates();
        this.store.dispatch(ClearTemplateFilters());
    }
    this.selectedFilter = {page: this.page, perPage: this.perPage};
    this.selectedDate = this.defaultSelectedDate;
    this.customRangeOpen = false;
    this.cdr.detectChanges()
}

handleFormSubmissionFilter() {
    this.selectedFilter = { page: this.page, perPage: this.perPage };
    this.FormSubmitted(this.selectedFilter);
}


  closeFilters(): void {
    this.activeFilter = null;
  }

  updateArchiveStatusFilter(option: FormFilterArchiveStatus): void {
      this.store.dispatch(UpdateFormFilterArchiveStatus({ option: option }));
  }

  updateDraftStatusFilter(option: FormDraftPublishedStatus): void {
    this.store.dispatch(UpdateFormFilterDraftStatus({ option: option }));
  }

  updateFormSubmissionStatus(option: string, event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectedOptionsFormSubmissionStatus = [...this.selectedOptionsFormSubmissionStatus, option];
    } else {
      this.selectedOptionsFormSubmissionStatus = this.selectedOptionsFormSubmissionStatus.filter(item => item !== option);
    }

    this.selectedFilter = {
      ...this.selectedFilter,
      status: this.selectedOptionsFormSubmissionStatus,
    };
    this.store.dispatch(UpdateFormSubmissionStatusFilter({option}));
    this.FormSubmitted(this.selectedFilter)
    
  }

  updateFormSubmissionSavedToPms(option: string, event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectedOptionsSavedToPms = [...this.selectedOptionsSavedToPms, option];
    } else {
      this.selectedOptionsSavedToPms = this.selectedOptionsSavedToPms.filter(item => item !== option);
    }

    this.selectedFilter = {
      ...this.selectedFilter,
      saveToPms: this.selectedOptionsSavedToPms,
    };
   
    this.filterLabels[FormSubmissionFilterType.SAVED_TO_PMS] = this.generateFilterLabel(
      FormSubmissionFilterType.SAVED_TO_PMS,
      this.selectedOptionsSavedToPms
    );
    this.store.dispatch(UpdateFormSubmissionSaveToPmsFilter({option: option}));
    this.FormSubmitted(this.selectedFilter)
  }

  toggleDateDropdown(): void {
    this.dateFilterOpen = !this.dateFilterOpen;
    if (this.selectedDate === this.dateOptions.find((opt) => opt.id === DateFilterShorthand.CUSTOM)) {
      this.customRangeOpen = true;
    }
  }

  closeDateFilter(target: any): void {
    if (target.className && typeof target.className.includes !== 'undefined' && (target.className.includes('p-datepicker') || target.className.includes('p-yearpicker') || target.className.includes('p-monthpicker'))) {
      // Don't close in this case - bug where it calls click outside even though we are using the datepicker inside the filter dropdown
      return;
    }

    this.dateFilterOpen = false;

    if (this.device === 'small-mobile' && this.customRangeOpen) {
      this.customRangeOpen = false;
    }
  }
  updateTakePaymentFilter(option: FormTakePayment): void {
    this.store.dispatch(UpdateFormFilterTakePayment({ option: option }));
  }

  handleClickOutside(type: FormFilterType | FormSubmissionFilterType): void {
    if (this.activeFilter === type) {
      this.activeFilter = null;
    }
  }
  dateChanged(dateOption: DateFilterOption): void {
    if (this.selectedDate === dateOption) {
      return;
    }

    this.customRangeOpen = false;
    this.selectedDate = dateOption;

    let filter = null;

    if (dateOption.id === DateFilterShorthand.CUSTOM) {
      this.customRangeOpen = true;
      filter = {
        start: new Date(this.rangeDates[0]).toISOString(),
        end: new Date(this.rangeDates[1]).toISOString()
      };
    } else {
      filter = dateOption.id;
    }

    this.dateFilter = filter;
    let date: {start: string, end: string} | null | string = this.dateFilter;
    if (this.dateFilter) {
      this.selectedFilter = {
        ...this.selectedFilter,
        date: this.dateFilter,
      };
      date = this.dateFilter
    } else {
      this.selectedFilter = {
        ...this.selectedFilter,
        date: null,
      };
      date = null;
    }
    this.updateCurrentPage.emit(1);
    this.store.dispatch(UpdateFormSubmissionDateFilter({date}))
    this.FormSubmitted(this.selectedFilter)
  }

  rangeSelected(): void {
    if (this.rangeDates[0] !== null && this.rangeDates[1] !== null) {
      const startDate = new Date(this.rangeDates[0]);
      startDate.setHours(0, 0, 0, 0); // Start of the day
  
      const endDate = new Date(this.rangeDates[1]);
      endDate.setHours(23, 59, 59, 999); // End of the day
  
      const formatToLocalISO = (date: Date) => {
        const offset = date.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
        const localDate = new Date(date.getTime() - offset); // Adjust to local time
        return localDate.toISOString().slice(0, 19); // Format without `Z`
      };
  
      this.dateFilter = {
        start: formatToLocalISO(startDate),
        end: formatToLocalISO(endDate),
      };
    }
  
    this.selectedFilter = {
      ...this.selectedFilter,
      date: this.dateFilter,
    };
    this.updateCurrentPage.emit(1);
    const date = this.dateFilter;
    this.store.dispatch(UpdateFormSubmissionDateFilter({date}));
    this.FormSubmitted(this.selectedFilter);
  }

}
  


