import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { AuthService } from "./auth/auth.service";
import { getUser } from "./auth/state/selectors";
import { WebsocketService } from "./conversation/websocket.service";
import { Notification } from "./models/Notification";
import { User } from "./models/User";
import { NotificationDto } from "./notifications/dto/notification.dto";
import { NotificationService } from "./notifications/notification.service";
import { getVisibleNotifications } from "./notifications/state/selectors";
import { Practice } from "./models/Practice";
import {
  getAvailablePractices,
  getCurrentPractice,
} from "./practices/state/selectors";
import { EnvironmentService } from "./services/environment.service";
import { AppState } from "./state/reducers";
import {
  getHealth,
  getWebsocketHealth,
  isConversationPreviewEnabled,
} from "./state/selectors";
import {
  DoHealthCheck,
  SetOneSignalUserId,
  GetCurrencies,
  GetTags,
  SetConversationPreviewOpen,
  SetPreviewConversationId,
  ToggleConversationPreviewEnabled,
} from "./state/actions";
import { SocketService } from "./services/socket.service";
import { NavigationEnd, Router } from "@angular/router";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { Title } from "@angular/platform-browser";
import { AppService } from "./app.service";
import { SetPmsHealthy, UpdatePracticeFormSubmissionCount, UpdatePracticeName, UpdatePracticeTheme } from "./practices/state/actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "messaging-front-end";
  alive = true;
  timeout?: any;
  healthCheckTimeout?: any;
  notifications$?: Observable<Notification[]>;
  practices$?: Observable<Practice[] | null>;
  user$?: Observable<User | null>;
  user: User | null = null;
  healthy$?: Observable<boolean>;
  websocketHealthy$?: Observable<boolean>;
  previewEnabled: boolean = false;
  socketConnected = false;
  practice?: Practice;

  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private environmentService: EnvironmentService,
    private websocketService: WebsocketService,
    private notificationService: NotificationService,
    private socketService: SocketService,
    private router: Router,
    private gaService: GoogleAnalyticsService,
    private titleService: Title,
    private appService: AppService,
  ) {
    this.doHealthCheck();
    this.subscribeToHealthy();
    this.subscribeToCurrentPractice();
    this.subscribeToWebsocketHealthy();
    this.subscribeToUser();
    this.subscribeToPractices();
    this.initialiseOneSignal();

    this.websocketService.onConnect$.subscribe((connected) => {
      this.socketConnected = true;
      this.joinSocket();
    });

    this.websocketService.getPmsStatusUpdates().subscribe((pmsHealthy) => {
      this.store.dispatch(
        SetPmsHealthy({
          pmsHealthy: pmsHealthy,
        }),
      );
    });

    this.websocketService.getUpdatedPractice().subscribe((practice) => {
      this.store.dispatch(UpdatePracticeName({ id: practice.id, newName: practice.name }));
      this.store.dispatch(UpdatePracticeTheme({ id: practice.id, newTheme: practice.theme }));
      
    });

    this.websocketService.getPracticeCounters().subscribe((data) => {
      this.store.dispatch(UpdatePracticeFormSubmissionCount({ id: data.practiceId, formSubmissionCount: data.formSubmissionCount }));
    });
    
  }

  ngOnInit(): void {
    this.authService.recoverSession();

    this.setDocHeight();

    this.setUpNotificationWebsocketConnections();
    this.subscribeToNotifications();

    this.store
      .pipe(select(isConversationPreviewEnabled))
      .pipe(takeWhile(() => this.alive))
      .subscribe((enabled) => (this.previewEnabled = enabled));

    const previewEnabled = window.localStorage.getItem("preview-pane");

    if (previewEnabled == "1") {
      this.store.dispatch(ToggleConversationPreviewEnabled());
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          this.previewEnabled &&
          (event.url.includes("/day-list") ||
            event.url.includes("/conversations?"))
        ) {
          this.store.dispatch(SetConversationPreviewOpen({ open: true }));
        } else {
          this.store.dispatch(SetConversationPreviewOpen({ open: false }));
          this.store.dispatch(SetPreviewConversationId({ id: null }));
        }

        if (event.id > 1) {
          this.gaService.pageView(
            event.urlAfterRedirects,
            this.titleService.getTitle(),
          );
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
    clearInterval(this.healthCheckTimeout);
  }

  private initialiseOneSignal(): void {
    this.appService.initialiseOneSignal();
  }

  @HostListener("document:visibilitychange", ["$event"])
  visibilityChange(): void {
    if (
      document.visibilityState !== "hidden" &&
      !this.socketService.ioSocket.connected
    ) {
      window.location.href = window.location.href;
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.handleResize();
  }

  @HostListener("window:orientationchange", ["$event"])
  onOrientationChange(): void {
    this.setDocHeight();
  }

  handleResize(): void {
    const el = document.activeElement;
    if (
      !el ||
      (el.tagName.toLowerCase() !== "input" &&
        el.tagName.toLowerCase() !== "textarea")
    ) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.setDocHeight(), 100);
    }
  }

  setDocHeight(): void {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`,
    );
  }

  setUpNotificationWebsocketConnections(): void {
    this.store.pipe(select(getUser)).subscribe((user) => {
      if (user) {
        this.user = user;
        this.websocketService.joinNotificationsChannel(Number(user.id));
      }
    });

    this.websocketService.onConnect$.subscribe((connected) => {
      if (connected && this.user) {
        this.websocketService.joinNotificationsChannel(Number(this.user.id));
      }
    });

    this.websocketService
      .getNotifications()
      .subscribe(
        (payload: { notification: NotificationDto; showToast: boolean }) => {
          this.notificationService.handleNewNotification(
            payload.notification,
            payload.showToast,
          );
        },
      );
  }

  subscribeToNotifications(): void {
    this.notifications$ = this.store.pipe(select(getVisibleNotifications));
  }

  subscribeToUser(): void {
    this.user$ = this.store.pipe(select(getUser));
    this.user$.subscribe((user) => {
      if (user) {
        this.doGetCurrencies();
        this.doGetTags();
      }
    });
  }

  subscribeToPractices(): void {
    this.practices$ = this.store.pipe(select(getAvailablePractices));
  }

  subscribeToCurrentPractice(): void {
    this.store.pipe(select(getCurrentPractice)).subscribe((practice) => {
      if (
        this.practice &&
        practice?.id != this.practice.id &&
        this.socketConnected
      ) {
        this.websocketService.leavePractice(this.practice.id);
      }

      if (practice && this.practice?.id != practice.id) {
        this.practice = practice;
        this.joinSocket();
      }
    });
  }

  joinSocket(): void {
    if (this.practice && this.socketConnected) {
      this.websocketService.joinPractice(this.practice.id);
    }
  }

  subscribeToWebsocketHealthy(): void {
    this.websocketHealthy$ = this.store.pipe(select(getWebsocketHealth));
  }

  subscribeToHealthy(): void {
    this.healthy$ = this.store.pipe(select(getHealth));
  }

  doHealthCheck(): void {
    this.store.dispatch(DoHealthCheck());

    this.healthCheckTimeout = setInterval(() => {
      this.store.dispatch(DoHealthCheck());
    }, 30000);
  }

  doGetCurrencies(): void {
    this.store.dispatch(GetCurrencies());
  }

  doGetTags(): void {
    this.store.dispatch(GetTags());
  }
}
