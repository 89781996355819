<div class="form-filters">
    <div class="top">
        <div class="left">
            <div class="filter-item filter-toggle" [class.active]="mobileFiltersOpen" (click)="toggleMobileFilters()">
                <div class="toggle">
                    <ng-container *ngIf="!mobileFiltersOpen">
                        <i class="pi pi-filter"></i>
                    </ng-container>
                    <ng-container *ngIf="mobileFiltersOpen">
                        <i class="pi pi-times"></i>
                    </ng-container>
                    <div>Filters</div>
                </div>
            </div>
            <div class="pipe"></div>
            <div class="filter-item clear-all" (click)="clearFilters()">
                Reset
            </div>
        </div>
        <div class="right" [class.active]="mobileFiltersOpen">
            <div class="filters-group">
                <ng-container *ngTemplateOutlet="filters"></ng-container>
            </div>
            <div class="pipe"></div>
            <div class="filter-item clear-all" (click)="clearFilters()">
                Reset
            </div>
        </div>
    </div>
</div>

<ng-template #filters>
    <ng-container *ngTemplateOutlet="searchFilter"></ng-container>
    <ng-container *ngTemplateOutlet="draftStatusFilter"></ng-container>
    <ng-container *ngTemplateOutlet="paymentStatusFilter"></ng-container>
    <ng-container *ngTemplateOutlet="SavedToPmsFilter"></ng-container>
    <ng-container *ngTemplateOutlet="archiveFormSubmissionStatusFilter"></ng-container>
    <ng-container *ngTemplateOutlet="dateFilterSubmitted"></ng-container>
    <ng-container *ngTemplateOutlet="archiveStatusFilter"></ng-container>
</ng-template>

<ng-template #searchFilter>
    <div class="search-input filter-item filter">
        <img [src]="'searchIcon' | themeIconUrl: theme" class="search-icon">
        <input pInputText [(ngModel)]="searchTerm" (keyup)="setFilteredTemplates()" placeholder="Search..."
            class="p-inputtext-search">
    </div>
</ng-template>

<ng-template #paymentStatusFilter>
    <ng-container *ngIf='source !="form_submission"'>
        <div class="filter-item with-dropdown filter" (clickOutside)="handleClickOutside(FormFilterType.TAKEPAYMENT)"
        [class.active]="activeFilter === FormFilterType.TAKEPAYMENT"
        [class.highlight]="((selectedTakePayment$ | ngrxPush) || []).length > 0">
        <div class="toggle" (click)="toggleActiveFilter(FormFilterType.TAKEPAYMENT)">
            <ng-container
                *ngIf="((selectedTakePayment$ | ngrxPush) || []).length == 0 || !this.filterLabels[FormFilterType.TAKEPAYMENT]">
                <i class="pi pi-wallet"></i>
                Payment
            </ng-container>
            <ng-container
                *ngIf="((selectedTakePayment$ | ngrxPush) || []).length > 0 && this.filterLabels[FormFilterType.TAKEPAYMENT]">
                <i class="pi pi-check"></i>
                {{ this.filterLabels[FormFilterType.TAKEPAYMENT] }}
            </ng-container>
            <dropdown-icon></dropdown-icon>
        </div>
        <div class="dropdown mobile-modal low-padding">
            <ng-container *ngFor="let option of takePaymentOptions">
                <div class="dropdown-item">
                    <label [for]="option" class="label">{{ option }}</label>
                    <input type="checkbox" (change)="updateTakePaymentFilter(option)"
                        [checked]="((selectedTakePayment$ | ngrxPush) || []).includes(option)" />
                </div>
            </ng-container>
        </div>
    </div>
    </ng-container>
  
</ng-template>
<ng-template #SavedToPmsFilter>
    <ng-container *ngIf="source === 'form_submission'">
        <div class="filter-item with-dropdown filter" (clickOutside)="handleClickOutside(FormSubmissionFilterType.SAVED_TO_PMS)"
        [class.active]="activeFilter === FormSubmissionFilterType.SAVED_TO_PMS"
        [class.highlight]="((selectedSaveToPms$ | ngrxPush) || []).length > 0">
        <div class="toggle" (click)="toggleActiveFilter(FormSubmissionFilterType.SAVED_TO_PMS)">
            <ng-container
                *ngIf="((selectedSaveToPms$ | ngrxPush) || []).length == 0 || !this.filterLabels[FormSubmissionFilterType.SAVED_TO_PMS]">
                Saved To PMS
            </ng-container>
            <ng-container
                *ngIf="((selectedSaveToPms$ | ngrxPush) || []).length > 0 && this.filterLabels[FormSubmissionFilterType.SAVED_TO_PMS]">
                <i class="pi pi-check"></i>
                {{ this.filterLabels[FormSubmissionFilterType.SAVED_TO_PMS] }}
            </ng-container>
            <dropdown-icon></dropdown-icon>
        </div>
        <div class="dropdown mobile-modal low-padding">
            <ng-container *ngFor="let option of takeSavedToPmsOptions">
                <div class="dropdown-item">
                    <label [for]="option" class="label">{{ option }}</label>
                    <input type="checkbox" (change)="updateFormSubmissionSavedToPms(option, $event)"
                    [checked]="((selectedOptionsSavedToPms | ngrxPush) || []).includes(option)"/>
                </div>
            </ng-container>
        </div>
    </div>
    </ng-container>
 
</ng-template>

<ng-template #archiveFormSubmissionStatusFilter>
    <ng-container *ngIf="source === 'form_submission'" >
        <div class="filter-item with-dropdown filter"
        (clickOutside)="handleClickOutside(FormSubmissionFilterType.STATUS)"
        [class.active]="activeFilter === FormSubmissionFilterType.STATUS"
        [class.highlight]="((selectedStatuses$ | ngrxPush) || []).length > 0">
        <div class="toggle" (click)="toggleActiveFilter(FormSubmissionFilterType.STATUS)">
            <ng-container
                *ngIf="((selectedStatuses$ | ngrxPush) || []).length == 0 || !this.filterLabels[FormSubmissionFilterType.STATUS]">
                <i class="pi pi-trash"></i>
                Status
            </ng-container>
            <ng-container
                *ngIf="((selectedStatuses$ | ngrxPush) || []).length > 0 && this.filterLabels[FormSubmissionFilterType.STATUS]">
                <i class="pi pi-check"></i>
                {{ this.filterLabels[FormSubmissionFilterType.STATUS] }}
            </ng-container>
            <dropdown-icon></dropdown-icon>
        </div>
        <div class="dropdown mobile-modal low-padding">
            <ng-container *ngFor="let option of formSubmissionStatus">
              <div class="dropdown-item">
                <label [for]="option" class="label">{{ option }}</label>
                <input type="checkbox" (change)="updateFormSubmissionStatus(option, $event)"
                [checked]="((selectedOptionsFormSubmissionStatus | ngrxPush) || []).includes(option)" />
                
              </div>
            </ng-container>
        </div>
    </div>
    </ng-container>

</ng-template>

<ng-template #draftStatusFilter>
    <ng-container *ngIf="source != 'form_submission'">
        <div class="filter-item with-dropdown filter"
        (clickOutside)="handleClickOutside(FormFilterType.DRAFT_PUBLISHED_STATUS)"
        [class.active]="activeFilter === FormFilterType.DRAFT_PUBLISHED_STATUS"
        [class.highlight]="((selectedDraftStatus$ | ngrxPush) || []).length > 0">
        <div class="toggle" (click)="toggleActiveFilter(FormFilterType.DRAFT_PUBLISHED_STATUS)">
            <ng-container
                *ngIf="((selectedDraftStatus$ | ngrxPush) || []).length == 0 || !this.filterLabels[FormFilterType.DRAFT_PUBLISHED_STATUS]">
                <i class="pi pi-flag"></i>
                Status
            </ng-container>
            <ng-container
                *ngIf="((selectedDraftStatus$ | ngrxPush) || []).length > 0 && this.filterLabels[FormFilterType.DRAFT_PUBLISHED_STATUS]">
                <i class="pi pi-check"></i>
                {{ this.filterLabels[FormFilterType.DRAFT_PUBLISHED_STATUS] }}
            </ng-container>
            <dropdown-icon></dropdown-icon>
        </div>
        <div class="dropdown mobile-modal low-padding">
                 <ng-container *ngFor="let option of draftStatusOptions">
                    <div class="dropdown-item">
                        <label [for]="option" class="label">{{ option }}</label>
                        <input type="checkbox" (change)="updateDraftStatusFilter(option)"
                            [checked]="((selectedDraftStatus$ | ngrxPush) || []).includes(option)" />
                    </div>
                </ng-container>
        </div>
    </div>
    </ng-container>

</ng-template>
<ng-template #dateFilterSubmitted>
    <ng-container *ngIf="source === 'form_submission'">
        <div class="filter-item with-dropdown filter"  [class.active]="activeFilter === FormSubmissionFilterType.DATE"[class.highlight]="selectedDate !== defaultSelectedDate" (clickOutside)="handleClickOutside(FormSubmissionFilterType.DATE)">
            <div class="toggle" (click)="toggleActiveFilter(FormSubmissionFilterType.DATE)">
              <ng-container *ngIf="!selectedDate">
                <i class="pi pi-calendar"></i>
              </ng-container>
              <ng-container *ngIf="selectedDate">
                <i class="pi pi-check"></i>
              </ng-container>
              {{ selectedDate ? selectedDate.label : 'Sent' }} <dropdown-icon></dropdown-icon>
            </div>
            <div class="dropdown mobile-modal no-checkbox">
              <ng-container *ngFor="let dateOption of dateOptions">
                <div class="dropdown-item" (click)="dateChanged(dateOption)">
                  <div class="label" [class.active]="selectedDate?.id === dateOption.id">{{ dateOption.label }}</div>
                </div>
              </ng-container>
              <ng-container *ngIf="customRangeOpen">
                <div class="dropdown-calendar">
                  <p-calendar (onSelect)="rangeSelected()" [(ngModel)]="rangeDates" [inline]="true" [firstDayOfWeek]="1" selectionMode="range" inputId="range"></p-calendar>
                </div>
              </ng-container>
            </div>
          </div>
    </ng-container>
</ng-template>

<ng-template #archiveStatusFilter>
    <ng-container *ngIf="source != 'form_submission'">
    <div
        class="filter-item with-dropdown filter"
        (clickOutside)="handleClickOutside(FormFilterType.ARCHIVE_STATUS)"
        [class.active]="activeFilter === FormFilterType.ARCHIVE_STATUS"
        [class.highlight]="((selectedArchiveStatus$ | ngrxPush) || []).length > 0"
    >
        <div
            class="toggle"
            (click)="toggleActiveFilter(FormFilterType.ARCHIVE_STATUS)"
        >
            <ng-container
                *ngIf="((selectedArchiveStatus$ | ngrxPush) || []).length == 0 || !this.filterLabels[FormFilterType.ARCHIVE_STATUS]"
            >
                <i class="pi pi-trash"></i>
                Archived Status
            </ng-container>
            <ng-container
                *ngIf="((selectedArchiveStatus$ | ngrxPush) || []).length > 0 && this.filterLabels[FormFilterType.ARCHIVE_STATUS]"
            >
                <i class="pi pi-check"></i>
                {{ this.filterLabels[FormFilterType.ARCHIVE_STATUS] }}
            </ng-container>
            <dropdown-icon></dropdown-icon>
        </div>
        <div class="dropdown mobile-modal low-padding">
            <ng-container *ngFor="let option of archiveStatusOptions">
                <div class="dropdown-item">
                    <label [for]="option" class="label">{{ option }}</label>
                    <input
                        type="checkbox"
                        (change)="updateArchiveStatusFilter(option)"
                        [checked]="((selectedArchiveStatus$ | ngrxPush) || []).includes(option)"
                    />
                </div>
            </ng-container>
        </div>
    </div>
    </ng-container>
</ng-template>