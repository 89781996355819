import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormGroup
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { filter, take, takeWhile } from 'rxjs/operators';
import { AppState } from '../../../state/reducers';
import { Practice } from '../../../models/Practice';
import { getCurrencies } from '../../../state/selectors';
import { Currency } from '../../../models/Currency';
import { FormFieldPrefillOption } from '../../../enums/form-field-prefill-option.enum';
import {
  ClearCurrentForm,
  ClearFormPreviewResult,
  CreateForm,
  GetForm,
  OpenFormPreview,
  PublishForm,
  UnpublishForm,
  UpdateForm,
} from '../../state/actions';
import { fieldTypeHasOptions } from '../../../helpers/field-type-has-options';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Form } from '../../interfaces/form';
import { getCurrentForm, getFormPreviewResult } from '../../state/selectors';
import { PageUpdateDto } from '../../interfaces/dto/page-update.dto';
import { FieldUpdateDto } from '../../interfaces/dto/field-update.dto';
import { OptionUpdateDto } from '../../interfaces/dto/option-update.dto';
import { CurrencyCode } from '../../../enums/currency-code';
import { MessageService } from 'primeng/api';
import { fieldTypeHasDefaultOption } from '../../../helpers/field-type-has-default-option';
import { fieldTypeHasDefault } from '../../../helpers/field-type-has-default';
import { FormFieldType } from '../../../enums/form-field-type.enum';
import { ContentUpdateDto } from '../../interfaces/dto/content-update.dto';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import 'vhd-quill-mention-2';
import 'vhd-quill-emoji/dist/quill-emoji.js';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { EnvironmentService } from '../../../services/environment.service';
import { PMS } from '../../../enums/pms';
import { practiceHasFeature } from '../../../helpers/practice-has-feature';
import { PracticeFeature } from '../../../enums/practice-feature';
import { Subscription } from "rxjs";
import { practiceSupportsFormSync } from "../../../helpers/practice-support-form-sync";

@Component({
  selector: "app-form-builder",
  templateUrl: "./form-builder.component.html",
  styleUrls: ["./form-builder.component.scss"],
})
export class FormBuilderPage implements OnInit, OnDestroy {
  alive = true;
  practice: Practice | null = null;
  currencies: Currency[] = [];
  practiceCurrency?: Currency;
  loading = false;
  isCurrentFormLoaded = false;
  formId: number | null = null;
  currentForm: Form | null = null;
  baseForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    intro: new UntypedFormControl('', [Validators.required]),
    estimatedCompletionDuration: new UntypedFormControl('', [
      Validators.required,
    ]),
    pages: this.formBuilder.array([]),
    takePayment: new UntypedFormControl(false),
    paymentAmount: new UntypedFormControl(""),
    paymentCurrency: new UntypedFormControl(""),
    paymentDescription: new UntypedFormControl(""),
    paymentExpiresAfter: new UntypedFormControl(3),
    paymentPreAuth: new UntypedFormControl(false),
    saveToPms: new UntypedFormControl(false),
    saveAgainst: new UntypedFormControl(''),
  });
  form: UntypedFormGroup;
  formFieldType = FormFieldType;
  inputTypes = Object.values(FormFieldType);
  expiryOptions = [
    { amount: 1, label: "1 Day" },
    { amount: 3, label: "3 Days" },
    { amount: 7, label: "7 Days" },
    { amount: 14, label: "14 Days" },
    { amount: 30, label: "30 Days" },
  ];

  saveAgainstOptions = [
    {value: 'client', label: 'Client'},
  ];
  prefillOptions = Object.values(FormFieldPrefillOption);
  valueVisibility: boolean[][] = [[false]];
  fieldsPerPage: number[] = [1];
  @ViewChild(QuillEditorComponent, { static: true })
  editor?: QuillEditorComponent;
  quillModules: QuillModules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ script: "super" }, { script: "sub" }],
      ["clean"],
    ],
  };
  previewOpen: boolean = false;
  iframeUrl: SafeResourceUrl = '';
  previewForm: any = null;
  isDraft: boolean = false;
  isAutomaticFormCompletionEnabled: boolean = false;
  isPreviewing: boolean = false;
  private labelSubscriptions = new Map<FormGroup, Subscription>();    

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private cookieService: CookieService,
    private environmentService: EnvironmentService,
  ) {
    const title = "Digital Practice | Form Builder";
    this.titleService.setTitle(title);
    this.form = this.baseForm;
    this.addPage();
   
    this.formId = this.route.snapshot.paramMap.get("id")
      ? Number(this.route.snapshot.paramMap.get("id"))
      : null;
    this.formId = this.route.snapshot.paramMap.get('id') ? Number(this.route.snapshot.paramMap.get('id')) : null;
    if (!this.formId) {
      this.saveToPmsToggled();
    }
  }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToCurrencies();
    this.subscribeToOptionsValueChanges();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.store.dispatch(ClearCurrentForm());
    this.labelSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  subscribeToOptionsValueChanges(): void {
    const pages = this.pages();
    Object.keys(pages.controls).map((pagekey, pageIndex) => {
      const fields = this.getItems(pageIndex);
      Object.keys(fields.controls).forEach((fieldString, fieldIndex) => {
        const fieldObject = fields.get(fieldString);
        if (fieldObject && fieldTypeHasOptions(fieldObject.get('type')?.value)) {
          this.copyLabelToValueOnChange(pageIndex, fieldIndex);
        }
      });
    });
  }


  subscribeToCurrentPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        this.practice = practice;
        if (!this.isCurrentFormLoaded) {
          this.getCurrentForm();
        }
        this.form.controls.paymentExpiresAfter.setValue(
          practice ? practice.paymentLinkExpiry : 3,
        ),
          this.setPracticeCurrency();
      });
    this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(practice => {
      this.practice = practice;
      this.isAutomaticFormCompletionEnabled = practiceSupportsFormSync(practice);;
      if (this.practice?.pms === PMS.EZYVET || this.practice?.pms === PMS.PROVET) {
        this.saveAgainstOptions = [...this.saveAgainstOptions, {value: 'patient', label: 'Patient'}];
      }else if (this.practice?.pms === PMS.TELEOS) {
        this.saveAgainstOptions = [{value: 'patient', label: 'Patient'}];
      }

      if (!this.isCurrentFormLoaded) {
        this.getCurrentForm();
      }
      this.form.controls.paymentExpiresAfter.setValue(practice ? practice.paymentLinkExpiry : 3),
      this.setPracticeCurrency();
    });
  }

  getCurrentForm(): void {
    if (this.formId && this.practice) {
      this.loading = true;
      this.store.dispatch(GetForm({ formId: this.formId }));
      this.subscribeToCurrentForm();
    }
  }

  subscribeToCurrentForm(): void {
    this.store.pipe(select(getCurrentForm)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(form => {
      if (form) {
        this.currentForm = form;
        this.prefillForm();
        this.loading = false;
        this.isCurrentFormLoaded = true;
        this.isDraft = form.isDraft ? form.isDraft : false;
        this.takePaymentToggled();
      }
    });
  }

  subscribeToCurrencies(): void {
    this.store
      .pipe(select(getCurrencies))
      .pipe(takeWhile(() => this.alive))
      .subscribe((currencies) => {
        this.currencies = currencies;
        this.setPracticeCurrency();
      });
  }
  subscribeToFormPreview(): void {
    this.store.select(getFormPreviewResult).pipe(
      filter(result => !!result), 
      takeWhile(() => this.alive)
    ).subscribe((form) => {
      this.loading = false;
      if (form && form.id) {
        this.previewForm = form;
        this.formId = form.id;
        this.previewOpen = true;
        let previewUrl = this.generatePreviewUrl(this.previewForm.id);
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(previewUrl);
      }
    });
  }

  setPracticeCurrency(): void {
    if (this.currencies.length && this.practice) {
      this.practiceCurrency = this.currencies.find(
        (currency) => currency.currencyCode === this.practice?.currency,
      );
      this.form.controls.paymentCurrency.setValue(
        this.practiceCurrency?.currencyCode,
      );
    }
  }

  pages(): UntypedFormArray {
    return this.form.get("pages") as UntypedFormArray;
  }

  addPage(manual = false): void {
    const pages = this.pages();
    if (pages) {
      pages.push(this.newPage());
      this.valueVisibility.push([false]);
      this.fieldsPerPage.push(1);
      this.addField(pages.length - 1, manual);
     // this.addContent(pages.length - 1, manual);
    }
  }

  newPage(): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(null),
      heading: new UntypedFormControl("", Validators.required),
      intro: new UntypedFormControl("", Validators.required),
      fields: this.formBuilder.array([]),
      contents: this.formBuilder.array([]),
      items: this.formBuilder.array([]),
    });
  }

 removePage(index: number): void {
  const pages = this.pages();
  if (pages) {
    pages.removeAt(index); 
    this.valueVisibility.splice(index, 1);
    this.fieldsPerPage.splice(index, 1);
  }
}

  fields(pageIndex: number): UntypedFormArray {
    return this.pages().at(pageIndex).get("fields") as UntypedFormArray;
  }

  contents(pageIndex: number): UntypedFormArray {
    return this.pages().at(pageIndex).get("contents") as UntypedFormArray;
  }

  addField(pageIndex: number, manual = false): void {
    const fields = this.getItems(pageIndex);
    if (fields) {
      fields.push(this.newField());
      this.addOption(pageIndex, fields.length - 1);
      this.valueVisibility[pageIndex].push(false);
    }

    if (manual) {
      this.touchAllFields();
    }
  }

  addContent(pageIndex: number, manual = false): void {
    const contents = this.getItems(pageIndex);
    if (contents) {
      contents.push(this.newContent());
    }

    if (manual) {
      this.touchAllFields();
    }
  }

  newField(): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(null),
      type: new UntypedFormControl(FormFieldType.TEXT, Validators.required),
      label: new UntypedFormControl("", Validators.required),
      url: new UntypedFormControl(""),
      description: new UntypedFormControl(""),
      prefill: new UntypedFormControl(false),
      customizeContent: new UntypedFormControl(false),
      prefillWith: new UntypedFormControl(FormFieldPrefillOption.CLIENT_NAME),
      readonly: new UntypedFormControl(false),
      sensitive: new UntypedFormControl(false),
      placeholder: new UntypedFormControl(""),
      default: new UntypedFormControl(""),
      required: new UntypedFormControl(true),
      options: this.formBuilder.array([]),
    });
  }

  newContent(): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(null),
      content: new UntypedFormControl(""),
    });
  }

  removeField(pageIndex: number, index: number): void {
    const fields = this.getItems(pageIndex);
    if (fields) {
      fields.removeAt(index);
      if (this.valueVisibility[pageIndex]) {
        this.valueVisibility[pageIndex].splice(index, 1);
      }
      this.fieldsPerPage[pageIndex]--;
    }
  }

  removeContent(pageIndex: number, index: number): void {
    const contents = this.getItems(pageIndex);
    if (contents) {
      contents.removeAt(index);
    }
  }

  options(pageIndex: number, fieldIndex: number): UntypedFormArray {
    return this.getItems(pageIndex)
      .at(fieldIndex)
      .get("options") as UntypedFormArray;
  }

  addOption(pageIndex: number, fieldIndex: number): void {
    const options = this.options(pageIndex, fieldIndex);
    if (options) {
      options.push(this.newOption(pageIndex, fieldIndex, options.length - 1));

      setTimeout(() => {
        this.updateFieldOptionValidators(pageIndex, fieldIndex);
      }, 0);
    }
  }


  movePageUp(pageIndex: number): void {
    if (pageIndex > 0) {
      const pagesArray = this.pages();
      const page = pagesArray.at(pageIndex);
      pagesArray.removeAt(pageIndex);
      pagesArray.insert(pageIndex - 1, page);
    }
  }
  
  movePageDown(pageIndex: number): void {
    const pagesArray = this.pages();
    if (pageIndex < pagesArray.length - 1) {
      const page = pagesArray.at(pageIndex);
      pagesArray.removeAt(pageIndex);
      pagesArray.insert(pageIndex + 1, page);
    }
  }


  newOption(
    pageIndex: number,
    fieldIndex: number,
    optionIndex: number,
  ): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(null),
      label: new UntypedFormControl(""),
      value: new UntypedFormControl(""),
    });
  }

  removeOption(pageIndex: number, fieldIndex: number, index: number): void {
    const options = this.options(pageIndex, fieldIndex);
    if (options) {
      options.removeAt(index);
    }
  }

  submit(isDraft = false): void {
    this.touchAllFields();

    if (!this.form.valid) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail:
          'Some fields are invalid, please ensure you have set up this form correctly.',
        life: 5000,
      });
    }

    if (this.currentForm && this.currentForm.id) {
      isDraft = this.isDraft;
    }
    
    if (this.form.valid && this.practice) {
      const formPages = this.generateFormPages();
      this.loading = true;

      if (this.formId) {
        this.store.dispatch(UpdateForm({
          dto: {
            id: Number(this.formId),
            name: this.form.controls.name?.value || '',
            intro: this.form.controls.intro?.value || '',
            estimatedCompletionDuration: this.form.controls.estimatedCompletionDuration?.value || '',
            practiceId: Number(this.practice.coreId),
            takePayment: this.form.controls.takePayment?.value || false,
            paymentAmount: this.form.controls.paymentAmount?.value || 0,
            paymentCurrency: this.form.controls.paymentCurrency?.value as CurrencyCode,
            paymentDescription: this.form.controls.paymentDescription?.value || '',
            paymentExpiresAfter: this.form.controls.paymentExpiresAfter?.value || '',
            paymentPreAuth: this.form.controls.paymentPreAuth?.value || false,
            pages: formPages,
            isDraft: isDraft,
            saveToPms: this.form.controls.saveToPms?.value || false,
            saveAgainst: this.form.controls.saveAgainst?.value || '',
          }
        }));
      } else {
        this.store.dispatch(CreateForm({
          dto: {
            name: this.form.controls.name?.value || '',
            intro: this.form.controls.intro?.value || '',
            estimatedCompletionDuration: this.form.controls.estimatedCompletionDuration?.value || '',
            practiceId: Number(this.practice.coreId),
            takePayment: this.form.controls.takePayment?.value || false,
            paymentAmount: this.form.controls.paymentAmount?.value || 0,
            paymentCurrency: this.form.controls.paymentCurrency?.value as CurrencyCode,
            paymentDescription: this.form.controls.paymentDescription?.value || '',
            paymentExpiresAfter: this.form.controls.paymentExpiresAfter?.value || '',
            paymentPreAuth: this.form.controls.paymentPreAuth?.value || false,
            pages: formPages,
            isDraft: isDraft,
            saveToPms: this.form.controls.saveToPms?.value || false,
            saveAgainst: this.form.controls.saveAgainst?.value || '',
          }
        }));
      }
    }
  }

  publish(): void {
    this.touchAllFields();
    let isDraft = false;
    if (!this.form.valid) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Some fields are invalid, please ensure you have set up this form correctly.',
        life: 5000
      });
    }

    const pages = this.pages();
    if (this.form.valid && this.practice) {
      const formPages = this.generateFormPages();
      this.loading = true;
      let publishFormDto: any = {
        name: this.form.controls.name?.value || '',
        intro: this.form.controls.intro?.value || '',
        estimatedCompletionDuration: this.form.controls.estimatedCompletionDuration?.value || '',
        practiceId: Number(this.practice.coreId),
        takePayment: this.form.controls.takePayment?.value || false,
        paymentAmount: this.form.controls.paymentAmount?.value || 0,
        paymentCurrency: this.form.controls.paymentCurrency?.value as CurrencyCode,
        paymentDescription: this.form.controls.paymentDescription?.value || '',
        paymentExpiresAfter: this.form.controls.paymentExpiresAfter?.value || '',
        paymentPreAuth: this.form.controls.paymentPreAuth?.value || false,
        pages: formPages,
        isDraft: isDraft,
        saveToPms: this.form.controls.saveToPms?.value || false,
        saveAgainst: this.form.controls.saveAgainst?.value || '',
      };

      if (this.formId) {
        publishFormDto = { ...publishFormDto, id: Number(this.formId) };
      }
    
      this.store.dispatch(PublishForm({
        dto: publishFormDto
      }));
    } 
  }

  unpublish(): void {
    let isDraft = true;
    this.touchAllFields();

    if (!this.form.valid) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Some fields are invalid, please ensure you have set up this form correctly.',
        life: 5000
      });
    }

    if (this.form.valid && this.practice) {
      const formPages = this.generateFormPages();
      this.loading = true;
      let unpublishFormDto: any = {
        name: this.form.controls.name?.value || '',
        intro: this.form.controls.intro?.value || '',
        estimatedCompletionDuration: this.form.controls.estimatedCompletionDuration?.value || '',
        practiceId: Number(this.practice.coreId),
        takePayment: this.form.controls.takePayment?.value || false,
        paymentAmount: this.form.controls.paymentAmount?.value || 0,
        paymentCurrency: this.form.controls.paymentCurrency?.value as CurrencyCode,
        paymentDescription: this.form.controls.paymentDescription?.value || '',
        paymentExpiresAfter: this.form.controls.paymentExpiresAfter?.value || '',
        paymentPreAuth: this.form.controls.paymentPreAuth?.value || false,
        pages: formPages,
        isDraft: isDraft,
        saveToPms: this.form.controls.saveToPms?.value || false,
        saveAgainst: this.form.controls.saveAgainst?.value || '',
      };

      if (this.formId) {
        unpublishFormDto = { ...unpublishFormDto, id: Number(this.formId) };
      }
    
      this.store.dispatch(UnpublishForm({
        dto: unpublishFormDto
      }));
    } 
  }

  private generateFormPages() : PageUpdateDto[]{
    const pages = this.pages();

    const formPages: PageUpdateDto[] = Object.keys(pages.controls).map((pagekey, pageIndex) => {
      const page = pages.get(pagekey) as UntypedFormArray;

      const pageFields: FieldUpdateDto[] = [];
      const fields = this.getItems(pageIndex);
      Object.keys(fields.controls).forEach((fieldString, fieldIndex) => {
        const fieldObject = fields.get(fieldString);
        if (fieldObject && this.isField(fieldObject)) {
          const fieldOptions: OptionUpdateDto[] = [];

          if (fieldTypeHasOptions(fieldObject.get('type')?.value)) {
            const options = this.options(pageIndex, fieldIndex);
            Object.keys(options.controls).forEach((option, optionIndex) => {
              const optionObject = options.get(option);
              if (optionObject) {
                fieldOptions.push({
                  id: optionObject.get('id')?.value || undefined,
                  order: optionIndex,
                  label: optionObject.get('label')?.value,
                  value: optionObject.get('value')?.value,
                  default:
                    fieldTypeHasDefaultOption(fieldObject.get('type')?.value) &&
                      fieldObject.get('default')?.value != null && 
                      fieldObject.get('default')?.value !== '' ?
                      optionIndex === Number(fieldObject.get('default')?.value) :
                      false,

                });
              }
            });
          }
          const customizeContent = fieldObject.get('customizeContent')?.value;
          const prefill = customizeContent 
              ? false 
              : fieldObject.get('type')?.value === FormFieldType.HIDDEN 
                  ? true 
                  : fieldObject.get('prefill')?.value;
          const readonly = (customizeContent || prefill)
                ? fieldObject.get("readonly")?.value
                : false;
          pageFields.push({
            id: fieldObject.get('id')?.value || undefined,
            default:
              fieldTypeHasDefault(fieldObject.get('type')?.value) ?
                fieldObject.get('default')?.value :
                undefined,
            label: fieldObject.get('label')?.value || '',
            url: fieldObject.get('url')?.value || '',
            description: fieldObject.get('description')?.value || '',
            order: fieldIndex,
            prefill: prefill,
            prefillWith: fieldObject.get('prefillWith')?.value,
            placeholder: fieldObject.get('placeholder')?.value || '',
            readonly: readonly,
            customizeContent: customizeContent,
            required: fieldObject.get('required')?.value,
            sensitive: fieldObject.get('sensitive')?.value,
            type: fieldObject.get('type')?.value || FormFieldType.TEXT,
            options: fieldOptions,
          });
        }
      });

      const contentsArray: ContentUpdateDto[] = [];
      const contents = this.getItems(pageIndex);
      Object.keys(contents.controls).forEach((contentString, contentIndex) => {
        const contentObject = contents.get(contentString);
        if (contentObject?.get("content") && this.isContent(contentObject)) {
          contentsArray.push({
            id: contentObject.get('id')?.value || undefined,
            content: contentObject.get('content')?.value || '',
            order: contentIndex,
          });
        }
      });

      return {
        id: page.get('id')?.value || undefined,
        heading: page.get('heading')?.value,
        intro: page.get('intro')?.value,
        order: pageIndex,
        fields: pageFields,
        contents: contentsArray,
      };
    });

    return formPages;
  }
  openPreview(isDraft = false): void {
    this.touchAllFields();

    if (!this.form.valid) {
      this.messageService.add({
        severity: "warn",
        summary: "Warning",
        detail:
          "Some fields are invalid, please ensure you have set up this form correctly.",
        life: 5000,
      });
    }
    
    if (this.currentForm && this.currentForm.id) {
      isDraft = this.isDraft;
    } else {
      this.isPreviewing = true;
    }

    const pages = this.pages();
    if (this.form.valid && this.practice) {
      const formPages: PageUpdateDto[] = Object.keys(pages.controls).map(
        (pagekey, pageIndex) => {
          const page = pages.get(pagekey) as UntypedFormArray;

          const pageFields: FieldUpdateDto[] = [];
          const fields = this.getItems(pageIndex);
          Object.keys(fields.controls).forEach((fieldString, fieldIndex) => {
            const fieldObject = fields.get(fieldString);
            if (fieldObject && this.isField(fieldObject)) {
              const fieldOptions: OptionUpdateDto[] = [];

              if (fieldTypeHasOptions(fieldObject.get("type")?.value)) {
                const options = this.options(pageIndex, fieldIndex);
                Object.keys(options.controls).forEach((option, optionIndex) => {
                  const optionObject = options.get(option);
                  if (optionObject) {
                    fieldOptions.push({
                      id: optionObject.get("id")?.value || undefined,
                      order: optionIndex,
                      label: optionObject.get("label")?.value,
                      value: optionObject.get("value")?.value,
                      default: 
                        fieldTypeHasDefaultOption(fieldObject.get('type')?.value) &&
                          fieldObject.get('default')?.value != null && 
                          fieldObject.get('default')?.value !== '' ?
                          optionIndex === Number(fieldObject.get('default')?.value) :
                          false,
                    });
                  }
                });
              }
              const customizeContent = fieldObject.get('customizeContent')?.value;
              const prefill = customizeContent 
                  ? false 
                  : fieldObject.get('type')?.value === FormFieldType.HIDDEN 
                      ? true 
                      : fieldObject.get('prefill')?.value;
              const readonly = (customizeContent || prefill)
                              ? fieldObject.get("readonly")?.value
                              : false;
              pageFields.push({
                id: fieldObject.get("id")?.value || undefined,
                default: fieldTypeHasDefault(fieldObject.get("type")?.value)
                  ? fieldObject.get("default")?.value
                  : undefined,
                label: fieldObject.get("label")?.value || "",
                url: fieldObject.get("url")?.value || "",
                description: fieldObject.get("description")?.value || "",
                order: fieldIndex,
                prefill: prefill,
                prefillWith: fieldObject.get("prefillWith")?.value,
                placeholder: fieldObject.get("placeholder")?.value || "",
                readonly: readonly,
                customizeContent: customizeContent, 
                required: fieldObject.get("required")?.value,
                sensitive: fieldObject.get("sensitive")?.value,
                type: fieldObject.get("type")?.value || FormFieldType.TEXT,
                options: fieldOptions,
              });
            }
          });
          
          const contentsArray: ContentUpdateDto[] = [];
          const contents = this.getItems(pageIndex);
          Object.keys(contents.controls).forEach((contentString, contentIndex) => {
            const contentObject = contents.get(contentString);
            if (contentObject?.get("content") && this.isContent(contentObject)) {
              contentsArray.push({
                id: contentObject.get('id')?.value || undefined,
                content: contentObject.get('content')?.value || '',
                order: contentIndex,
              });
            }
          });

          return {
            id: page.get("id")?.value || undefined,
            heading: page.get("heading")?.value,
            intro: page.get("intro")?.value,
            order: pageIndex,
            fields: pageFields,
            contents: contentsArray,
          };
        },
      );

      this.loading = true;
      let opendFormPreviewDto: any = {
        name: this.form.controls.name?.value || '',
        intro: this.form.controls.intro?.value || '',
        estimatedCompletionDuration: this.form.controls.estimatedCompletionDuration?.value || '',
        practiceId: Number(this.practice.coreId),
        takePayment: this.form.controls.takePayment?.value || false,
        paymentAmount: this.form.controls.paymentAmount?.value || 0,
        paymentCurrency: this.form.controls.paymentCurrency?.value as CurrencyCode,
        paymentDescription: this.form.controls.paymentDescription?.value || '',
        paymentExpiresAfter: this.form.controls.paymentExpiresAfter?.value || '',
        paymentPreAuth: this.form.controls.paymentPreAuth?.value || false,
        pages: formPages,
        isDraft: isDraft,
        saveToPms: this.form.controls.saveToPms?.value || false,
        saveAgainst: this.form.controls.saveAgainst?.value || '',
      };

      if (this.formId) {
        opendFormPreviewDto = { ...opendFormPreviewDto, id: Number(this.formId) };
      }

      this.isDraft = isDraft;

      this.store.dispatch(ClearFormPreviewResult());
      this.store.dispatch(OpenFormPreview({
        dto: opendFormPreviewDto
      }));
      this.subscribeToFormPreview();
    } 
  }
  
  private generatePreviewUrl(previewFormId: number): string {
    let formsFrontendUrl = this.environmentService.get('formsFrontEndUrl');
    let token = this.cookieService.get(this.environmentService.get('jwtPrefix') + 'CORE_JWT');
    let url = formsFrontendUrl + '/forms/' + this?.practice?.coreId+ '/' + previewFormId + '/' + token;
    return url;
  }
  isValidPaymentAmount(): boolean {
    let isValidFormat = false;
    if (this.form.get("paymentAmount")?.value) {
      const regex = new RegExp(/^\d+\.?\d{0,2}$/, "g");
      isValidFormat = regex.test(
        this.form.get("paymentAmount")?.value.toString(),
      );
    }

    return isValidFormat;
  }

  saveAgainstValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.form) {
        return null;
      }

      if (!this.isAutomaticFormCompletionEnabled) {
        return null;
      }

      if (this.form.get('saveAgainst')?.value === false) {
        return null;
      }

      const saveAgainstErrors = [];

      if (!this.form.get('saveAgainst')?.value) {
        saveAgainstErrors.push('Save against is a required field');
      }

      return saveAgainstErrors.length > 0 ? saveAgainstErrors.map((err) => ({ message: err })) : null;
    };
  }

  paymentAmountValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.form) {
        return null;
      }

      if (this.form.get("takePayment")?.value === false) {
        return null;
      }

      const amountErrors = [];

      if (!this.form.get("paymentAmount")?.value) {
        amountErrors.push("Amount requested is a required field");
      }

      if (
        this.form.get("paymentAmount") &&
        this.form.get("paymentAmount")?.value &&
        this.practiceCurrency
      ) {
        if (this.form.get("paymentAmount")?.value > 999999.99) {
          amountErrors.push(
            `Amount requested cannot be more than ${this.practiceCurrency.currencySymbol}999,999.99`,
          );
        }

        if (
          this.form.get("paymentAmount")?.value <
          (this.practiceCurrency.minimumCharge || 1)
        ) {
          amountErrors.push(
            `Amount requested cannot be less than ${this.practiceCurrency.currencySymbol}${this.practiceCurrency.minimumCharge}`,
          );
        }

        if (!this.isValidPaymentAmount()) {
          amountErrors.push("Amount requested format is invalid");
        }
      }

      return amountErrors.length > 0
        ? amountErrors.map((err) => ({ message: err }))
        : null;
    };
  }
 
  showDisplayLabel(event: any, pageIndex: number, fieldIndex: number): void {
    if (this.valueVisibility[pageIndex] === undefined) {
      this.valueVisibility[pageIndex] = [];
    }
  
    if (this.valueVisibility[pageIndex][fieldIndex] === undefined) {
      this.valueVisibility[pageIndex][fieldIndex] = true; 
    }
    
    this.valueVisibility[pageIndex][fieldIndex] = !this.valueVisibility[pageIndex][fieldIndex];
    
    this.updateFieldOptionValidators(pageIndex, fieldIndex);

    if (!this.valueVisibility[pageIndex][fieldIndex]) {
      this.copyLabelToValueOnChange(pageIndex, fieldIndex);
    }

    event.preventDefault();
  }

  copyLabelToValueOnChange(pageIndex: number, fieldIndex: number): void {
    const options = this.options(pageIndex, fieldIndex).controls;
  
    options.forEach((option: AbstractControl) => {
      const formGroup = option as FormGroup; // Explicitly cast AbstractControl to FormGroup
      const labelControl = formGroup.get('label');
      const valueControl = formGroup.get('value');
  
      if (labelControl && valueControl) {
        // Unsubscribe if a subscription already exists for this formGroup
        if (this.labelSubscriptions.has(formGroup)) {
          this.labelSubscriptions.get(formGroup)?.unsubscribe();
        }

        // Subscribe to changes in the label field
        const subscription = labelControl.valueChanges.subscribe((labelText) => {
          if (!this.valueVisibility[pageIndex][fieldIndex]) {
            valueControl.setValue(labelText, { emitEvent: false });
          }
        });

        // Store the subscription
        this.labelSubscriptions.set(formGroup, subscription);
      }
    });
  }
  

  saveToPmsToggled(): void {
    setTimeout(() => {
      if (this.form.controls.saveToPms.value === true && this.isAutomaticFormCompletionEnabled) {
        this.form.controls.saveAgainst.setValidators([this.saveAgainstValidator()]);
        this.form.controls.saveAgainst.setValidators([Validators.required]);
      } else {
        this.form.controls.saveAgainst.setValidators(null);
      }

      this.form.controls.saveAgainst.updateValueAndValidity();
    }, 0);
  }
  takePaymentToggled(): void {
    setTimeout(() => {
      if (this.form.controls.takePayment.value === true) {
        this.form.controls.paymentAmount.setValidators([
          this.paymentAmountValidator(),
        ]);
        this.form.controls.paymentDescription.setValidators([
          Validators.required,
        ]);
      } else {
        this.form.controls.paymentAmount.setValidators(null);
        this.form.controls.paymentDescription.setValidators(null);
      }

      this.form.controls.paymentAmount.updateValueAndValidity();
      this.form.controls.paymentDescription.updateValueAndValidity();
    }, 0);
  }

  prefillForm(): void {
    if (this.currentForm) {
      if (this.currentForm.saveToPms) {
        this.saveToPmsToggled();
      }

      this.form = this.baseForm;

      this.form.controls.name.setValue(this.currentForm.name);
      this.form.controls.intro.setValue(this.currentForm.intro);
      this.form.controls.estimatedCompletionDuration.setValue(
        this.currentForm.estimatedCompletionDuration,
      );
      this.form.controls.takePayment.setValue(this.currentForm.takePayment);
      this.form.controls.paymentAmount.setValue(this.currentForm.paymentAmount);
      this.form.controls.paymentCurrency.setValue(
        this.currentForm.paymentCurrency,
      );
      this.form.controls.paymentDescription.setValue(
        this.currentForm.paymentDescription,
      );
      this.form.controls.paymentExpiresAfter.setValue(
        this.currentForm.paymentExpiresAfter,
      );
      this.form.controls.paymentPreAuth.setValue(
        this.currentForm.paymentPreAuth,
      );

      this.form.controls.paymentCurrency.setValue(this.currentForm.paymentCurrency);
      this.form.controls.paymentDescription.setValue(this.currentForm.paymentDescription);
      this.form.controls.paymentExpiresAfter.setValue(this.currentForm.paymentExpiresAfter);
      this.form.controls.paymentPreAuth.setValue(this.currentForm.paymentPreAuth);
      this.form.controls.saveToPms.setValue(this.currentForm.saveToPms);
      this.form.controls.saveAgainst.setValue(this.currentForm.saveAgainst);
      this.currentForm.formPages?.forEach((formPage, pageIndex) => {
        if (this.pages().length <= pageIndex) {
          this.addPage();
        }

        const currentPage = this.pages().at(pageIndex);
        currentPage.get('id')?.setValue(formPage.id);
        currentPage.get('heading')?.setValue(formPage.heading);
        currentPage.get('intro')?.setValue(formPage.intro);
       
        const combinedItems: any[] = [
          ...(formPage.pageContents?.map((content) => ({ ...content, fieldType: 'content' })) || []),
          ...(formPage.pageFields?.map((field) => ({ ...field, fieldType: 'field' })) || []),
        ].sort((a, b) => (a.order || 0) - (b.order || 0));
    
        combinedItems?.forEach((item, itemIndex) => {
          const existingItems = this.getItems(pageIndex).controls;
          const existingItem = existingItems?.find((existingItem: any) => existingItem.get('id')?.value === item.id);

          if (existingItem) {
            return; // Skip if the item already exists
          }
          if (itemIndex == 0) {
            this.removeField(pageIndex,0);
          }
          if (item.content === undefined) {
            this.addField(pageIndex);
            let fieldDefault = item.default;
            if (
              (item.type === FormFieldType.CHECKBOX || item.type === FormFieldType.AGREE_TO_TERMS) &&
              item.default === 'true'
            ) {
              fieldDefault = true;
            }

            const currentField = this.getItems(pageIndex).at(itemIndex);
            const customizeContent = item.customizeContent;
            const prefill = customizeContent ? false : item.prefill;
            if (currentField) {
              currentField.get('id')?.setValue(item.id);
              currentField.get('type')?.setValue(item.type);
              currentField.get('label')?.setValue(item.label);
              currentField.get('url')?.setValue(item.url);
              currentField.get('description')?.setValue(item.description);
              currentField.get('prefill')?.setValue(prefill);
              currentField.get('prefillWith')?.setValue(item.prefillWith);
              currentField.get('readonly')?.setValue(item.readonly);
              currentField.get('customizeContent')?.setValue(customizeContent);
              currentField.get('placeholder')?.setValue(item.placeholder);
              currentField.get('default')?.setValue(fieldDefault);
              currentField.get('required')?.setValue(item.required);

              if (item.type && !this.isPreviewing) {
                currentField.get('type')?.disable()
              }
              const fieldOptionsArray = this.options(pageIndex, itemIndex);
              item.fieldOptions?.forEach((option: any, optionIndex: any) => {
                if (fieldOptionsArray.length <= optionIndex) {
                  this.addOption(pageIndex, itemIndex);
                }

                const currentOption = this.options(pageIndex, itemIndex).at(optionIndex);
                currentOption.get('id')?.setValue(option.id);
                currentOption.get('label')?.setValue(option.label);
                currentOption.get('value')?.setValue(option.value);

                if (item.defaultOptionId && option.id === item.defaultOptionId) {
                  currentField.get('default')?.setValue(optionIndex);
                }
              });
              if (customizeContent) { 
                currentField.get('prefill')?.disable();
              }
            }
          } else {
            this.addContent(pageIndex);
            const currentContent = this.getItems(pageIndex).at(itemIndex);
            if (currentContent) {
              currentContent.get('id')?.setValue(item.id);
              currentContent.get('content')?.setValue(item.content);
            }
          }
        });
      });
       
    }
  }

  typeChanged(pageIndex: number, fieldIndex: number): void {
    this.getItems(pageIndex).at(fieldIndex).get("description")?.reset();
    setTimeout(() => {
      this.updateFieldOptionValidators(pageIndex, fieldIndex);
    }, 0);
  }

  updateFieldOptionValidators(pageIndex: number, fieldIndex: number): void {
    this.subscribeToOptionsValueChanges();
    const field = this.getItems(pageIndex).at(fieldIndex);

    if (fieldTypeHasOptions(field.get("type")?.value || "")) {
      for (const control of this.options(pageIndex, fieldIndex).controls) {
        control.get("value")?.setValidators([Validators.required]);
        control.get("value")?.updateValueAndValidity();
        control.get("label")?.setValidators([Validators.required]);
        control.get("label")?.updateValueAndValidity();
      }
    } else {
      for (const control of this.options(pageIndex, fieldIndex).controls) {
        control.get("value")?.setValidators(null);
        control.get("value")?.updateValueAndValidity();
        control.get("label")?.setValidators(null);
        control.get("label")?.updateValueAndValidity();
      }
    }
  }

  private touchAllFields(): void {
    Object.keys(this.form.controls).forEach((field) => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    const pages = this.pages();
    Object.keys(pages.controls).forEach((pageKey, pageIndex) => {
      const page = pages.get(pageKey) as UntypedFormArray;
      if (page) {
        Object.keys(page.controls).forEach((pageField) => {
          page.get(pageField)?.markAsTouched({ onlySelf: true });
        });

        const fields = this.getItems(pageIndex);
        Object.keys(fields.controls).forEach((fieldString, fieldIndex) => {
          const fieldObject = fields.get(fieldString) as UntypedFormArray;
          if (fieldObject && this.isField(fieldObject)) {
            Object.keys(fieldObject.controls).forEach((subField) => {
              fieldObject.get(subField)?.markAsTouched({ onlySelf: true });
            });

            const options = this.options(pageIndex, fieldIndex);
            if (options) {
              Object.keys(options.controls).forEach((option) => {
                const optionObject = options.get(option) as UntypedFormArray;
                if (optionObject) {
                  Object.keys(optionObject.controls).forEach((subOption) => {
                    optionObject
                      .get(subOption)
                      ?.markAsTouched({ onlySelf: true });
                  });
                }
              });
            }
          }
        });

        const contents = this.getItems(pageIndex);
        Object.keys(contents.controls).forEach((contentString) => {
          const contentObject = contents.get(contentString) as UntypedFormArray;
          if (contentObject) {
            Object.keys(contentObject.controls).forEach((subContent) => {
              contentObject.get(subContent)?.markAsTouched({ onlySelf: true });
            });
          }
        });
      }
    });
  }

  isField(item: AbstractControl): boolean {
    return !item.get('content');
  }

  isContent(item: AbstractControl): boolean {
    return Boolean(item.get('content'));
  }

  getItems(pageIndex: number): UntypedFormArray {
    return this.pages().at(pageIndex).get("items") as UntypedFormArray;
  }

  getFieldIndex(pageIndex: number, itemIndex: number): number {
    const itemsArray: any = this.getItems(pageIndex).controls;
    let fieldIndex = -1;
    
    for (let i = 0; i <= itemIndex; i++) {
      if (itemsArray[i] && this.isField(itemsArray[i])) {
        fieldIndex++;
      }
    }

    return fieldIndex;
  }

  getContentIndex(pageIndex: number, itemIndex: number): number {
    const itemsArray: any = this.getItems(pageIndex).controls;
    let contentIndex = -1;
    for (let i = 0; i <= itemIndex; i++) {
      if (itemsArray[i] && this.isContent(itemsArray[i])) {
        contentIndex++;
      }
    }

    return contentIndex;
  }

  moveItemUp(pageIndex: number, itemIndex: number): void {
    if (itemIndex > 0) {
      const itemsArray = this.getItems(pageIndex);
      const item = itemsArray.at(itemIndex);
      itemsArray.removeAt(itemIndex);
      itemsArray.insert(itemIndex - 1, item);
    }
  }

  moveItemDown(pageIndex: number, itemIndex: number): void {
    const itemsArray = this.getItems(pageIndex);
    if (itemIndex < itemsArray.length - 1) {
      const item = itemsArray.at(itemIndex);
      itemsArray.removeAt(itemIndex);
      itemsArray.insert(itemIndex + 1, item);
    }
  
  }

  removeItem(pageIndex: number, itemIndex: number): void {
    const itemsArray = this.getItems(pageIndex);
    itemsArray.removeAt(itemIndex);
  }
}
