import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EnvironmentService} from '../services/environment.service';
import {HttpClient} from '@angular/common/http';
import {ProductRequestAdapter} from '../adapters/product-request.adapter';
import {ProductRequestDto} from '../interfaces/dto/product-request.dto';
import {ProductRequest} from '../models/ProductRequest';
import {Practice} from '../models/Practice';
import {Contact} from '../models/Contact';
import {ProductRequestItem} from '../interfaces/product-request-item';
import {ProductRequestItem as ProductRequestItemModel} from '../models/ProductRequestItem';
import {Patient} from '../models/Patient';
import {Client} from '../models/Client';
import {User} from '../models/User';
import {ProductRequestStatus} from '../enums/product-request-status';
import {getEnumKeyByEnumValue} from '../helpers/get-enum-key-by-value';
import {Comment} from '../models/Comment';
import {CommentDto} from '../interfaces/dto/comment.dto';
import {CommentAdapter} from '../adapters/comment.adapter';
import {SortByOption} from '../interfaces/sort-by-option.interface';
import {FilterSelection} from '../interfaces/filter-selection.interface';
import {PaymentRequest} from '../interfaces/payment-request';
import {Channel} from '../enums/channel';
import {practiceHasFeature} from '../helpers/practice-has-feature';
import {PracticeFeature} from '../enums/practice-feature';
import {ProductRequestApprovalStatus} from '../enums/product-request-approval-status';
import {ProductRequestItemDto} from '../interfaces/dto/product-request-item.dto';
import {ProductRequestItemAdapter} from '../adapters/product-request-item.adapter';
import {Group} from '../models/Group';
import {isUser} from '../helpers/is-user';
import { PracticeConfigInterface } from '../interfaces/practice-config.interface';
import { PracticeConfig } from '../enums/practice-config';
import {AuditLogDto} from "../interfaces/dto/audit-log.dto";
import {AuditLog} from "../models/AuditLog";
import {AuditLogAdapter} from "../adapters/audit-log.adapter";

@Injectable({
  providedIn: 'root'
})
export class ProductRequestService {

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private productRequestAdapter: ProductRequestAdapter,
    private productRequestItemAdapter: ProductRequestItemAdapter,
    private commentAdapter: CommentAdapter,
    private auditLogAdapter: AuditLogAdapter,
  ) { }

  getProductRequests(practiceId: string): Observable<ProductRequest[]> {
    const url = this.environmentService.get('backendUrl') + `/product-requests`;
    return this.http.get<ProductRequestDto[]>(url, {
      params: {
        practiceId
      },
      withCredentials: true
    }).pipe(
      map((response: ProductRequestDto[]) => {
        return response.map(status => this.productRequestAdapter.run(status));
      })
    );
  }

  getProductRequestList(practiceId: string, searchString: string | null, filters: FilterSelection, sortBy: SortByOption, page: number): Observable<{
    items: ProductRequest[],
    total: number
  }> {
    const url = this.environmentService.get('backendUrl') + `/product-requests/list`;
    return this.http.post<{
      items: ProductRequestDto[],
      total: number
    }>(url, {
      searchString,
      filters,
      sortBy,
      page,
      perPage: this.environmentService.get('productRequestsPerPage')
    },
    {
      params: {
        practiceId
      },
      withCredentials: true
    }).pipe(
      map((response: {
        items: ProductRequestDto[],
        total: number
      }) => {
        return {
          items: response.items.map(status => this.productRequestAdapter.run(status)),
          total: response.total
        };
      })
    );
  }

  createRequest(practice: Practice | null, client: Client, contact: Contact, patient: Patient, assignee: User | Group | null, items: ProductRequestItem[], channel: Channel): Observable<ProductRequest>  {
    if (!practice) {
      throw new Error('Practice not set');
    }

    let assigneeUserId = null;
    let assigneeGroupId = null;

    if (assignee && isUser(assignee)) {
      assigneeUserId = assignee.id;
    } else if (assignee && !isUser(assignee)) {
      assigneeGroupId = assignee.id;
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests?practiceId=${practice.id}`;
    return this.http.post<ProductRequestDto>(url, {
      clientId: client.id,
      patientPmsId: patient.pmsId,
      patientName: patient.name,
      patientSpecies: patient.species,
      patientBreed: patient.breed,
      assigneeUserId,
      assigneeGroupId,
      channel,
      contact,
      items
    }, {withCredentials: true}).pipe(
      map((response: ProductRequestDto) => {
        return this.productRequestAdapter.run(response);
      })
    );
  }

  moveRequest(practice: Practice | null, productRequest: ProductRequest, status: ProductRequestStatus): Observable<ProductRequest>  {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequest.id}/move?practiceId=${practice.id}`;
    return this.http.post<ProductRequestDto>(url, {
      status: getEnumKeyByEnumValue(ProductRequestStatus, status),
    }, {withCredentials: true}).pipe(
      map((response: ProductRequestDto) => {
        return this.productRequestAdapter.run(response);
      })
    );
  }

  getHistory(practice: Practice | null, productRequestId: number): Observable<AuditLog[]>  {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequestId}/history?practiceId=${practice.id}`;
    return this.http.get<any>(url, {withCredentials: true}).pipe(
      map((history: AuditLogDto[]) => {
        return history.map((item) => this.auditLogAdapter.run(item));
      })
    );
  }

  getComments(practice: Practice | null, productRequestId: number): Observable<Comment[]>  {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequestId}/comments?practiceId=${practice.id}`;
    return this.http.get<CommentDto[]>(url, {withCredentials: true}).pipe(
      map((comments: CommentDto[]) => {
        return comments.map(comment => this.commentAdapter.run({
          ...comment,
          content: JSON.parse(comment.content)
        }));
      })
    );
  }

  addComment(practice: Practice | null, productRequestId: number, message: string): Observable<Comment>  {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequestId}/comments?practiceId=${practice.id}`;
    return this.http.post<CommentDto>(url, {
      message,
    }, {withCredentials: true}).pipe(
      map((comment: CommentDto) => {
        return this.commentAdapter.run({
          ...comment,
          content: JSON.parse(comment.content)
        });
      })
    );
  }

  approveProductRequest(practice: Practice | null, productRequest: ProductRequest, notifyClient: boolean, rejectionReason: string): Observable<ProductRequest>  {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequest.id}/approve?practiceId=${practice.id}`;
    return this.http.post<ProductRequestDto>(url, {
      notifyClient,
      rejectionReason
    }, {withCredentials: true}).pipe(
      map((response: ProductRequestDto) => {
        return this.productRequestAdapter.run(response);
      })
    );
  }

  rejectProductRequest(practice: Practice | null, productRequest: ProductRequest, rejectionReason: string, notifyClient: boolean): Observable<ProductRequest> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequest.id}/reject?practiceId=${practice.id}`;
    return this.http.post<ProductRequestDto>(url, {
      rejectionReason,
      notifyClient
    }, {withCredentials: true}).pipe(
      map((response: ProductRequestDto) => {
        return this.productRequestAdapter.run(response);
      })
    );
  }

  createProductRequestPayment(practice: Practice | null, productRequest: ProductRequest, paymentRequest: PaymentRequest): Observable<ProductRequest> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequest.id}/create-payment?practiceId=${practice.id}`;
    return this.http.post<ProductRequestDto>(url, {
      description: paymentRequest.description,
      amount: paymentRequest.amount,
      siteId: paymentRequest.siteId,
      invoiceId: paymentRequest?.paymentInvoice ? paymentRequest.paymentInvoice : null,
      invoiceNumber: paymentRequest?.paymentInvoiceNumber ? paymentRequest.paymentInvoiceNumber : null,
    }, {withCredentials: true}).pipe(
      map((response: ProductRequestDto) => {
        return this.productRequestAdapter.run(response);
      })
    );
  }

  confirmProductRequestPaid(practice: Practice | null, productRequest: ProductRequest): Observable<ProductRequest> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequest.id}/confirm-paid?practiceId=${practice.id}`;
    return this.http.get<ProductRequestDto>(url, {withCredentials: true}).pipe(
      map((response: ProductRequestDto) => {
        return this.productRequestAdapter.run(response);
      })
    );
  }

  dispenseProductRequest(practice: Practice | null, productRequest: ProductRequest, notifyClient: boolean, config: PracticeConfigInterface | null): Observable<ProductRequest> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    let status = ProductRequestStatus.FULFILLED;
    //if (config && config[PracticeConfig.PRODUCT_REQUEST_SHOW_PAID_COLUMN] === "0") {
      // If dispensed column is disabled - send straight to complete column
      //status = ProductRequestStatus.COMPLETE;
    //}

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequest.id}/dispense?practiceId=${practice.id}`;
    return this.http.post<ProductRequestDto>(url, {
      notifyClient,
      status: getEnumKeyByEnumValue(ProductRequestStatus, status)
    }, {withCredentials: true}).pipe(
      map((response: ProductRequestDto) => {
        return this.productRequestAdapter.run(response);
      })
    );
  }

  updateProductRequestItemApproval(practice: Practice | null, productRequestItem: ProductRequestItemModel, approvalStatus: ProductRequestApprovalStatus, approvedCount: number, rejectionReason: string | null, approvedItem: string | null): Observable<ProductRequestItemModel> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/item/${productRequestItem.id}/updateApproval?practiceId=${practice.id}`;
    return this.http.post<ProductRequestItemDto>(url, {
      approvalStatus: getEnumKeyByEnumValue(ProductRequestApprovalStatus, approvalStatus),
      approvedCount,
      rejectionReason,
      approvedItem
    }, {withCredentials: true}).pipe(
      map((response: ProductRequestItemDto) => {
        return this.productRequestItemAdapter.run(response);
      })
    );
  }

  updateProductRequestItemFulfilled(practice: Practice | null, productRequestItem: ProductRequestItemModel, fulfilledCount: number): Observable<ProductRequestItemModel> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/item/${productRequestItem.id}/updateFulfilled?practiceId=${practice.id}`;
    return this.http.post<ProductRequestItemDto>(url, {
      fulfilledCount
    }, {withCredentials: true}).pipe(
      map((response: ProductRequestItemDto) => {
        return this.productRequestItemAdapter.run(response);
      })
    );
  }

  updateProductRequestApproval(
    practice: Practice | null,
    productRequest: ProductRequest,
    approvalCounts: { itemId: number; approvedQty: number; approvedItem: string | null }[],
    rejectionReasons: { itemId: number; reason: string | null }[]
  ): Observable<ProductRequest> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequest.id}/updateApproval?practiceId=${practice.id}`;
    return this.http.post<ProductRequestDto>(url, {
      approvalCounts,
      rejectionReasons
    }, {withCredentials: true}).pipe(
      map((response: ProductRequestDto) => {
        return this.productRequestAdapter.run(response);
      })
    );
  }

  updateProductRequestAssignee(
    practice: Practice | null,
    productRequest: ProductRequest,
    assignee: User | Group | null,
  ): Observable<ProductRequest> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    let assigneeUserId = null;
    let assigneeGroupId = null;

    if (assignee && isUser(assignee)) {
      assigneeUserId = assignee.id;
    } else if (assignee && !isUser(assignee)) {
      assigneeGroupId = assignee.id;
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequest.id}/update-assignee?practiceId=${practice.id}`;
    return this.http.post<ProductRequestDto>(url, {
      assigneeUserId,
      assigneeGroupId
    }, {withCredentials: true}).pipe(
      map((response: ProductRequestDto) => {
        return this.productRequestAdapter.run(response);
      })
    );
  }

  updateProductRequestOwner(
    practice: Practice | null,
    productRequest: ProductRequest,
    owner: User,
  ): Observable<ProductRequest> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/product-requests/${productRequest.id}/update-owner?practiceId=${practice.id}`;
    return this.http.post<ProductRequestDto>(url, {
      ownerId: owner ? owner.id : null
    }, {withCredentials: true}).pipe(
      map((response: ProductRequestDto) => {
        return this.productRequestAdapter.run(response);
      })
    );
  }
}
