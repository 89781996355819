import { createFeatureSelector, createSelector } from '@ngrx/store';
import { STATE_KEY } from 'src/app/state/reducers';
import { CampaignState } from './reducer';
import { CampaignFilterSelection } from '../../interfaces/campaign-filter-selection';
import { CampaignFilterType } from '../../enums/campaign-filter-type';

export const getCampaignState = createFeatureSelector<CampaignState>(STATE_KEY.CAMPAIGNS);

export const getCampaigns = createSelector(
  getCampaignState,
    (state: CampaignState) => state.campaigns,
);

export const isNewCampaignOpen = createSelector(
  getCampaignState,
  (state: CampaignState) => state.newCampaignOpen,
);

export const getCreatedCampaignData = createSelector(
  getCampaignState,
  (state: CampaignState) => {
    return {
      createdCampaigns: state.createdCampaigns,
      invalidNumbers: state.invalidNumbers,
      optedOutUsers: state.optedOutUsers,
      duplicates: state.duplicates,
      dataErrors: state.dataErrors,
    };
  },
);

export const getCampaignFilterSelection = createSelector(
  getCampaignState,
  (state: CampaignState) => state.filters
);

export const getFilterSlectedBroadCast = createSelector(
  getCampaignFilterSelection,
  (state: CampaignFilterSelection) => state[CampaignFilterType.BROADCAST_STATUS]
);

export const getFilterSlectedChannel = createSelector(
  getCampaignFilterSelection,
  (state: CampaignFilterSelection) => state[CampaignFilterType.CHANNEL]
);

export const getFilterSlectedTemplate = createSelector(
  getCampaignFilterSelection,
  (state: CampaignFilterSelection) => state[CampaignFilterType.TEMPLATE]
);

export const getFilterCampaignSearchTerm = createSelector(
  getCampaignFilterSelection,
  (state: CampaignFilterSelection) => state[CampaignFilterType.SEARCH_TERM]
);