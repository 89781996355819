import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Client } from '../../../models/Client';
import { FormSubmission } from '../../../forms/interfaces/form-submission';
import { Store, select } from '@ngrx/store';
import { getClientFormSubmissions } from '../../../forms/state/selectors';
import { Observable, takeWhile } from 'rxjs';
import { GetClientFormSubmissions } from '../../../forms/state/actions';
import { FormSubmissionStatus } from '../../../enums/form-submission-status';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { Practice } from '../../../models/Practice';
import { practiceHasFeature } from '../../../helpers/practice-has-feature';
import { PracticeFeature } from '../../../enums/practice-feature';
import { practiceSupportsFormSync } from '../../../helpers/practice-support-form-sync';

@Component({
  selector: 'form-request',
  templateUrl: './form-request.component.html',
  styleUrl: './form-request.component.scss',
})
export class FormRequestComponent implements OnInit, OnDestroy {
  @Input() client?: Client;
  alive = true;
  modalActive = false;
  practice$?: Observable<Practice | null>;
  isAutomaticFormCompletionEnabled: boolean = false;
  formSubmissions: FormSubmission[] = [];
  public FormSubmissionStatus = FormSubmissionStatus;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToFormSubmissions();

    if (this.client && this.client.id !== '0') {
      this.store.dispatch(GetClientFormSubmissions({client: this.client}));
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));

    this.practice$.subscribe((practice) => {
      if (practice) {
        this.isAutomaticFormCompletionEnabled = practiceSupportsFormSync(practice);
      }
    });
  }

  subscribeToFormSubmissions(): void {
    this.store.pipe(select(getClientFormSubmissions)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((formSubmissions) => {
      this.formSubmissions = formSubmissions;
    });
  }

  goToAllForms(): void {
    if (this.client) {
      location.href = `search?s=${this.client.pmsId}&searchType=form-submissions`;
    }
  }

  showModal(): void {
    this.modalActive = true;
  }
}
