import { PMS } from '../enums/pms';
import { PracticeFeature } from '../enums/practice-feature';
import { Practice } from '../models/Practice';

export function practiceSupportsFormSync(practice: Practice | null | undefined): boolean {
    if (!practice) {
      return false;
    }

    const supportSync = (practice.pms === PMS.ROBOVET ||  practice.pms === PMS.VETIT) ? false : true;

    if (supportSync) {
      return true;
    } else {
      return false;
    }
}
