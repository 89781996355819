import { createAction, props } from '@ngrx/store';
import { CampaignAddDto } from 'src/app/interfaces/dto/campaign-add.dto';
import { Campaign } from 'src/app/models/Campaign';
import { Practice } from 'src/app/models/Practice';
import {CampaignEditDto} from "../../interfaces/dto/campaign-edit.dto";
import {User} from "../../models/User";
import { BroadcastStatus } from '../../enums/broadcast-status';
import { Channel } from '../../enums/channel';
import { Template } from '../../models/Template';

export const GetCampaigns = createAction(
    '[Campaign] Get Campaigns',
    props<{practice: Practice}>()
);

export const GetCampaignsSuccess = createAction(
    '[Campaign] Get Campaigns - Success',
    props<{campaigns: Campaign[]}>()
);

export const OpenNewCampaign = createAction(
  '[Campaign] Open Dialog',
);

export const CloseNewCampaign = createAction(
  '[Campaign] Close Dialog',
);

export const AddCampaign = createAction(
    '[Campaign] Add Campaign',
    props<{campaign: CampaignAddDto}>()
);

export const AddCampaignSuccess = createAction(
    '[Campaign] Add Campaigns - Success',
    props<{
      campaigns: Campaign[],
      invalidNumbers: string[],
      optedOutUsers: User[],
      duplicates: string[],
      dataErrors: {name: string, errors: number}[],
    }>()
);

export const ClearCreatedCampaigns = createAction(
  '[Campaign] Clear Created Campaigns'
);

export const EditCampaign = createAction(
  '[Campaign] Edit Campaign',
  props<{campaign: CampaignEditDto}>()
);

export const EditCampaignSuccess = createAction(
  '[Campaign] Edit Campaigns - Success',
  props<{originalCampaignId: string, campaigns: Campaign[]}>()
);

export const DeleteCampaign = createAction(
    '[Campaign] Delete Campaign',
    props<{campaign: Campaign}>()
);

export const DeleteCampaignSuccess = createAction(
    '[Campaign] Delete Campaigns - Success',
    props<{campaignId: string}>()
);

export const SetCampaignSearchTerm = createAction(
    '[Campaign] Set Campaign Search Term',
    props<{option: string}>()
);

export const UpdateCampaignFilterBroadCastStatus = createAction(
  '[Campaign] Update Campaign Filter Broad Cast Status',
  props<{option: BroadcastStatus}>()
);

export const UpdateCampaignFilterChannel = createAction(
  '[Campaign] Update Campaign Filter Channel',
  props<{option: Channel}>()
);

export const UpdateCampaignFilterTemplate = createAction(
  '[Campaign] Update Campaign Filter Template',
  props<{option: Template}>()
);

export const ClearCampaignFilters = createAction(
    '[Campaign] Clear Filters'
);

