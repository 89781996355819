<div class="btn-wrap">
  <button
    pButton
    label="Form requests"
    class="p-button-fourth p-button-sm"
    (click)="showModal()"
  ></button>
</div>
<ng-container>
  <p-dialog
    header="Form requests"
    [(visible)]="modalActive"
    [modal]="true"
    [style]="{ width: '700px', 'min-height': '200px' }"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
  <div class="wrapper">
    <div class="top-section">
      <div class="row title" [class.has-save-to-pms-column]="isAutomaticFormCompletionEnabled">
        <div class="cell">Form</div>
        <div class="cell">Sent</div>
        <div class="cell">Status</div>
        <ng-container *ngIf="isAutomaticFormCompletionEnabled">
          <div class="cell synced-to-pms">Saved to PMS</div>
        </ng-container>
      </div>
      <ng-container *ngFor="let formSubmission of formSubmissions">
        <div class="row" [class.has-save-to-pms-column]="isAutomaticFormCompletionEnabled">
          <div class="cell">
            {{ formSubmission.form.name }}
          </div>

          <div class="cell">
            {{ formSubmission.createdAt | date : "dd/MM/yy 'at' h:mma" }}
          </div>

          <div class="cell">
            <div class="capsule">
              <ng-container *ngIf="!formSubmission.completedAt">
                <div
                  class="cell bold"
                  [style.color]="
                    FormSubmissionStatus.PENDING | getFormSubmissionStatusColor
                  "
                >Pending</div>
              </ng-container>
              <ng-container *ngIf="formSubmission.completedAt">
                <div
                  class="cell bold"
                  [style.color]="
                    FormSubmissionStatus.COMPLETED | getFormSubmissionStatusColor
                  "
                >Complete</div>
              </ng-container>
            </div>
          </div>

          <div class="cell">
            <ng-container *ngIf="isAutomaticFormCompletionEnabled">
              <div class="synced-to-pms">
                <ng-container *ngIf="formSubmission?.form?.saveToPms">
                  <div class="not-saved-to-pms">
                    <ng-container *ngIf="formSubmission?.syncedToPms">
                      <i
                        class="pi pi-check-circle"
                        pTooltip="Saved to PMS"
                        tooltipStyleClass="success-tooltip"
                        tooltipPosition="top"
                      ></i>
                    </ng-container>
                    <ng-container *ngIf="!formSubmission?.syncedToPms" >
                        <img src="assets/images/icons/pending-submission.png" 
                          height="22px"
                          alt="Pending submission to PMS" 
                          pTooltip="Pending form completion"
                          tooltipStyleClass="tooltip-error"
                          tooltipPosition="top">
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="!formSubmission?.form?.saveToPms">
                  N/A
                </ng-container>
              </div>
            </ng-container>
          </div>
         
        </div>
      </ng-container>
    </div>
    <div class="bottom-btn-row">
      <p-button
        [label]="'View all Form requests for ' + client?.fullName"
        (click)="goToAllForms()"
      ></p-button>
    </div>
  </div>
  </p-dialog>
</ng-container>

