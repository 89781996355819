import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ClientService } from 'src/app/clients/client.service';
import { Client } from 'src/app/models/Client';
import { Conversation } from 'src/app/models/Conversation';
import { ConversationStatus } from 'src/app/models/ConversationStatus';
import { AppState } from 'src/app/state/reducers';
import { getCurrentConversationAndPractice, getCurrentConversationClientAndPractice } from 'src/app/state/selectors';
import { ConversationService } from '../conversation.service';
import * as ConversationActions from './actions';
import * as PatientActions from '../../patients/state/actions';
import { Router } from '@angular/router';
import {of, takeUntil} from 'rxjs';
import { Noop } from 'src/app/state/actions';
import { MessageService } from 'primeng/api';
import { Patient } from 'src/app/models/Patient';
import { PatientService } from 'src/app/patients/patients.service';
import { WebsocketService } from '../websocket.service';
import { Comment } from 'src/app/models/Comment';
import { HistoryItem } from 'src/app/models/HistoryItem';
import { MessagesService } from '../../messages/messages.service';
import { getUser } from '../../auth/state/selectors';
import { WhatsappTemplateService } from '../../services/whatsapp-template.service';
import { UserService } from 'src/app/users/user.service';
import { User } from 'src/app/models/User';
import { ConversationAdapter } from 'src/app/adapters/conversation.adapter';
import { FullPayment } from '../../models/FullPayment';
import { ResendPaymentLink } from '../../payments/state/actions';
import {EnvironmentService} from '../../services/environment.service';
import { getPatients } from 'src/app/patients/state/selectors';
import { getConversationPatient } from './selectors';
import { getCurrentPractice } from 'src/app/practices/state/selectors';
import { getPatientPage } from 'src/app/patients/state/selectors';
import { CampaignMessage } from 'src/app/models/CampaignMessage';
import { MessageType } from 'src/app/enums/message-type';
import { FacebookTemplateService } from 'src/app/services/facebook-template.service';
import {buildUrlParamsFromConversationFilters} from '../../helpers/build-url-params-from-conversation-filters';
import {CloseNewConversation} from '../../dialogs/state/actions';

@Injectable()
export class ConversationEffects {
  constructor(
    private actions$: Actions,
    private conversationService: ConversationService,
    private clientService: ClientService,
    private patientService: PatientService,
    private store: Store<AppState>,
    private router: Router,
    private messageService: MessageService,
    private messagesService: MessagesService,
    private websocketService: WebsocketService,
    private userService: UserService,
    private whatsappTemplateService: WhatsappTemplateService,
    private facebookTemplateService: FacebookTemplateService,
    private conversationAdapter: ConversationAdapter,
    private environmentService: EnvironmentService,
  ) {
  }

  getConversation$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.GetConversation),
    mergeMap((action) => this.conversationService.getConversation(action.id)
      .pipe(
        map((result: Conversation) => {
          return ConversationActions.GetConversationSuccess({conversation: result});
        })
      ))
    )
  );


  getPreviousConversation$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.GetPreviousConversations),
    mergeMap((action) => this.conversationService.getPreviousConversations(action.conversationId, action.skip)
      .pipe(
        map((result: Array<Conversation|CampaignMessage>) => {
          return ConversationActions.GetPreviousConversationsSuccess({previousConversations: result});
        })
      ))
    )
  );

  getConversationStatuses$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.GetConversationStatuses),
    mergeMap((action) => this.conversationService.getConversationStatuses()
      .pipe(
        map((result: ConversationStatus[]) => {
          return ConversationActions.GetConversationStatusesSuccess({statuses: result});
        })
      ))
    )
  );

  newConversation$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.NewConversation),
    withLatestFrom(this.store.select(getUser), this.store.select(getCurrentPractice)),
    mergeMap(([action, user, practice]) => this.conversationService.createConversation(
        action.practice.id,
        action.channel,
        action.client,
        action.contact,
        action.payment,
        action.initialMessage,
        action.template,
        action.patient,
      ).pipe(
        tap(async (result) => {
          if (user) {
            if (action.initialMessage) {
              this.websocketService.sendMessage(
                result.id,
                action.initialMessage,
                MessageType.STANDARD,
                action.template ?? undefined,
                action.selectedMedia,
                action.mergeFields ?? undefined,
                action.buttonLink,
              );
            }

            if (action.payment) {
              this.store.dispatch(ResendPaymentLink({payment: action.payment}));
            } else {
              this.router.navigateByUrl('conversations/' + result.id);
            }
          }
        }),
        map((result: Conversation) => {
          this.store.dispatch(CloseNewConversation());
          return ConversationActions.GetConversationSuccess({conversation: result});
        }),
        catchError((error) => {
          return of(ConversationActions.NewConversationError({
            errorMessage: error.error.error,
            existingConversation: this.conversationAdapter.run(error.error.conversation)
          }));
        })
      ))
    )
  );

  updateConversationStatus$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.SetConversationStatus),
    mergeMap((action) => this.conversationService.updateConversation(
      Number(action.conversationId),
      {statusId: Number(action.status.id)}
    )
      .pipe(
        map((result: Conversation) => {
          return ConversationActions.GetConversationSuccess({conversation: result});
        })
      ))
    )
  );

  updateConversationOwner$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.SetConversationOwner),
    mergeMap((action) => this.conversationService.updateConversation(
      Number(action.conversationId),
      {ownerId: action.owner.id !== '0' ? Number(action.owner.id) : null}
    )
      .pipe(
        map((result: Conversation) => {
          return ConversationActions.GetConversationSuccess({conversation: result});
        })
      ))
    )
  );

  updateConversationAssignee$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.SetConversationAssignee),
    mergeMap((action) => {
      let AssignedUser;
      let AssignedGroup;

      if ('firstName' in action.assignee) {
        // This is a user
        AssignedUser = action.assignee.id !== '0' ? Number(action.assignee.id) : null;
        AssignedGroup = null;
      } else {
        // This is a group
        AssignedUser = null;
        AssignedGroup = action.assignee.id !== '0' ? Number(action.assignee.id) : null;
      }

      return this.conversationService.updateConversation(
        Number(action.conversationId),
        {assigneeGroupId: AssignedGroup, assigneeUserId: AssignedUser}
      )
      .pipe(
        map((result: Conversation) => {
          return ConversationActions.GetConversationSuccess({conversation: result});
        })
      );
    })
    )
  );

  updateConversationSummary$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.UpdateConversationSummary),
    mergeMap((action) => this.conversationService.updateConversation(
      Number(action.conversationId),
      {summary: action.summary}
    )
      .pipe(
        map((result: Conversation) => {
          return ConversationActions.GetConversationSuccess({conversation: result});
        })
      ))
    )
  );

  updateResolvedAt$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.SetConversationAsResolved),
    mergeMap((action) => this.conversationService.updateConversation(
      Number(action.conversationId),
      {
        resolvedAt: new Date().toISOString(),
        newMessage: 0
      }
    )
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Conversation resolved successfully',
            life: 5000
          });
          setTimeout(() => {
            this.router.navigateByUrl('day-list');
          }, 3000);
        }),
        map((result: Conversation) => {
          return ConversationActions.GetConversationSuccess({conversation: result});
        })
      )
      .pipe(
        tap(() => {
          if (action.saveToPms && !action.sendTextToPms) {
            this.conversationService.sendToPms(Number(action.conversationId), false)
              .subscribe((response) => {
              }, (err) => {
                this.messageService.add({
                  key: 'save-conversation-pms-error',
                  severity: 'error',
                  summary: 'Error',
                  detail: 'There was an error saving the conversation to your PMS',
                  data: {
                    conversationId: Number(action.conversationId),
                  },
                  life: 30000
                });
              });
          }

          if (action.downloadPdf) {
            this.conversationService.downloadPdf(Number(action.conversationId))
              .subscribe(
                (data: Blob) => {
                  const file = new Blob([data], { type: 'application/pdf' });
                  const fileURL = URL.createObjectURL(file);

                  const a = document.createElement('a');
                  a.href = fileURL;
                  a.target = '_blank';
                  a.download = `conversation-${action.conversationId}.pdf`;
                  document.body.appendChild(a);
                  a.click();
                },
                (error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'There was an error downloading a PDF of this conversation',
                    life: 5000
                  });
                }
              );
          }

          if (action.sendTextToPms) {
            this.conversationService.sendToPms(Number(action.conversationId), true, action.saveToPms)
              .subscribe((response) => {
              }, (err) => {
                this.messageService.add({
                  key: 'save-conversation-pms-error',
                  severity: 'error',
                  summary: 'Error',
                  detail: 'There was an error saving the conversation to your PMS',
                  life: 30000
                });
              });
          }
        }),
      ))
    )
  );

  exportConversationsAsPdfs$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.ExportConversationsAsPdfs),
    tap((action) => {
      this.conversationService.downloadPdfs(action.conversations)
        .subscribe((data: Blob) => {
          const file = new Blob([data], { type: 'application/zip' });
          const fileURL = URL.createObjectURL(file);

          const a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = `conversations.zip`;
          document.body.appendChild(a);
          a.click();
          this.store.dispatch(ConversationActions.ExportConversationsAsPdfsComplete());
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error downloading these conversations',
            life: 5000
          });
          this.store.dispatch(ConversationActions.ExportConversationsAsPdfsComplete());
        });
    })), { dispatch: false }
  );

  exportConversationAsPdf$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.ExportConversationAsPdf),
    tap((action) => {
      this.conversationService.downloadPdf(action.conversationId)
        .subscribe((data: Blob) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          const a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = `conversation-${action.conversationId}.pdf`;
          document.body.appendChild(a);
          a.click();
          this.store.dispatch(ConversationActions.ExportConversationAsPdfComplete());
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error downloading this conversation',
            life: 5000
          });
          this.store.dispatch(ConversationActions.ExportConversationAsPdfComplete());
        });
    })), { dispatch: false }
  );

  updateNewMessage$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.SetConversationAsRead),
    mergeMap((action) => this.conversationService.updateConversation(
      Number(action.conversationId),
      {newMessage: 0}
    ))), { dispatch: false }
  );

  setConversationClient$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.SetConversationClient),
    withLatestFrom(this.store.select(getCurrentConversationAndPractice)),
    filter(([action, data]) => data.conversation !== null && data.practice !== null),
    mergeMap(([action, data]) => this.conversationService.setClient(
      Number(data.conversation!.id),
      action.client,
      data.practice!
    ).pipe(
      map((result: Conversation) => {
        return ConversationActions.GetConversationSuccess({conversation: result});
      })
    ))
    )
  );

  refreshConversationClient$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.RefreshConversationClient),
    mergeMap((action) => this.conversationService.refreshClient(
      action.conversationId
    ).pipe(
      map((result) => {
        return ConversationActions.RefreshClientComplete();
      })
    ))
    )
  );

  getMoreNewConversationPatients$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.GetMoreNewConversationPatients),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      if (action.client && practice) {
        return this.clientService.getClientByPmsId(action.client.pmsId, practice.id, action.page).pipe(
          map((result: { client: Client, patients: Patient[] }) => {
            return ConversationActions.GetNewConversationPatientsSuccess({patients: result.patients, replace: false});
          })
        );
      }

      return of(ConversationActions.GetNewConversationPatientsSuccess({patients: [], replace: true}));
    })
  ));

  getNewConversationPatients$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.SetNewConversationClient),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      if (action.client && practice) {
        return this.clientService.getClientByPmsId(action.client.pmsId, practice.id, 0).pipe(
          map((result: { client: Client, patients: Patient[] }) => {
            return ConversationActions.GetNewConversationPatientsSuccess({patients: result.patients, replace: true});
          })
        );
      }

      return of(ConversationActions.GetNewConversationPatientsSuccess({patients: [], replace: true}));
    })
  ));

  getClient$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.GetClient),
    withLatestFrom(this.store.select(getPatientPage)),
    mergeMap(([action, data]) => this.clientService.getClient(action.clientId, data).pipe(
      tap((result: { client: Client, patients: Patient[] }) => {
        this.store.dispatch(PatientActions.GetPatientsSuccess({patients: result.patients, replace: true}));
      }),
      map((result: { client: Client, patients: Patient[] }) => {
        return ConversationActions.GetClientSuccess({client: result.client});
      }),
      takeUntil(this.actions$.pipe(ofType(PatientActions.ClearPatients))),
      catchError(() => {
        return of(ConversationActions.GetClientFailed());
      })
    ))
  ));

  getClientPaymentHistory$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.GetClientPaymentHistory),
    mergeMap((action) => this.clientService.getClientPaymentHistory(action.clientId).pipe(
      map((result: FullPayment[]) => {
        return ConversationActions.GetClientPaymentHistorySuccess({payments: result});
      })
    ))
  ));

  shareClientToPms$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.ShareClientToPms),
    withLatestFrom(this.store.select(getCurrentPractice), this.store.select(getConversationPatient), this.store.select(getPatients)),
    mergeMap(([action, currentPractice, selectedPatient, allPatients]) => {
      if (!currentPractice) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred when sharing to PMS',
          life: 5000
        });
        return of(Noop());
      }

      let patient: Patient | null = null;

      if (selectedPatient) {
        patient = selectedPatient;
      }

      if (!patient && allPatients) {
        patient = allPatients[0];
      }

      if (patient) {
        return this.clientService.shareToPmsWithPmsId(currentPractice?.id, action.client.pmsId, patient.pmsId).pipe(
          map(() => {
            return ConversationActions.ShareClientToPmsSuccess();
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when sharing to PMS',
              life: 5000
            });
            return of(Noop());
          }));
      }

      return this.clientService.shareToPms(action.client).pipe(
        map(() => {
          return ConversationActions.ShareClientToPmsSuccess();
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when sharing to PMS',
            life: 5000
          });
          return of(Noop());
        }));
    })
  ));

  getPatient$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.GetPatient),
    mergeMap((action) => this.patientService.getPatient(action.patientId).pipe(
      map((result: Patient) => {
        return ConversationActions.GetPatientSuccess({patient: result});
      })
    ))
  ));

  sharePatientToPms$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.SharePatientToPms),
    mergeMap((action) => this.patientService.shareToPms(action.patient).pipe(
      map(() => {
        return ConversationActions.SharePatientToPmsSuccess();
      }),
      catchError(() => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred when sharing to PMS',
          life: 5000
        });
        return of(Noop());
      })
    ))
  ));

  setConversationPatient$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.SetConversationPatient),
    withLatestFrom(this.store.select(getCurrentConversationClientAndPractice)),
    filter(([action, data]) => data.conversation !== null && data.practice !== null && data.client !== null),
    mergeMap(([action, data]) => this.conversationService.setPatient(
      Number(data.conversation!.id),
      action.patient,
      data.client!,
      data.practice!
    ).pipe(
      map((result: Conversation) => {
        if (result.patient && result.patient.id) {
          return ConversationActions.GetPatient({patientId: result.patient.id});
        }
        return Noop();
      })
    ))
    )
  );

  removeClientFromConversation$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.RemoveClientFromConversation),
    mergeMap((action) => this.conversationService.updateConversation(
      Number(action.conversationId), {
        clientId: null
      }
    ).pipe(
      map((result: Conversation) => {
        return Noop();
      })
    ))
    )
  );

  removePatientFromConversation$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.RemovePatientFromConversation),
    mergeMap((action) => this.conversationService.updateConversation(
      Number(action.conversationId), {
        patientId: null
      }
    ).pipe(
      map((result: Conversation) => {
        return Noop();
      })
    ))
    )
  );

  getStaffComments$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.GetStaffComments),
    mergeMap((action) => this.conversationService.getComments(action.conversationId)
      .pipe(
        map((result: Comment[]) => {
          return ConversationActions.GetStaffCommentsSuccess({comments: result});
        })
      ))
    )
  );

  sendComment$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.AddComment),
    tap((action) => this.websocketService.sendComment(
      action.conversationId ?? null,
      action.productRequestId ?? null,
      action.message
      )
    )
  ), {dispatch: false});

  getPatientHistory$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.GetPatientHistory),
    mergeMap((action) => this.patientService.getHistory(action.patientId, action.skip, action.limit)
      .pipe(
        map((result: HistoryItem[]) => {
          return ConversationActions.GetPatientHistorySuccess({historyItems: result});
        })
      ))
    )
  );

  updateUser$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.UpdateUser),
    mergeMap((action) => this.userService.update(action.user)
      .pipe(
        map((result: User) => {
          return ConversationActions.UpdateUserSuccess({user: result});
        })
      ))
    )
  );

  addWatcher$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.AddWatcher),
    mergeMap((action) => this.conversationService.addWatcher(action.conversation.id, action.user.id)
      .pipe(
        map(() => {
          return ConversationActions.WatcherAdded();
        })
      ))
    )
  );

  removeWatcher$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.RemoveWatcher),
    mergeMap((action) => this.conversationService.removeWatcher(action.conversation.id, action.user.id)
      .pipe(
        map(() => {
          return ConversationActions.WatcherRemoved();
        })
      ))
    )
  );


  updateConversationsFilters$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.UpdateConversationsFilters),
    tap((action) => {
      let url = `conversations?`;
      const params = buildUrlParamsFromConversationFilters(action.filters);
      url += params;
      this.router.navigateByUrl(url);
    }),
    map((action) => {
      return ConversationActions.SearchConversations({filters: action.filters});
    })
  ));

  searchConversation$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.SearchConversations),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, currentPractice]) => {
      if (!currentPractice) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred when sharing to PMS',
          life: 5000
        });
        return of(Noop());
      }

      return this.conversationService.searchConversations(currentPractice.id, action.filters)
        .pipe(
          map((result: { conversations: Conversation[], total: number }) => {
            return ConversationActions.SearchConversationsSuccess({conversations: result.conversations, total: result.total});
          })
        );
    })
  ));

  markConversationsRead$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.MarkConversationsAsRead),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, currentPractice]) => {
      if (!currentPractice) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred when marking conversations read',
          life: 5000
        });
        return of(Noop());
      }

      return this.conversationService.markConversationsRead(currentPractice.id, action.conversations)
        .pipe(
          map((result: any) => {
            return ConversationActions.MarkConversationsAsReadSuccess({conversations: action.conversations});
          })
        );
    })
  ));

  markConversationsUnread$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.MarkConversationsAsUnread),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, currentPractice]) => {
      if (!currentPractice) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred when marking conversations unread',
          life: 5000
        });
        return of(Noop());
      }

      return this.conversationService.markConversationsUnread(currentPractice.id, action.conversations)
        .pipe(
          map((result: any) => {
            return ConversationActions.MarkConversationsAsUnreadSuccess({conversations: action.conversations});
          })
        );
    })
  ));

  blockSender$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.BlockSender),
    withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, currentPractice]) => {
        if (!currentPractice) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when blocking this sender',
            life: 5000
          });
          return of(Noop());
        }

        return this.conversationService.blockSender(currentPractice.id, action.conversations).pipe(
          map((result: any) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Sender has been blocked successfully',
              life: 5000
            });

            this.router.navigateByUrl('/day-list', {});
            return ConversationActions.BlockSenderSuccess();
          }),
          catchError((error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when blocking this sender',
              life: 5000
            });
            return of(Noop());
          })
        );
      })
    )
  );

  convertToSms$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.ConvertConversationToSMS),
    mergeMap((action) => {
      return this.conversationService.convertToSMS(action.conversation, action.resendAll)
        .pipe(
          map((result: any) => {
            return ConversationActions.ConvertConversationToSMSSuccess({conversation: action.conversation});
          }),
          catchError((error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when converting this conversation to SMS',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));

  setTagsOnConversation$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationActions.SetTagsOnConversation),
    mergeMap((action) => {
      return this.conversationService.setTags(action.conversation, action.tags)
        .pipe(
          map((result: any) => {
            return ConversationActions.SetTagsOnConversationSuccess({tags: action.tags, conversation: action.conversation});
          }),
          catchError((error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred updating the tags on this conversation',
              life: 5000
            });
            return of(ConversationActions.SetTagsOnConversationFailed({tags: action.tags, conversation: action.conversation}));
          })
        );
    })
  ));
}
